import React, { useState, useEffect, useContext } from "react";
import "./RolloverRow.css";
import moment from "moment";
import { fetchLocalTokenData, getFeeManagerContract, truncateNumber } from "../../utils/Utils";
import { ethers } from "@usedapp/core/node_modules/ethers";
import ReactTooltip from "react-tooltip";
import { WalletDataContext } from "../../context/WalletDataContext";
import { WALLET_DATA_CONTEXT } from "../../utils/Interfaces";

const RolloverRow = (props: {
  pool: any,
  oldPoolAddress: string,
  oldExpiry: number,
  active: boolean,
  rolloverRowClicked: (
    colSymbol: string,
    lendSymbol: string,
    mintRatio: string,
    expiry: number,
    feeRate: number,
    index: number
  ) => void,
  index: number
  colPrice: number,
  lendPrice: number,
  rolloverPoolDataLoaded: (data: any) => void
}) => {
  const [expiry, setExpiry] = useState<number>(0);
  const [mintRatio, setMintRatio] = useState("");
  const [colSymbol, setColSymbol] = useState("");
  const [lendSymbol, setLendSymbol] = useState("");
  // const [newPoolOwner, setNewPoolOwner] = useState("");
  // const [oldPoolOwner, setOldPoolOwner] = useState("");
  const [feeRate, setFeeRate] = useState<number>(0);
  const [annualizedFeeRate, setAnnualizeFeeRate] = useState<number>(0);
  const [borrowingDisabled, setBorrowingDisabled] = useState<boolean>(false);
  const { provider, chainId, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  useEffect(() => {
    getPoolStats();
    // eslint-disable-next-line
  }, []);

  const getPoolStats = async () => {
    if (!multicallProvider || !provider) return;
    // let newPoolOwner = props.pool.deployer
    let expiryResp = props.pool.expiry;
    // get fee manager contract
    const feeManagerContract = getFeeManagerContract(provider, chainId, true);
    // setup multicall calls
    const calls:any[] = [
      feeManagerContract.getCurrentRate(props.pool.id),
      feeManagerContract.poolFeesData(props.pool.id),
    ];
    // execute contract calls 
    const response = await multicallProvider.all(calls);
    // extract response
    const feeRateResp = response[0]
    const { 
      auctionEndDate,
      auctionStartDate,
      rateType,
      startRate,
      endRate
    } = response[1];

    const lendTokenData = fetchLocalTokenData(props.pool.lendToken, chainId);
    const colTokenData = fetchLocalTokenData(props.pool.colToken, chainId);
    const colSymbol = colTokenData.symbol;
    const lendSymbol = lendTokenData.symbol;
    const disabledBorrow = props.pool._paused;

    let annualizedFeeRate = feeRateResp;
    if (rateType === 1) {
      annualizedFeeRate = (31536000 / (((Number(props.pool.expiry)) - new Date().getTime() / 1000))) * (parseFloat(feeRateResp.toString()));
    } else {
      const now = Math.trunc(new Date().getTime() / 1000);
      // logic for determining APR for pools with type 2 (linear decay with auction)
      if (now < auctionStartDate)  {
        annualizedFeeRate = startRate;
      } else if (now >= auctionStartDate && now < auctionEndDate)  {
        annualizedFeeRate = endRate + (startRate - endRate) * (auctionEndDate - now) / (auctionEndDate - auctionStartDate);
      } else if (now >= auctionEndDate) {
        annualizedFeeRate = endRate;
      }
    }

    // update state
    setExpiry(expiryResp);
    setBorrowingDisabled(disabledBorrow);
    setMintRatio(ethers.utils.formatUnits(props.pool.mintRatio, 18));
    setColSymbol(colSymbol);
    setLendSymbol(lendSymbol);
    setFeeRate(Number(feeRateResp.toString()) / 10000);
    setAnnualizeFeeRate(Number(annualizedFeeRate));

    // notify the parent component that the pool is loaded
    // and pass some data about the pool
    props.rolloverPoolDataLoaded({
      id: props.pool.id,
      disabledBorrow,
    });
    
  };

  const renderRow = () => {
    // make sure symbols are loaded
    // new expiry must be > old expiry
    if (
      !colSymbol ||
      !lendSymbol
    ) {
      return <div></div>;
    } else {
      const lendValue = parseFloat(mintRatio) * props.lendPrice;
      const colValue = props.colPrice;
      const apr = annualizedFeeRate / 10000;
      const ltv = (lendValue / colValue) * 100;
      return (
        <div
          className={`rollover-row fade-in ${props.active ? "active" : ""} ${borrowingDisabled ? "disabled" : ""}`}
          onClick={() => 
            props.rolloverRowClicked(
              colSymbol,
              lendSymbol,
              mintRatio,
              expiry,
              feeRate,
              props.index
            )
          }
        >
          <div className="rollover-lend-ratio rollover-text">
            <ReactTooltip id={`rollover-ltv-tip-${props.pool.id}`} type="info" className="tool-tip">
              Lend Ratio: {mintRatio}
            </ReactTooltip>
            <span data-tip data-for={`rollover-ltv-tip-${props.pool.id}`}>
              {ltv.toFixed(2)}%
            </span>
          </div>
          <div className="rollover-expiry rollover-text">
            {moment(expiry * 1000).format("MMM D, YYYY")}
          </div>
          <div className="rollover-amount-owed rollover-text">
            {truncateNumber(feeRate, 4)}%
          </div>
          <div className="rollover-amount-owed rollover-text">
            {truncateNumber(apr, 3)}%
          </div>
        </div>
      );
    }
  };

  return renderRow();
};

export default RolloverRow;
