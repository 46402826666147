import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import Calculator from "../../../components/Calculator/Calculator";
import { CreatePoolContext } from "../../../context/CreatePoolContext";
import { CreatePoolPagesContext } from "../../../context/CreatePoolPagesContext";
import { WalletDataContext } from "../../../context/WalletDataContext";
import { CREATE_POOL_CONTEXT, WALLET_DATA_CONTEXT } from "../../../utils/Interfaces";
import { getAaveLendingPoolContract, tokenData } from "../../../utils/Utils";
import { MulticallWrapper } from 'ethers-multicall-provider';
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

// this component serves as a wrapper for the three pages of the create pool flow
const Pages = () => {

  const [termRateChanged, setTermRateChanged] = useState(false);
  const [lendRatioChanged, setLendRatioChanged] = useState<boolean>(false);
  const [hideRecommendation, setHideRecommendation] = useState<boolean>(true);
  const [calculatedLtv, setCalculatedLtv] = useState<number>(0); 
  const [strategyRates, setStrategyRates] = useState<any>({});
  const { 
    selectedCollateral,
    selectedLend,
    currentPage
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const { 
    provider, 
    chainId
  } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  // reset 'changed' variables when user updates the collateral asset
  useEffect(() => {
    setTermRateChanged(false);
    setLendRatioChanged(false);
  }, [selectedCollateral, selectedLend]);

  useEffect(() => {
    // check if strategyRates is empty
    if (Object.keys(strategyRates).length > 0) return;
      getMarketRates();
  // eslint-disable-next-line
  });

  // get the AAVE rates for each stablecoin that they support
  const getMarketRates = async () => {
    if (!provider) return;

    const multicallProvider = MulticallWrapper.wrap(provider);

    // get a list of all supported stablecoin addresses
    const tokens = Object.entries(tokenData).filter((token) => {
      const data:any = token[1];
      // const isStablecoin = data.category && data.category === 'stablecoin';
      // only include stablecoins that are supported by AAVE
      const isSupported = (["DAI", "USDC", "USDC.e", "USDT", "USDbC", "WETH"].includes(data.symbol)) && data.address[chainId];
      return isSupported;
    }).map((token) => token[1]);

    // get the AAVE lending pool contract
    const lendingPool = getAaveLendingPoolContract(
      multicallProvider,
      chainId,
    );

    // get the reserve data for each token
    let calls = tokens.map((tokenData: any) => 
      lendingPool.getReserveData(tokenData.address[chainId]).catch(() => undefined)
    );

    // execute contract calls 
    const response = await Promise.all(calls);

    let rates:any = {};

    response.forEach((reserveData: any, index: number) => {
      if (!reserveData) return;
      // get the asset's lend rate
      const liquidityRate = reserveData.liquidityRate;
      const strategyRate = parseFloat(ethers.utils.formatUnits(liquidityRate, 25)); // 25 decimals for the interest rate
      const tokenData = tokens[index];
      // @ts-ignore
      rates[tokenData.address[chainId]] = strategyRate;
    });

    // update the state
    setStrategyRates(rates);
  }

  const renderPages = () => {
    const pageMapping = [
      <Page1/>, 
      <Page2/>, 
      <Page3/>
    ];
    return (
      pageMapping[currentPage]
    )
  }

  return (
    <CreatePoolPagesContext.Provider value={{
      termRateChanged,
      setTermRateChanged,
      lendRatioChanged,
      setLendRatioChanged,
      hideRecommendation,
      setHideRecommendation,
      strategyRates,
      calculatedLtv,
      setCalculatedLtv
    }}>
      <Calculator
        colToken={selectedCollateral.address}
        lendToken={selectedLend.address}
      />
      {renderPages()}
    </CreatePoolPagesContext.Provider>
  )
}

export default Pages;