import "./App.css";

import Dashboard from "./components/Dashboard/Dashboard";
import Disclaimer from "./components/Disclaimer/Dislcaimer";
import { Fragment, useState } from "react";
import Verification from "./components/Verification/Verification";

function App() {

  const [countryAllowed, setCountryAllowed] = useState<boolean>();

  const renderDashboard = () => {
    if (countryAllowed === undefined || countryAllowed === false) {
      return (
        <Verification
          countryAllowed={countryAllowed}
          setCountryAllowed={setCountryAllowed}
        />
      )
    } else {
      return (
        <Fragment>
          <Dashboard/>
          <Disclaimer/>
        </Fragment>
      )
    }
  }

  return (
    <div className="App">
      {renderDashboard()}
    </div>
  );
}

export default App;
