import { useSnackbar } from "notistack";
import ActionButton from "../../components/ActionButton/ActionButton";
import { getGasLimit, getTransactionUrl, supportedNetworks } from "../../utils/Utils";
import LendingPoolABI from "../../abi/LendingPool.js";
import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { PoolDataContext } from "../../context/PoolDataContext";
import { AppDataContext } from "../../context/AppDataContext";

const PauseBorrowing = (props: {
  updatePoolData: () => void,
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [isPoolPaused, setIsPoolPaused] = useState<boolean>(false);
  const { provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    getPauseStatus();
  // eslint-disable-next-line
  }, [selectedPool]);

  const getPauseStatus = async() => {
    if (!selectedPool) return;
    // if pool pausetime is less than current time, pool is paused
    setIsPoolPaused(selectedPool.pauseTime < (new Date().getTime()) / 1000);
  }

  const pauseBorrow = async() => {
    if (!supportedNetworks.includes(chainId) || !selectedPool || !provider) return;

    setPending(true);
    enqueueSnackbar("Please confirm the toggle borrow transaction in your wallet", {
      persist: false,
      disableWindowBlurListener: true
    });

    try {
      const poolAddress = selectedPool.id;
      const poolContract = new ethers.Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner() as any
      );
      const now = Number((new Date().getTime() / 1000).toFixed(0));
      const newPauseTime = isPoolPaused ? selectedPool.expiry : now;
      const tx = await poolContract.setPauseBorrowing(
        newPauseTime,
        {
          ...getGasLimit(chainId, METHOD_TYPE.PAUSE_BORROWING)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Toggle borrow transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
      // setIsPoolPaused(newPauseTime < now);
      setIsPoolPaused(!isPoolPaused);
    } catch (e) {
      enqueueSnackbar("Toggle borrow transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
    // getPoolData([selectedPool]);
    props.updatePoolData();
  }

  const manageWidgetText = () => {
    if (!selectedPool) return "Pause";
    else if (isPoolPaused) return "Unpause";
    else if (!isPoolPaused) return "Pause";
    else return "Pause";
  }

  return (
    <div className="pause-borrowing-wrapper small-widget">
      <span className="widget-description">
        When paused users will no longer be able to borrow from this pool but
        all other actions such as repay or rollover will remain active
      </span>
      <div className="settings-tab-button-wrapper pause-borrowing">
        <ActionButton
          disabled={pending}
          title={manageWidgetText()}
          action={pauseBorrow}
        />
      </div>
    </div>
  );
}

export default PauseBorrowing;