import React from "react";
import "./ActionButton.css";

const ActionButton = (props: {
  disabled: boolean;
  action?: () => any;
  title: any;
  secondary?: boolean;
}) => {
  return (
    <button
      onClick={props.action}
      disabled={props.disabled ? props.disabled : false}
      className={`${props.secondary ? "secondary-" : ""}action-button-container disable-select action-button`}
    >
      {props.title}
    </button>
  );
};

export default ActionButton;
