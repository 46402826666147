import "./ConnectWallet.css";
// import { Modal } from '@material-ui/core'
import Modal from "../Modal/Modal";
//@ts-ignore
import metamask from "../../img/providers/metamask.png";
//@ts-ignore
import walletConnect from "../../img/providers/walletconnect.png";
//@ts-ignore
import walletLink from "../../img/providers/walletlink.png";

/*
  connect: (x: any) => void;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
*/
export default function ConnectWallet(props: {
  connectWallet: () => void,
  showModal: boolean,
  setShowModal: (val: boolean) => void,
}) {
  const options = ["MetaMask", "WalletConnect", "WalletLink"];

  // choose provider and connect
  const onClick = (e: any, index: number) => {
    props.connectWallet();
    props.setShowModal(false);
  };

  const getProvider = (option: string) => {
    if (option === "metamask") return metamask;
    else if (option === "walletconnect") return walletConnect;
    else if (option === "walletlink") return walletLink;
  };

  return (
    <Modal showModal={props.showModal} setShowModal={props.setShowModal}>
      <div className="walletModal fade-in">
        {options.map((option, index) => (
          <div
            key={index}
            className="walletProvider"
            onClick={(e) => onClick(e, index)}
          >
            <img
              src={getProvider(option.toLowerCase())}
              alt={`${option.toLowerCase()}`}
              width={50}
              height={50}
            />
            <span>{option}</span>
          </div>
        ))}
      </div>
    </Modal>
  );
}
