import { Jazzicon } from "@ukstv/jazzicon-react";
import { shortenAddress } from "@usedapp/core";
import { useContext, useState } from "react";
import { ChevronDown, X } from "react-feather";
import { Link } from "react-router-dom";
import { WalletDataContext } from "../../context/WalletDataContext";
import { NETWORK_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { fetchSocialLogo } from "../../utils/Utils";
import NetworkSelect from "../NetworkSelect/NetworkSelect";
import "./MobileMenu.css";

const MobileMenu = (props: {
  links: any,
  connectWallet: any,
  changeNetwork: any,
  account: any,
  showMenu: boolean
  networkName: string,
  setShowMenu: (val: boolean) => void
}) => {

  const [showNetworkSelect, setShowNetworkSelect] = useState(false);

  const { account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  const changeNetwork = async (network: NETWORK_DATA) => {
    setShowNetworkSelect(false);
    props.changeNetwork(network)
    // props.setShowMenu(false);
    // props.connectWallet(network);
  }

  const connectWalletClicked = () => {
    // close menu
    props.setShowMenu(false);
    // start connect wallet steps 
    props.connectWallet();
  }

  const renderMobileWalletSection = () => {
    return (
      <div className={`mobile-menu-wallet-section`}>
        <div className="jazzicon-wrapper">
          <Jazzicon
            // seed={jsNumberForAddress(account)}
            address={account || ""}
            className="jazzicon"
          />
        </div>
        <div className="mobile-menu-wallet-info">
          <span className="mobile-menu-wallet-address" onClick={connectWalletClicked}>
            {account ? shortenAddress(account) : 'Connect Wallet'}
          </span>
          <span 
            className="mobile-menu-wallet-network"
            onClick={() => setShowNetworkSelect(true)}
          >
            {props.networkName}
            <ChevronDown/>
            {showNetworkSelect &&
              <NetworkSelect
                changeNetwork={changeNetwork}
                setShowNetworkSelect={setShowNetworkSelect}
                mobile={true}
              />
            }
          </span>
        </div>
      </div>
    );
  }

  return(
    <div className={`nav-link-container mobile ${props.showMenu ? 'show' : ''}`}>
      {renderMobileWalletSection()}
      <X
        onClick={() => props.setShowMenu(false)}
        className={"inner-mobile-menu-button"}
      />
      {props.links.map((link: any, index:number) => 
        <Link 
          to={link.href}
          onClick={() => props.setShowMenu(false)}
          className={`mobile-nav-link ${window.location.pathname.split("/")[1] === link.href.substring(1) ? 'active' : ''}`}
          key={index}
        >
          {link.text}
        </Link>
      )}
      <div className="mobile-menu-divider"></div>
      <a 
        href="https://docs.vendor.finance/" 
        className="mobile-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Docs 
      </a>
      <a 
        href="https://discord.com/channels/945189813869490247/945412786647883826"
        className="mobile-nav-link"
        target="_blank"
        rel="noreferrer"
      >
        Report A Bug
      </a>
      <div className="mobile-menu-social-icons-wrapper">
        <a
          className="mobile-social-icon"
          href="https://vendorfinance.medium.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fetchSocialLogo("medium")} alt="medium" />
        </a>
        <a
          className="mobile-social-icon"
          href="https://twitter.com/VendorFi"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fetchSocialLogo("twitter")} alt="twitter" />
        </a>
        <a
          className="mobile-social-icon"
          href="https://discord.gg/mZWtWVJvzZ"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fetchSocialLogo("discord")} alt="discord" />
        </a>
        <a
          className="mobile-social-icon github"
          href="https://github.com/VendorFinance"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fetchSocialLogo("github")} alt="github" />
        </a>
      </div>
    </div>
  );

}

export default MobileMenu;