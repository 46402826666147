import { BigNumberish, ethers } from "ethers";
import { Fragment, useContext, useEffect, useState } from "react";
import { PuffLoader } from "react-spinners";
import { Area, Bar, CartesianGrid, ComposedChart, Legend, Line, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { fetchHistoricalPriceData, fetchLocalTokenData, generateFakePriceData, getCurrentPage } from "../../utils/Utils";
import millify from "millify";
import "./PoolSimulation.css";

const PoolSimulation = (props: {
  colToken: string, 
  lendToken: string,
  mintRatio: BigNumberish,
  fakeData?: boolean,
  startTime?: number 
}) => {

  const [historicalPriceData, setHistoricalPriceData] = useState<any[]>(generateFakePriceData(80));
  const [usingFakeData, setUsingFakeData] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialLoading, setInitialLoading] = useState(true);

  // load contexts
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { tokenPrices, historicalPrices, setHistoricalPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    if (
      props.colToken !== "" && 
      props.lendToken !== "" && 
      props.mintRatio > -1 && 
      chainId &&
      !props.fakeData
    ) getGraphData();
  // eslint-disable-next-line
  }, [props.mintRatio, props.colToken, props.lendToken, tokenPrices, chainId]);

  const CustomTooltip = (data:any) => {

    // get tool tip payload based on key
    const getPayload = (dataKey: string) => {
      if (data) {
        for (let i = 0; i < data.payload.length; i++) {
          if (data.payload[i].dataKey === dataKey) {
            return data.payload[i];
          }
        }
      }
      return undefined;
    }

    // render tool tip when data is active
    if (data && data.active && data.payload && data.payload.length) {
      try {
        return (
          <div className="graph-tooltip">
            <p className="label">{`${new Date(getPayload("timestamp").payload.timestamp).toLocaleString()}`}</p>
            <p className="label">Collateral Price: {`$${getPayload("Collateral Price").value}`}</p>
            <p className="label">Lend Ratio: {`${props.mintRatio}`}</p>
            {getPayload("Borrow Value") !== undefined
              ? <p className="label">Borrow Value: {`$${getPayload("Borrow Value").value}`}</p>
              : <p className="label">Borrow Value: {`$0`}</p>
            }
          </div>
        );
      } catch (e) {
        return (
          <div className="graph-tooltip">
            <p className="label">LOADING</p>
          </div>
        );
      }
    }
  
    return null;
  };

  // fetch historical price data from backend or load from cached data
  const getHistoricalPriceData = async (colToken: string) => {
    let historicalData:[][] = [];
    // check if entry exists
    if (!historicalPrices[colToken]) {
      // if no entry, load from backend
      historicalData = await fetchHistoricalPriceData(colToken, chainId, 71);
      let newStoredData = {
        ...historicalPrices,
        [colToken]: historicalData
      }
      // save data to cache
      setHistoricalPrices(newStoredData);
    } else {
      // if entry, load from cache
      historicalData = historicalPrices[colToken];
    }
    return (historicalData);
  }

  const getGraphData = async () => {
    if (props.colToken === "" || props.lendToken === "" || !tokenPrices) return;
    setLoading(true);
    // get historical prices for the given collateral token
    const prices:[][] = await getHistoricalPriceData(props.colToken);
    // const lendSymbol:string = fetchTokenSymbol(props.lendToken, chainId);
    const colData = fetchLocalTokenData(props.colToken, chainId);
    const lendData = fetchLocalTokenData(props.lendToken, chainId);
    // @ts-ignore
    const lendPrice = tokenPrices[ethers.utils.getAddress(props.lendToken)];
    let mintRatio = Number(props.mintRatio) * lendPrice;
    mintRatio = isFinite(mintRatio) ? mintRatio : 1;
    let historicalData:any[] = [];
    // let skip = props.colToken === tokenData.ARB.address[42161] ? 1 : 2;
    let skip = 2;
    // construct graph data based on historical prices
    for (let i = 0; i < prices.length; i+=skip) {
      // if (i % 2 === 0) continue;
      const priceData:any = prices[i];
      const colPrice = (priceData.price).toFixed(colData.displayDecimals);
      const pointData:any = {
        name: (priceData.timestamp  * 1000),
        timestamp: new Date(priceData.timestamp * 1000),
        // "Expiry": ,
        "Collateral Price": Number(colPrice),
        "Borrow Value": Number(mintRatio.toFixed(lendData.displayDecimals))
      }
      historicalData.push(pointData);
    }

    // add current price to graph
    const price = tokenPrices[props.colToken];
    if (parseFloat(price) > 0) {
      const now = new Date();
      const pointData:any = {
        name: Number(now),
        timestamp: now,
        "Collateral Price": parseFloat(price).toFixed(2),
        "Borrow Value": Number(mintRatio.toFixed(lendData.displayDecimals))
      }
      historicalData.push(pointData);
    }

    setHistoricalPriceData(historicalData);
    setUsingFakeData(false);
    setLoading(false);
    setInitialLoading(false);
  }

  const formatDate = (timestamp: number) => {
    if (!timestamp) return "";
    let str = new Date(timestamp).toString();
    const splitStr = str.split(" ");
    str = `${splitStr[1]} ${splitStr[2]}`
    return str;
  }

  const formatYAxis = (val: number) => {
    // abbreviate number
    const formattedValue = millify(val, {
      precision: 1,
      lowercase: true
    });
    if (val > 1)
      return formattedValue;
    else 
      return val.toFixed(3);
  }

  const getYAxisDomain = () => {
    let max = Math.max(...historicalPriceData.map((d:any) => d["Collateral Price"]));
    let min = Math.min(...historicalPriceData.map((d:any) => d["Collateral Price"]));
    // change max to be x% higher than max value
    max = (max * 1.1);
    // change min to be x% lower than min value
    min = (min * 0.2);
    const domain = [min, max];
    return domain;
  }

  const renderChart = () => {

    // gradient colors for price graph area
    const gradientColors = () => {
      return (
       <linearGradient id="colorView" x1="0" y1="0" x2="1" y2="0">
         <stop offset="30%" stopColor="var(--cta-blue)" stopOpacity={0.08} />
         <stop offset="95%" stopColor="var(--cta-orange)" stopOpacity={0.08} />
       </linearGradient>
      );
    };

    // gradient colors for price graph area
    const dashed = () => {
      const colors = [...new Array(100)].map((_, index) => 
        <stop 
          offset={`${index}%`} 
          stopColor={`${index % 5 === 0 ? "white" : "black"}`}
          stopOpacity={`${index % 5 === 0 ? 0 : 1}`}
        />
      );
      return (
       <linearGradient id="dashed" x1="0" y1="0" x2="0" y2="1">
          {colors}
       </linearGradient>
      );
    };

    // custom dot coloring logic 
    const customDot = (data: any) => {
      const { cx, cy, dataKey } = data;
      const stroke = dataKey.indexOf("Borrow Value") > -1 
        ? "var(--cta-orange)" 
        : "var(--cta-blue)";
      return (
        <circle cx={cx} cy={cy} r={3} stroke={stroke} strokeWidth={2} fill="white" />
      );
    }

    // what to render if no data is available
    const noData = (
      <Fragment>
        <span className="no-data-message">
          {usingFakeData && initialLoading && !loading ? "No Data Available" : ""}
        </span>
        <ResponsiveContainer width="100%" height="95%">
          <ComposedChart data={historicalPriceData}>
            <defs>
              {gradientColors()}
              {dashed()}
            </defs>
            <XAxis 
              dataKey="name"
              scale="auto" 
              tickLine={false}
              minTickGap={10}
              tickCount={20}
              axisLine={{ stroke: "var(--input)" }}
              tickFormatter={(tick: any) => formatDate(tick)}
              stroke="var(--border-color)" 
            />
            <Area
              type="natural"
              dataKey="Collateral Price"
              fill="url(#colorView)"
              strokeWidth={2}
              stroke="#4753d8"
              yAxisId={1}
              dot={false}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="Borrow Value"
              stroke="var(--cta-orange)"
              yAxisId={1}
              dot={false}
              hide={true}
              strokeDasharray={3}
              activeDot={customDot}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="timestamp"
              strokeWidth={5}
              yAxisId={1}
              dot={false}
            />
            <YAxis
              type="number"
              yAxisId={1}
              tickFormatter={(tick: number) => formatYAxis(tick)}
              tickLine={false}
              tick={true}
              strokeWidth={0}
              width={35}
            />
            {window.location.pathname.indexOf("my-pools") > -1 &&
              <Bar 
                dataKey="Start Date" 
                fill="var(--dark-jungle-green)" 
                maxBarSize={1}
                yAxisId={1}
              />
            }
          </ComposedChart>
        </ResponsiveContainer>
      </Fragment>
    );

    if (props.fakeData || initialLoading)
      return (noData)
    else if (historicalPriceData.length > 0)
      return (
        <ResponsiveContainer width="100%" height="95%">
          <ComposedChart data={historicalPriceData}>
            <defs>
              {gradientColors()}
              {dashed()}
            </defs>
            <CartesianGrid
              stroke="hsla(220, 22%, 96%, 1)"
              vertical={false}
            />
            <XAxis 
              dataKey="name"
              scale="auto" 
              tickLine={false}
              minTickGap={10}
              tickFormatter={(tick: any) => formatDate(tick)}
              axisLine={{ stroke: "var(--input)" }}
              tickCount={20}
              domain={[historicalPriceData[0].name, historicalPriceData[historicalPriceData.length - 1].name]}
              stroke="var(--border-color)" 
              type="number"
            />
            <YAxis
              type="number"
              yAxisId={1}
              tickLine={false}
              tickFormatter={(tick: number) => formatYAxis(tick)}
              interval={0}
              domain={getYAxisDomain()}
              tickCount={7}
              tick={true}
              strokeWidth={0}
              width={35}
            />
            <Legend
              verticalAlign="top"
              height={getCurrentPage() === "my-pools" ? 30 : 0}
              iconType="circle"
              iconSize={10}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="natural"
              dataKey="Collateral Price"
              fill="url(#colorView)"
              strokeWidth={2}
              stroke="#4753d8"
              yAxisId={1}
              dot={false}
              activeDot={customDot}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="Borrow Value"
              stroke="var(--cta-orange)"
              yAxisId={1}
              dot={false}
              hide={props.mintRatio === 0}
              activeDot={customDot}
              animationDuration={500}
            />
            <Line
              type="monotone"
              dataKey="timestamp"
              strokeWidth={5}
              yAxisId={1}
              dot={false}
            />
            {/* the start date bar is invisible, but needed to render legend*/}
            {props.startTime &&
              <Fragment>
                <Bar 
                  dataKey="Start Date" 
                  fill="black"
                  maxBarSize={0}
                  strokeDasharray={"3 3"}
                  yAxisId={1}
                />
                {props.startTime > historicalPriceData[0].name &&
                  <ReferenceLine
                    x={props.startTime}
                    stroke="black"
                    ifOverflow="extendDomain"
                    strokeLinecap="round"
                    strokeWidth={2}
                    yAxisId={1}
                  />
                }
              </Fragment>
            }
          </ComposedChart>
        </ResponsiveContainer>
      );
  }

  const renderLoading = () => {
    if (loading || (initialLoading && !usingFakeData)) {
      return (
        <div className="simulation-loader">
          <PuffLoader size={20} />
        </div>
      );
    }
  }

  return (
    <div className="pool-simulation-wrapper simulation-wrapper">
      <div className="pool-simulation-title my-pools-graph-title simulation-title">
        <span>
          Collateral Price &amp; Borrow Value
        </span>
      </div>
      {renderLoading()}
      {renderChart()}
    </div>
  )
}

export default PoolSimulation;