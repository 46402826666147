import axios from "axios";
import { useEffect } from "react";
import "./Verification.css";

const Verification = (props: {
  countryAllowed: boolean | undefined,
  setCountryAllowed: (countryAllowed: boolean) => void
}) => {

  useEffect(() => {
    axios.get("https://us-central1-vendor-finace.cloudfunctions.net/checkUserIp")
      .then((res) => {
        props.setCountryAllowed(res.data);
      })
      .catch((err) => {
        console.log(err);
        props.setCountryAllowed(false);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLogic = () => {
    if (props.countryAllowed === false)
      return (
        <div className="blocked-ip-message fade-in">
          <h1>Sorry, this website is not available in your country.</h1>
          <span>If you're using a VPN please disable it and refresh the page.</span>
        </div>
      )
    else
      return (
        <></>
      )
  }

  return (
    <div className="verification-loading-wrapper fade-in">
      {renderLogic()}
    </div>
  )
}

export default Verification;
