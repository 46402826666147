import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../context/AppDataContext";
import { CreatePoolContext } from "../../context/CreatePoolContext";
import { APP_DATA_CONTEXT, CREATE_POOL_CONTEXT, CREATE_POOL_PAGES_CONTEXT } from "../../utils/Interfaces";
// @ts-ignore
import { blackScholes } from "black-scholes";
import { fetchLocalTokenData } from "../../utils/Utils";
import { CreatePoolPagesContext } from "../../context/CreatePoolPagesContext";

const Calculator = (props: {
  colToken: string,
  lendToken: string
}) => {

  const { historicalPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { 
    selectedMintRatio,
    setSelectedMintRatio,
    setSelectedAPR,
    selectedExpiry,
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const { tokenPrices } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { 
    setHideRecommendation, 
    strategyRates,
    setCalculatedLtv
  } = useContext(CreatePoolPagesContext) as CREATE_POOL_PAGES_CONTEXT;

  const [volatility, setVolatility] = useState<number>(0);
  const [volatilityCoefficient, setVolatilityCoefficient] = useState<number>(0);

  useEffect(() => {
    // if historical prices are loaded and the selected collateral price data is loaded
    // then calculate the recommended terms
    if (
      Object.entries(historicalPrices).length > 0 && 
      historicalPrices[props.colToken] &&
      historicalPrices[props.colToken].length > 0
    ) {
      // check if terms should be calculated based on the collateral and lend token
      if (shouldCalculateTerms()) {
        setHideRecommendation(false);
        setTimeout(() => {
          // reset values
          setVolatility(0);
          setVolatilityCoefficient(0);
          // and recalculate
          calculateVolatility();
        }, 500)
      } else {
        // setSelectedMintRatio(calculateMintRatio(50));
        // setSelectedAPR([17,0]);
        // if terms don't need to be calculated
        // reset the recommended rate and ltv
        setHideRecommendation(true);
        setCalculatedLtv(0);
      }
    }
  // eslint-disable-next-line
  }, [props.colToken, props.lendToken, historicalPrices, selectedExpiry, strategyRates]);

  useEffect(() => {
    if (volatility > 0 && volatilityCoefficient > 0) {
      calculateRecommendedRate();
    }
  // eslint-disable-next-line
  }, [selectedMintRatio]);

  useEffect(() => {
    // calculate recommended rate and ltv based on volatility and volatility coefficient
    const ltv = 100 * calculateRecommendedLtv(volatility, volatilityCoefficient);
    if (ltv !== 100) {
      // set the recommended rate and ltv
      setSelectedMintRatio(calculateMintRatio(ltv));
      setCalculatedLtv(ltv);
    }
  // eslint-disable-next-line
  }, [volatility, volatilityCoefficient]);

  const hasQueryParams = () => {
    // extract parameters from URL query
    const urlParams = new URLSearchParams(window.location.search);
    const queryLendToken = urlParams.get("lendToken");
    const queryColToken = urlParams.get("colToken");
    const queryLendRatio = urlParams.get("lendRatio");
    const queryStartRate = urlParams.get("startRate");
    const queryFeeType = urlParams.get("feeType");
    const queryStrategy = urlParams.get("strategy");
    const queryExpiry = urlParams.get("expiry");
    const queryRequestedPool = urlParams.get("requestedPool");
    const queryOriginPool = urlParams.get("originPool");

    // check if there are any query params
    if (
      queryLendToken ||
      queryColToken ||
      queryLendRatio ||
      queryStartRate ||
      queryFeeType ||
      queryStrategy ||
      queryExpiry ||
      queryRequestedPool ||
      queryOriginPool
    ) {
      return true;
    } else {
      return false;
    }
  }

  const getStableRate = () => {
    if (strategyRates[props.lendToken]) 
      return strategyRates[props.lendToken];
    else 
      return 0;
  }

  // calculate mint ratio based on % button click
  const calculateMintRatio = (ltv: number) => {
    const colPrice = (tokenPrices[props.colToken] || 0);
    const lendPrice = (tokenPrices[props.lendToken] || 0);
    if (colPrice === 0 || lendPrice === 0) return 0;
    // get a percentage value of the collateral price
    const colValue = colPrice * (ltv / 100);
    // get how many lend tokens are needed to match that percent value 
    const lendValue = colValue / lendPrice;
    // different precision for different token pairs
    const res = lendValue.toFixed(lendValue > 0.001 ? 3 : 5);
    return (Number(res));
  }

  // calculate and store the volatility, volatility coefficient, and ltv coefficient
  const calculateVolatility = () => {
    let returns = calculateReturns();
    returns = returns.slice(0, returns.length - 1);
    const mean = calculateMean(returns);
    const variance = calculateVariance(returns, mean);
    const dailyStdDev = calculateStdDev(variance);
    const volatility = dailyStdDev * Math.sqrt(365);
    const volatilityCoefficient = getVolatilityCoefficient(volatility);
    setVolatility(volatility);
    setVolatilityCoefficient(volatilityCoefficient);
  }

  /*
    Determine what the volatility coefficient should be based on the calculated volatlity.
    For now there are four categories that the volatility value can fall into.
    Based on the category, the volatility coefficient is chosen.

    Very Low Volatility: Assets with a standard deviation up to 0.1 (DAI, USDC, USDT, FRAX)
    Low Volatility: Assets with a standard deviation between 0.1 and 0.5 (AGEUR, MIM, jUSDC, gOHM, WETH, WBTC, RETH)
    Medium Volatility: Assets with a standard deviation between 0.5 and 1 (ALP, ARB, GMX, DPX, PLSARB)
    High Volatility: Assets with a standard deviation above 1 (MAGIC, cmUMAMI, Y2K, PENDLE, neadRAM, RAM, DMT)
  */
  const getVolatilityCoefficient = (volatility: number) => {
    if (volatility <= 0.1) {
      return 0.005;
    } else if (volatility > 0.1 && volatility <= 0.5) {
      return 0.05;
    } else if (volatility > 0.5 && volatility <= 1) {
      return 0.15;
    } else {
      return 0.17;
    }
  }

  // only calculate a recommended rate if the collateral token isn't a stable
  // and the lend token is a stable
  const shouldCalculateTerms = () => {
    try {
      // fetch data for the lend and collateral tokens
      const lendData = fetchLocalTokenData(props.lendToken);
      const colData = fetchLocalTokenData(props.colToken);
      const urlParams = new URLSearchParams(window.location.search);
      const chainQuery = urlParams.get("chain");
      if (chainQuery !== null) {
        return true;
      } else if (hasQueryParams()) {
        return false;
      } else if (
        // is the collateral token a stablecoin?
        colData.category === "stablecoin" ||
        // is the collateral token a stablecoin and the lend token not a stablecoin?
        (colData?.category !== "stablecoin" && lendData?.category !== "stablecoin")
      ) {
        return false;
      }
      else 
        return true;
    } catch {
      return false;
    }
  }

  // use black scholes to calculate the recommended rate
  const calculateRecommendedRate = () => {

    if (!shouldCalculateTerms())
      return;

    // blackScholes(s, k, t, v, r, callPut)
    // s - Current price of the underlying
    // k - Strike price
    // t - Time to expiration in years
    // v - Volatility as a decimal
    // r - Annual risk-free interest rate as a decimal
    // callPut - The type of option to be priced - "call" or "put"

    const colPrice = (tokenPrices[props.colToken] || 0);

    // calculte time to expiration in years
    const timeToExpiry = (selectedExpiry - Date.now()) / (1000 * 60 * 60 * 24 * 365);

    // calculate the option price 
    const optionPrice = blackScholes(
      colPrice,
      selectedMintRatio,
      timeToExpiry,
      volatility,
      0,
      "put"
    );

    // divide the option price by the strike price to get the recommended rate
    const recommendedRate = optionPrice / selectedMintRatio;
    // annualize the rate
    const annualized = recommendedRate / timeToExpiry;
    // get the stable rate from prevailing borrowing/lending markets (eg: Aave)
    const stableRate = getStableRate();
    // convert to percentage
    const rate = (annualized * 100) + stableRate;
    // update state
    setSelectedAPR([Number(rate.toFixed(1)),0]);
  }

  // 95%, 90%, 85%, 80% etc down to 50% to be the only "legal" values
  // mapping vol of 1-100 to the LTV of 95%-50% but in steps of 5
  const calculateRecommendedLtv = (volatility: number, volatilityCoefficient: number) => {
    // 1. 1 - Math.min(volatility / 2, 0.5) - this will give us a value between 0.5 and 1
    // 2. Math.floor(recommendedLtv * 20) / 20 - this will round the value down to the nearest 0.05
    // 3. 0.05 is the step size for the LTV values
    let recommendedLtv = 1 - Math.min(volatility / 2, 0.5);
    recommendedLtv = Math.floor(recommendedLtv * 20) / 20;
    return recommendedLtv;
  }

  // calculate daily returns based on historical price data
  const calculateReturns = () => {
    const prices = historicalPrices[props.colToken];

    // filter prices array to have one price per day
    const dailyPrices = prices.filter((price: any, index: number) => {
      const date = new Date(price.timestamp * 1000);
      const nextDate = new Date(prices[index + 1]?.timestamp * 1000);
      return date.getDate() !== nextDate.getDate();
    });

    const dailyReturns = dailyPrices.map((price: any, index: number) => {
      const nextPrice = dailyPrices[index + 1];
      const dailyReturn = (nextPrice?.price - price.price) / price.price;
      return {
        timestamp: price.timestamp,
        dailyReturn: dailyReturn
      }
    });

    return dailyReturns.map((r: any) => r.dailyReturn);

  }

  // calculate the mean of a returns data set
  const calculateMean = (returns: number[]) => {
    let sum = returns.reduce((a, b) => a + b, 0);
    return sum / returns.length || 0;
  }

  // calculate the variance of a returns data set
  const calculateVariance = (returns: number[], mean: number) => {
    let variance = returns.reduce((a, b) => a + Math.pow((b - mean), 2), 0);
    return variance / returns.length || 0;
  }

  // calculate the standard deviation based on the variance
  const calculateStdDev = (variance: number) => {
    return Math.sqrt(variance);
  }

  return (
    <div></div>
  )
}

export default Calculator;