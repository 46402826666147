import { APP_DATA_CONTEXT, FEATURED_POOLS_MANAGER_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import ActionButton from "../ActionButton/ActionButton";
import Modal from "../Modal/Modal";
import { Fragment, useContext } from "react";
import TransactionReview from "../TransactionReview/TransactionReview";
import { shortenAddress } from "@usedapp/core";
import "./SubmitFeaturedPool.css";
import PotentialRollover from "../PotentialRollover/PotentialRollover";
import { getFeaturedPoolsManagerContract, getNetworkData, getTransactionUrl } from "../../utils/Utils";
import { WalletDataContext } from "../../context/WalletDataContext";
import { useSnackbar } from "notistack";
import { AppDataContext } from "../../context/AppDataContext";
import { FeaturedPoolsManagerContext } from "../../context/FeaturedPoolsManagerContext";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { ethers } from "ethers";

const SubmitFeaturedPool = (props: {
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  pool: POOL_DATA
}) =>{

  // import context variables
  const { chainId, provider, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { enqueueSnackbar } = useSnackbar();
  const { 
    isPoolFeatured, 
    getFeaturedPoolData, 
    fetchFeaturedPools,
    featuredDuration,
    featuredPoolsPrice
  } = useContext(FeaturedPoolsManagerContext) as FEATURED_POOLS_MANAGER_CONTEXT;

  // setup transaction info
  const getTransactionInfo = () => {
    const shortenedAddress = shortenAddress(props.pool.id);
    const networkData = getNetworkData(chainId);
    const poolLink = networkData.getExplorerAddressLink(props.pool.id);
    const price = featuredPoolsPrice; 
    // convert the timestamp from seconds to days
    const duration = Number(featuredDuration) / 86400;
    // open the pool link in a new tab on click
    const poolLinkClicked = () => window.open(poolLink, "_blank");
    return (
      <Fragment>
        <div className="info-item">
          <span className="info-left">Pool Address:</span>
            <a 
              href={poolLink} 
              target="_blank" 
              rel="noreferrer"
              onClick={poolLinkClicked}
            >
              <u>{shortenedAddress}</u>
            </a>
        </div>
        <div className="info-item">
          <span className="info-left">Featured Duration:</span>
          <span>{duration} days</span>
        </div>
        <div className="info-item">
          <span className="info-left">Amount Due:</span>
          <span>{price} ETH</span>
        </div>
      </Fragment>
    )
  }

  // render the info for the pool to be featured
  const renderPoolInfo = () => {
    return (
      <div>
        <div className="potential-rollover-header-wrapper">
          <span>Pair</span>
          <span>Due Date</span>
          <span>LTV</span>
          <span>Term Rate</span>
        </div>
        <PotentialRollover
          pool={props.pool}
          setSelectedRollover={() => {}}
        />
      </div>
    )
  }

  // execute a tx to submit the pool to the featured category
  const submitPool = async () => {
    if (!provider || !chainId) return;
    setPending(true);
    try {
      enqueueSnackbar("Please confirm the transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true,
      });
      // setup a reference to the featured pools manager contract
      const featuredPoolsManagerContract = getFeaturedPoolsManagerContract(
        provider.getSigner(),
        chainId,
      );
      // execute tx
      const tx = await featuredPoolsManagerContract.submitFeaturedPool(props.pool.id, {
        value: ethers.utils.parseEther(featuredPoolsPrice.toString())
      });
      await tx.wait();
      // fetch the featured pools after tx is complete
      await fetchFeaturedPools();
      props.setShowModal(false);
      enqueueSnackbar(`Successfully submitted featured pool ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true,
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to submit featured pool", {
        persist: false,
        disableWindowBlurListener: true,
      });
    }
    setPending(false);
  }

  // render the text for the action button
  const renderActionButtonText = () => {
    if (pending) return "Pending...";
    if (!provider || !account) return "Wallet Not Connected";
    return "Submit Pool";
  }

  // get the action button for the modal
  const getActionButton = () => {
    return (
      <ActionButton
        title={renderActionButtonText()}
        action={submitPool}
        disabled={pending || !provider || !account}
      />
    )
  }

  // show info about an existing submission if it exists
  const renderExistingSubmission = () => {
    const isFeatured = isPoolFeatured(props.pool.id);
    if (!isFeatured) return null;
    const entry = getFeaturedPoolData(props.pool.id);
    // use moment to get time until the submission expires
    const duration = entry?.featuredDuration;
    const durationDays = Number(featuredDuration) / 86400;
    if (!duration) return null;
    // format date to include date and time
    const formattedDuration = moment(duration * 1000).format("MMM DD, YYYY hh:mm A");
    return (
      <Alert severity="info" className="existing-submission-message">
        <span className="alert-header">Pool is Already Featured</span>
        <span>This pool is featured until {formattedDuration}. Resubmitting will extend the feature by {durationDays} days.</span>
      </Alert>
    );
  }

  // render the transaction review
  const renderTransactionReview = () => {
    return (
      <TransactionReview
        transactionInfo={getTransactionInfo()}
        actionButton={getActionButton()}
        hideHeader={false}
        showReview={true}
        setShowReview={() => {}}
      />
    )
  }

  return (
    <Modal
      showModal={props.showModal}
      setShowModal={props.setShowModal}
    >
      <div className="featured-manager-wrapper">
        <div className="featured-manager-header">
          <span className="header-title">Submit Featured Pool</span>
          <span className="header-description">Submit pool to the featured category</span>
        </div>
        {renderPoolInfo()}
        {renderExistingSubmission()}
        {renderTransactionReview()}
      </div>
    </Modal>
  )
}

export default SubmitFeaturedPool;

