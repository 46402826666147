import "./SettingsManager.css";
import { useContext, useState } from "react";
import UpdateFeeRate from "../../pages/MyPools/UpdateFeeRate";
import PauseBorrowing from "../../pages/MyPools/PauseBorrowing";
import SetRollover from "../SetRollover/SetRollover";
import SettingsTab from "./SettingsTab/SettingsTab";
import PoolReactions from "../PoolReactions/PoolReactions";
import SubmitFeaturedPoolTab from "../SubmitFeaturedPoolTab/SubmitFeaturedPoolTab";
import { WalletDataContext } from "../../context/WalletDataContext";
import { WALLET_DATA_CONTEXT } from "../../utils/Interfaces";

const SettingsManager = (props: {
  updatePoolData: () => void,
}) => {

  // list of pool reactions to determine if the settings tab should be shown
  const [poolReactionCount, setPoolReactionCount] = useState<number>(0);
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  // setup tabs
  const tabs = [
    {
      icon: "update",
      title: "Update Fee Rate",
      component: <UpdateFeeRate 
        updatePoolData={props.updatePoolData}
      />
    },
    {
      icon: "pause",
      title: "Pause Borrowing",
      component: <PauseBorrowing
        updatePoolData={props.updatePoolData}
      />
    },
    {
      icon: "rollover",
      title: "Set Rollover",
      component: <SetRollover/>
    },
    {
      icon: "reactions",
      title: "Pool Reactions",
      component: <PoolReactions
        setPoolReactionCount={setPoolReactionCount}
      />
    },
    {
      icon: "featured",
      title: "Feature Pool",
      component: <SubmitFeaturedPoolTab/>
    },
  ]

  // don't render the feature pool tab if not on arbitrum 
  const shouldRenderTab = (tab: {title: string}) => {
    if (tab.title === "Feature Pool" && chainId !== 42161)
      return false;
    else 
      return true;
  }

  // render the menu with all tabs
  const renderAllTabs = () => {
    return (
      <div className="settings-manager-tabs">
        {tabs.filter(shouldRenderTab).map((tab) => 
          <SettingsTab
            tab={tab}
            // force open the pool reactions tab if there are reactions
            forceOpen={tab.title === "Pool Reactions" && poolReactionCount > 0}
          />
        )}
      </div>
    );
  }

  return (
    <div className={`settings-manager-wrapper fade-in`}>
      {renderAllTabs()}
    </div>
  )
}

export default SettingsManager;