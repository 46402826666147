import React, { useState } from "react";
import { Plus, Minus, /* ChevronDown*/ 
Calendar} from "react-feather";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "./Selector.css";
import MobileDateTimePicker from "@mui/lab/MobileDateTimePicker";
import { useScreenSize } from "../../utils/useScreenSize";
import { getCurrentPage, mobileBreakpoint } from "../../utils/Utils";

const Selector = (props: {
  background?: string,
  type?: string,
  align?: string,
  setValue?: (val: any) => void,
  disabled: boolean,
  text: any,
  value?: any,
  buttons?: boolean
  shouldDisableDate?: (value: any) => boolean
}) => {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { screenWidth } = useScreenSize();

  const getDateFormat = () => {
    if (screenWidth > mobileBreakpoint) 
      return ("MMM d, Y hh:mm a")
    else 
      return ("M/d/yy hh:mm a")
  }

  const renderDate = (props:any) => {
    const date = props.inputProps.value;
    const split:string[] = date.split(" ");
    const line1 = split.slice(0, 3).join(" ");
    const line2 = split.slice(3).join(" ");
    return (
      <div
        onClick={() => {
          if (getCurrentPage() === "create-pool") setIsOpen(true);
        }}
        className="formatted-date"
      >
        <span className="formatted-date-top">{line1}</span>
        <span className="formatted-date-bottom">{line2}</span>
      </div>
    )
  }

  const disableDate = (value: any) => {
    if (props.shouldDisableDate)
      return props.shouldDisableDate(value)
    else 
      return new Date().getTime() / 1000 + 86400 > new Date(value).getTime() / 1000;
  }

  const toggleClicked = () => {
    if (props.disabled) return;
    setIsOpen(true);
  }

  return (
    <div className={"selector-container " + props.background}>
      {props.type !== "date" ? (
        <input
          className={`selector-text disable-select ${
            props.align ? "rate-selector" : ""
          }`}
          disabled={props.disabled}
          type={props.type ? props.type : ""}
          value={props.text}
          onChange={(e) => props.setValue && props.setValue(e.target.value)}
        />
      ) : (
        <LocalizationProvider dateAdapter={AdapterDateFns} className="fade-in">
          <div className="date-time-container fade-in">
            <Calendar/>
            <div className="date-time-modal-toggle" onClick={toggleClicked}></div>
            <MobileDateTimePicker
              renderInput={renderDate}
              className="fade-in"
              label="Expiry Date &amp; Time"
              disablePast={true}
              value={props.value}
              open={isOpen}
              disabled={props.disabled}
              shouldDisableDate={disableDate}
              showToolbar={false}
              inputFormat={getDateFormat()}
              onClose={() => setIsOpen(false)}
              onChange={(newValue: any) => {
                props.setValue && props.setValue(new Date(newValue));
              }}
            />
          </div>
        </LocalizationProvider>
      )}
      {props.buttons ? (
        <div className="selector-button-container">
          <Minus
            onClick={() =>
              props.setValue && props.setValue(Math.max(0, parseFloat(props.text) - 0.5))
            }
          />
          <span className="selector-number-symbol disable-select">%</span>
          <Plus onClick={() => props.setValue && props.setValue(parseFloat(props.text) + 0.5)} />
        </div>
      ) : (
        ""
      )}
      {/*<FontAwesomeIcon icon={faAngleDown} className="selector-arrow"/>*/}
    </div>
  );
};

export default Selector;