import "./WrongNetwork.css";
import { Alert } from "@material-ui/lab";
import { getChainQuery, getNetworkData, getWeb3Provider } from "../../utils/Utils";
import { useContext } from "react";
import { NETWORK_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { WalletDataContext } from "../../context/WalletDataContext";
import { useConnectWallet } from '@web3-onboard/react'
import { Provider as MulticallProvider } from "ethers-multicall";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
// @ts-ignore
declare var window: any;
window.Buffer = window.Buffer || require("buffer").Buffer; 

const WrongNetwork = () => {

  // import contexts
  const { chainId, account, setChainId, setProvider, setMulticallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const [{ wallet }] = useConnectWallet();
  const { enqueueSnackbar  } = useSnackbar();
  const navigate = useNavigate();

  const changeNetwork = async (networkData: NETWORK_DATA) => {
    // reset the token prices so they are
    // reloaded once the chainId changes
    try {
      let provider;
      let multicallProvider;
      let chainId;
      const isDevSite = window.location.host.includes("localhost") || window.location.host.includes("dev");
      if (!networkData.isLive && !isDevSite) return;
      if (window.ethereum && window.ethereum.selectedAddress !== null) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x" + networkData.chainId.toString(16)}], // chainId must be in hexadecimal numbers
        });
        provider = getWeb3Provider(wallet?.provider);
        // brand new users use the default RPC
        multicallProvider = new MulticallProvider(provider);
        multicallProvider.init();
        chainId = networkData.chainId;
      } else {
        chainId = networkData.chainId;
        provider = getWeb3Provider(networkData.rpcUrl, true);
        multicallProvider  = new MulticallProvider(provider);
        chainId = networkData.chainId;
      }
      setChainId(chainId);
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      enqueueSnackbar(`Successfully switched to ${networkData.chainName}`, {
        persist: false,
      });
      // update chain query param
      const path = window.location.pathname;
      navigate(`${path}${getChainQuery(networkData.chainId)}`);
    } catch (e) {
      enqueueSnackbar(`Failed to switch to ${networkData.chainName}`, {
        persist: false,
      });
      console.log(e);
    }
  }

  // check if the user's wallet is connected to the network being viewed
  const isOnSameNetwork = () => {
    // if there is no wallet in the browser, return true
    if (!window.ethereum || !account) 
      return true;
    // if the user's chainId is different than what is being viewed, return false
    else if (Number(window.ethereum.chainId) !== chainId) 
      return false;
    else return true;
  }

  const renderWrongNetworkMessage = () => {
    const connectedNetwork = getNetworkData(chainId);
    // render nothing if user is on the same network
    // or there is no wallet in the browser
    if (isOnSameNetwork() || !connectedNetwork)
      return <></>
    else 
      return (
        <Alert 
          severity="warning"
          onClick={() => changeNetwork(connectedNetwork)}
          className="wrong-network-message fade-in"
        >
          Your wallet is not connected to {connectedNetwork.chainName}. Click here to switch to {connectedNetwork.chainName}.
        </Alert>
      )
  }

  return (renderWrongNetworkMessage())
}

export default WrongNetwork;