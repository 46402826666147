import { Slider } from "@material-ui/core";
import { useState, useContext, useEffect } from "react";
import { Calendar } from "react-feather";
import Dropdown from "../Dropdown/Dropdown";
import TokenFilter from "../TokenFilter/TokenFilter";
import "./BorrowFilters.css";
import { AppDataContext } from "../../context/AppDataContext";
import { APP_DATA_CONTEXT } from "../../utils/Interfaces";
import { fetchIcon, isDarkMode } from "../../utils/Utils";
import ActionButton from "../ActionButton/ActionButton";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-date-range/dist/styles.css'; // main css file
import Modal from "../Modal/Modal";
import { addDays, subDays } from "date-fns";


const BorrowFilters = (props: {
  colFilter: string[],
  lendFilter: string[],
  setColFilter: (tokens: string[]) => void,
  setLendFilter: (tokens: string[]) => void,
}) => {

  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [showSliders, setShowSliders] = useState<boolean>(false);
  const [tempAvailable, setTempAvailable] = useState<number>(0);
  const [tempAPR, setTempAPR] = useState<number>(500);
  const [aprChanged, setAprChanged] = useState<boolean>(false);
  const [availableChanged, setAvailableChanged] = useState<boolean>(false);
  const [dateRangeChanged, setDateRangeChanged] = useState<boolean>(false);
  const [filterCount, setFilterCount] = useState<number>(0);
  const {
    liquidityRange,
    setLiquidityRange,
    aprRange,
    setAprRange,
    dateRange,
    setDateRange,
  } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    getFilterCount();
  // eslint-disable-next-line
  }, [aprChanged, availableChanged]);

  // get the number of filters being used
  const getFilterCount = () => {
    let count = 0
    if (aprChanged) count++;
    if (availableChanged) count++;
    setFilterCount(count);
  }

  // display the selected range
  const renderDateRange = () => {
    let startDate = dateRange[0].toString().split(" ").slice(1, 4).join(" ");
    let endDate = dateRange[1].toString().split(" ").slice(1, 4).join(" ");
    return (
      <div
        className="date-range-wrapper"
      >
        <span className="date-range-start">{startDate}</span>
        <span className="dash">-</span>
        <span className="date-range-end">{endDate}</span>
      </div>
    )
  }

  const renderCalendarModal = () => {
    const selectionRange = {
      startDate: dateRange[0],
      endDate: dateRange[1],
      key: 'selection',
    }

    const staticRanges = [
      {
        label: 'This Month',
        isSelected: (range: any) => {
          return false;
        },
        range: () => ({
          startDate: subDays(new Date(), 30),
          endDate: new Date()
        }),
      },
      {
        label: 'Next Month',
        isSelected: (range: any) => {
          return false;
        },
        range: () => ({
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 60)
        }),
      },
      {
        label: 'Two Months Away',
        isSelected: (range: any) => {
          return false;
        },
        range: () => ({
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 90)
        }),
      },
      {
        label: 'Three Months Away',
        isSelected: (range: any) => {
          return false;
        },
        range: () => ({
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 120)
        }),
      },
      {
        label: 'Four Months Away',
        isSelected: (range: any) => {
          return false;
        },
        range: () => ({
          startDate: addDays(new Date(), 1),
          endDate: addDays(new Date(), 120)
        }),
      },
    ];

    return (
      <Modal
        showModal={showCalendar}
        setShowModal={setShowCalendar}
      >
        <div className="calendar-wrapper">
          <DateRangePicker
            ranges={[selectionRange]}
            staticRanges={staticRanges}
            fixedHeight={true}
            onChange={(e) => {
              if (e.selection.startDate && e.selection.endDate) {
                setDateRangeChanged(true);
                setDateRange([e.selection.startDate, e.selection.endDate]);
              }
            }}
          />
          <div className="calendar-button-wrapper">
            <ActionButton
              title="Cancel"
              secondary={true}
              action={() => setShowCalendar(false)}
              disabled={false}
            />
            <ActionButton
              title="Apply"
              action={() => setShowCalendar(false)}
              disabled={false}
            />
          </div>
        </div>
      </Modal>
    )
  }

  // render date filter for pools
  const renderDateFilter = () => {
    return (
      <div className={`borrow-date-filter ${dateRangeChanged ? "active" : ""}`} onClick={() => setShowCalendar(true)}>
        <Calendar/>
        <span className="borrow-date-filter-label">
          {!dateRangeChanged ? "Select Dates" : renderDateRange()}
        </span>
      </div>
    )
  }

  // render slider for APR
  const renderAPRSlider = () => {
    return (
      <div className="slider-filter">
        <div className="slider-filter-header">
          <span>APR</span>
          <div className="apr-input-wrapper input-wrapper">
            <span className="value-label">max.</span>
            <input 
              value={tempAPR}
              type="number"
              onChange={(e) => {
                setAprRange([aprRange[0], Number(e.target.value)]);
                setTempAPR(Number(e.target.value));
                setAprChanged(true);
              }}
            />
            <span className="percent-sign">%</span>
          </div>
        </div>
        <Slider
          min={0}
          max={100}
          value={tempAPR}
          onChange={(e, value) => setTempAPR(value as number)}
          onChangeCommitted={(e, value) => {
            setAprRange([aprRange[0], value as number])
            setAprChanged(true);
          }}
          step={5}
          track={"normal"}
        />
      </div>
    );
  }

  // render slider for available liquidity
  const renderAvailableSlider = () => {
    return (
      <div className="slider-filter">
        <div className="slider-filter-header">
          <span>Available</span>
          <div className="available-input-wrapper input-wrapper">
            <span className="value-label">min.</span>
            <span className="dollar-sign">$</span>
            <input 
              value={tempAvailable}
              type="number"
              onChange={(e) => {
                setLiquidityRange([Number(e.target.value), liquidityRange[1]]);
                setTempAvailable(Number(e.target.value));
                setAvailableChanged(true);
              }}
            />
          </div>
        </div>
        <Slider
          min={0}
          max={20000}
          defaultValue={liquidityRange[0]}
          value={tempAvailable}
          onChange={(e, value) => setTempAvailable(value as number)}
          onChangeCommitted={(e, value) => {
            setLiquidityRange([value as number, liquidityRange[1]])
            setAvailableChanged(true);
          }}
          step={50}
          track={"normal"}
        />
      </div>
    )
  }

  // reset the slider filters back to the default values
  const resetFilters = () => {
    setAprRange([0, 500]);
    setDateRange([new Date(), addDays(new Date(), 300)]);
    setLiquidityRange([0, 1000000]);
    setTempAPR(500);
    setTempAvailable(0);
    setAprChanged(false);
    setAvailableChanged(false);
  }

  // render the sliders
  const renderSliderFilters = () => {
    return (
      <div 
        className={`borrow-slider-filters-wrapper ${filterCount > 0 ? "active" : ""}`}
        onClick={() => setShowSliders(true)}
      >
        {filterCount === 0 
          ? <img src={fetchIcon(`${isDarkMode() ? "filter-light" : "filter-dark"}`)} alt="filter"/>
          : <img src={fetchIcon(`${isDarkMode() ? "filter-light" : "filter-dark"}`)} alt="filter"/>
        }
        Filters
        {filterCount > 0 &&
          <div className={`filter-count ${filterCount > 0 ? "active" : ""}`}>
            {filterCount}
          </div>
        }
        {showSliders && (
          <Dropdown
            showDropdown={showSliders}
            setShowDropdown={setShowSliders}
          >
            {renderAPRSlider()}
            {renderAvailableSlider()}
            <ActionButton
              action={() => resetFilters()}
              title={"Reset Filters"}
              secondary={true}
              disabled={false}
            />
          </Dropdown>
        )}
      </div>
    )
  }

  return (
    <div className="borrow-filters-wrapper">
      {renderCalendarModal()}
      <section className="borrow-filters-left-section filter-section">
        <span className="borrow-filters-section-header">
          1. Choose tokens 
        </span>
        <div className="token-filters">
          <TokenFilter
            filter={props.colFilter}
            setFilter={props.setColFilter}
            defaultTitle={"Collateral"}
          />
          <TokenFilter
            filter={props.lendFilter}
            setFilter={props.setLendFilter}
            defaultTitle={"Borrow"}
          />
        </div>
      </section>
      <section className="borrow-filters-right-section filter-section">
        <span className="borrow-filters-section-header">
          2. Use filters for better search
        </span>
        <div className="extra-filters">
          {renderDateFilter()}
          {renderSliderFilters()}
        </div>
      </section>
    </div>
  )
}

export default BorrowFilters;