import { useContext, useState } from "react";
import { PoolDataContext } from "../../context/PoolDataContext";
import { POOL_DATA_CONTEXT } from "../../utils/Interfaces";
import ActionButton from "../ActionButton/ActionButton";
import SubmitFeaturedPool from "../SubmitFeaturedPool/SubmitFeaturedPool";

const SubmitFeaturedPoolTab = () => {

  const [showModal, setShowModal] = useState<boolean>(false);
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;

  return (
    <div className="pool-reactions-wrapper small-widget">
      <span className="widget-description">
        Submit your pool to the Featured category on the Borrow page for more exposure
      </span>
      <div className="settings-tab-button-wrapper submit-featured-pool">
        <ActionButton 
          disabled={false} 
          title={"Continue"} 
          action={() => setShowModal(true)}
          secondary={false}
        />
      </div>
      <SubmitFeaturedPool
        showModal={showModal}
        setShowModal={setShowModal}
        pool={selectedPool}
      />
    </div>
  )
}

export default SubmitFeaturedPoolTab;