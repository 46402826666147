import { Alert } from "@material-ui/lab";
import { Info } from "react-feather";
import "./GlobalMessage.css";

const GlobalMessage = (props: {
  message: string | undefined
  severity: "warning" | "info" | "error",
  account?: string
}) => {

  // const { account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;

  if (props.message)
    return (
      <div className="global-message-wrapper fade-in">
        <Alert severity={props.severity}>
          <span>
            <Info/>
            {props.message}
          </span>
        </Alert>
      </div>
    );
  else
    return null;
}

export default GlobalMessage;