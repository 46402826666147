import { Modal as ModalMUI } from "@material-ui/core";

const Modal = (props: {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  closeOnOuterClick?: boolean;
  children: any;
}) => {
  return (
    <ModalMUI
      open={props.showModal}
      onClose={() => {
        props.setShowModal(false);
      }}
      className="modal"
    >
      {props.children}
    </ModalMUI>
  );
};

export default Modal;
