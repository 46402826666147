import { useContext, useEffect, useState } from "react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, FEATURED_POOLS_MANAGER_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { getCurrentPage, getPoolsFromGraph, handleMulticallAddress, readableABIs } from "../../utils/Utils";
import { Contract as MulticallContract } from "ethers-multicall";
import "./PoolStatusContainer.css";
import { ethers } from "ethers";
import { AppDataContext } from "../../context/AppDataContext";
import { FeaturedPoolsManagerContext } from "../../context/FeaturedPoolsManagerContext";

const PoolStatusContainer = (props: {
  pool: POOL_DATA  
}) => {

  const [rolloverPools, setRolloverPools] = useState<any[]>([]);
  const [active, setActive] = useState<boolean[]>([false, false, false, false, false]);
  const { chainId, multicallProvider, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { isPoolFeatured } = useContext(FeaturedPoolsManagerContext) as FEATURED_POOLS_MANAGER_CONTEXT;

  // pools paused by first responder address
  const factoryPausedPools = [
    "0x39b9f0c8bd0b1add3756b0726c60e71a36225424",
    "0x4e5d12708d8157dc06c9c6c45ef9722a3b4773bd",
    "0xa273242093e0de7015d3e210bc871840ae735bf9",
    "0xe7e44cc639f2dff5e827492d48602ff3d632aa4f",
    "0xf8139974ad1d34bb2168fb5dff3e9cb9f00fecf6",
    "0x0767e3e55b1b056e9c1d128a3b73b04bc1246133",
    "0x07aAf6A1aa00bf75330D750f3F8E8E5eA8BD292f"
  ]

  useEffect(() => {
    getRolloverStatus();
  // eslint-disable-next-line
  }, [pending]);

  // get rollover pools and check if pool should have rollover status
  const getRolloverStatus = async () => {

    // if not on my-pools page, don't run
    if (getCurrentPage() !== "my-pools") return;

    let fetchedPools:any;
    let count = 0;

    // try to fetch pools 3 times
    // use the fallback after the first try
    while (fetchedPools === undefined && count < 3) {
      fetchedPools = await getPoolsFromGraph(
        chainId, 
        // don't use fallback on first try
        count === 0 ? false : true,
      );
    }

    if (!fetchedPools) {
      console.log("Failed to fetch rollover pools from graph");
      setRolloverPools([]);
      return;
    }

    let filteredRolloverPools:any[] = [];
    if (fetchedPools.length !== 0) {
      // get col and lend tokens
      const lendToken = props.pool.lendToken;
      const colToken = props.pool.colToken;
      const owner = props.pool.owner;

      // only work with the correct parameters
      filteredRolloverPools = fetchedPools.filter(
        (p: any) =>
          p.id.toLowerCase() !== props.pool.id.toLowerCase() &&
          p.colToken.toLowerCase() === colToken.toLowerCase() &&
          p.lendToken.toLowerCase() === lendToken.toLowerCase() &&
          p.deployer.toLowerCase() === owner.toLowerCase() &&
          p.expiry > props.pool.expiry &&
          !p._paused 
      )
    }

    setRolloverPools(await getWhitelistedRollovers(filteredRolloverPools));
  }

  const getWhitelistedRollovers = async (potentialRollovers: POOL_DATA[]) => {
    try {
      // setup the correct multicall address
      handleMulticallAddress(chainId, multicallProvider);
      // get all calls needed for multicall
      const calls = potentialRollovers.map((pool: POOL_DATA) => {
        const poolContract = new MulticallContract(
          ethers.utils.getAddress(props.pool.id),
          readableABIs.lendingPool
        );
        return (poolContract.allowedRollovers(ethers.utils.getAddress(pool.id)));
      });
      // execute contract calls 
      const response = await multicallProvider.all(calls);
      let whitelist:POOL_DATA[] = [];
      // check if any of the results return true and set it as the selected pool
      response.forEach((isSelected: boolean, index: number) => {
        if (isSelected) {
          whitelist.push(potentialRollovers[index]);
        }
      });
      return (whitelist);
    } catch (e) {
      return potentialRollovers;
    }
  }

  const updateActiveStatus = (index: number, value: boolean) => {
    let copy = [...new Array(active.length)];
    copy[index] = value;
    setActive(copy);
  }

  const renderPrivateStatus = () => {
    // remove empty items from the borrowers array
    const borrowers = props.pool.borrowers.filter((b: any) => b !== "");
    const isPrivate = borrowers.length > 0;
    if (isPrivate)
      return(
        <div 
          className={`pool-status-wrapper private-status ${active[0] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(0, true)}
          onMouseLeave={() => updateActiveStatus(0, false)}
        >
          {active[0] ? "Private" : "P"}
        </div>
      );
    else return null;
  }

  const renderRolloverStatus = () => {
    const hasRollovers = rolloverPools.length > 0;
    if (hasRollovers)
      return (
        <div 
          className={`pool-status-wrapper rollover-status ${active[1] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(1, true)}
          onMouseLeave={() => updateActiveStatus(1, false)}
        >
          {active[1] ? "Rollover" : "R"}
        </div>
      )
    else return null;
  }

  const renderPausedStatus = () => {
    // check if pool is paused but not expired
    const now = new Date().getTime() / 1000;
    const isPaused = props.pool.pauseTime <= now && now <= Number(props.pool.expiry);
    if (isPaused)
      return(
        <div 
          className={`pool-status-wrapper paused-status ${active[2] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(2, true)}
          onMouseLeave={() => updateActiveStatus(2, false)}
        >
          {active[2] ? "Paused" : "P"}
        </div>
      );
    else return null;
  }

  const renderFactoryPausedStatus = () => {
    const isPaused = factoryPausedPools.includes(props.pool.id.toLowerCase());
    if (isPaused)
      return(
        <div 
          className={`pool-status-wrapper factory-paused-status ${active[3] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(3, true)}
          onMouseLeave={() => updateActiveStatus(3, false)}
        >
          {active[3] ? "Factory Paused" : "F"}
        </div>
      );
    else return null;
  }

  const renderFeaturedStatus = () => {
    const isFeatured = isPoolFeatured(props.pool.id);
    const isOwner = account 
      ? props.pool.owner.toLowerCase() === account?.toLocaleLowerCase()
      : false;
    if (isFeatured && isOwner)
      return(
        <div 
          className={`pool-status-wrapper featured-status ${active[4] ? "active" : ""}`}
          onMouseOver={() => updateActiveStatus(4, true)}
          onMouseLeave={() => updateActiveStatus(4, false)}
        >
          {active[4] ? "Featured" : "F"}
        </div>
      );
    else return null;
  }

  return (
    <div className="pool-status-container">
      {renderPrivateStatus()}
      {renderRolloverStatus()}
      {renderPausedStatus()}
      {renderFactoryPausedStatus()}
      {renderFeaturedStatus()}
    </div>
  );
}

export default PoolStatusContainer;
