import { useState } from "react";
import { Search } from "react-feather";
import "./PoolFilters.css";

const PoolFilters = (props: {
  setSearchValue: (value: string) => void;
  children: any
}) => {

  const [searchValue, setSearchValue] = useState<string>("");

  const searchUpdated = (e: any) => {
    const val = e.target.value;
    setSearchValue(val);
    props.setSearchValue(val);
  };

  return (
    <div className="pool-filters-header">
      <div className="pool-filters-search-wrapper filter">
        <div
          className="search-toggle"
        >
          <Search />
        </div>
        <input
          className={`search-input`}
          placeholder="Search token symbols"
          value={searchValue}
          onInput={searchUpdated}
        />
      </div>
    </div>
  );
};

export default PoolFilters;