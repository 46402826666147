import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { Fragment, useContext, useEffect, useState } from "react"
import PoolFilters from "../../components/PoolFilters/PoolFilters";
import PoolRowsContainer from "../../components/PoolRowsContainer/PoolRowsContainer";
import BalanceManager from "./BalanceManager";
import "./MyPools.css";
import { PuffLoader } from "react-spinners";
import { isDarkMode, getNetworkData, getPoolFactoryContract, getPositionTrackerContract, handleMulticallAddress, isPoolExpired, mobileBreakpoint, pollTime, supportedNetworks, tabletBreakpoint, tokenData, ZERO_ADDRESS } from "../../utils/Utils";
import { BigNumber, ethers } from "ethers";
import PoolStats from "../../components/PoolStats/PoolStats";
import { useScreenSize } from "../../utils/useScreenSize";
import { BarChart2, X } from "react-feather";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import PoolSimulation from "../../components/PoolSimulation/PoolSimulation";
import ReactTooltip from "react-tooltip";
import LendingPoolABI from "../../abi/LendingPool";
import UpgradeImplementation from "./UpgradeImplementation/UpgradeImplementation";
import { Contract as MulticallContract } from "ethers-multicall";
import { useInterval } from "@usedapp/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import LineTabs from "../../components/LineTabs/LineTabs";
import SettingsManager from "../../components/SettingsManager/SettingsManager";
import WrongNetwork from "../../components/WrongNetwork/WrongNetwork";

const MyPools = () => {
  const [borrowedPools, setBorrowedPools] = useState<any[]>([]);
  const [lentPools, setLentPools] = useState<POOL_DATA[]>([]);
  const [mode, setMode] = useState<"borrowed" | "lent">("borrowed");
  const [searchValue, setSearchValue] = useState<string>("");
  const [poolsLoaded, setPoolsLoaded] = useState<boolean>(false);
  const [borrowedPoolsLoaded, setBorrowedPoolsLoaded] = useState<boolean>(false);
  const [lentPoolsLoaded, setLentPoolsLoaded] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("ltv");
  const [sortDirection, setSortDirection] = useState<"ascending" | "descending">("descending");
  const [selectedPool, setSelectedPool] = useState<POOL_DATA>();
  const [showExpiredPools, setShowExpiredPools] = useState<boolean>(false);
  const [showPoolStats, setShowPoolStats] = useState<boolean>(false);
  const [utilizationData, setUtilizationData] = useState<any[]>([{ name: "Used", value: 0},{ name: "Unused", value: 100}]);
  const [showPoolModal, setShowPoolModal] = useState<boolean>(false);
  const [shouldShowUpgrade, setShouldShowUpgrade] = useState<boolean>(false);
  const [selectedRollover, setSelectedRollover] = useState<POOL_DATA>();
  const [hasV1LentPools, setHasV1LentPools] = useState<boolean>(false);
  const [hasV1BorrowedPools, setHasV1BorrowedPools] = useState<boolean>(false);

  const { provider, chainId, account, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { fullPoolData } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { screenWidth } = useScreenSize();

  useEffect(() => {
    // reload user pool data after switching chains
    updatePoolData();
    setSelectedPool(undefined);
    getUserBorrowedPools();
    getUserLentPools();
  // eslint-disable-next-line
  }, [account, chainId]);

  useEffect(() => {
    handleAutoModeSelection();
    getUserV1LentPools();
    getUserV1BorrowedPools();
  // eslint-disable-next-line
  }, []);

  useInterval(() => {
    getUserV1LentPools();
    getUserV1BorrowedPools();
  }, pollTime * 2);

  useEffect(() => {
    if (selectedPool) {
      setShowPoolModal(true);
      if (poolsLoaded)
        getGraphData();
    }
    checkPoolEligibility();
    setSelectedRollover(undefined);
  // eslint-disable-next-line
  }, [selectedPool]);

  useEffect(() => {
    setPoolsLoaded(borrowedPoolsLoaded && lentPoolsLoaded);
    // set mode to borrowed if the user has lent pools but no borrowed pools
    if (borrowedPoolsLoaded && lentPoolsLoaded) {
      // get the number of non-expired borrowed and lent pools
      const nonExpiredBorrowedPools = borrowedPools.filter(pool => !isPoolExpired(pool));
      const nonExpiredLentPools = lentPools.filter(pool => !isPoolExpired(pool));
      // auto select lent pools if the user has lent pools but no borrowed pools
      if (nonExpiredBorrowedPools.length === 0 && nonExpiredLentPools.length > 0)
        modeClicked("lent")
    }
  // eslint-disable-next-line
  }, [borrowedPoolsLoaded, lentPoolsLoaded, lentPools, borrowedPools]);

  useInterval(() => {
    if (selectedPool) {
      updatePoolData();
    }
  }, pollTime);

  // check if pool is eligible for certain features
  const checkPoolEligibility = async () => {
    setShouldShowUpgrade(await isPoolEligibleForUpgrade());
  }

  // auto select a mode based on the URL  
  const handleAutoModeSelection = () => {
    // get the current page path
    const path = window.location.pathname;
    const parts = path.split("/");
    if (parts.length > 2) {
      const side = parts[2];
      // switch to lend or borrow mode
      if (side === "lent")
        setMode("lent");
      else if (side === "borrowed")
        setMode("borrowed");
    }
  }

  // select the first valid pool when the user switches modes
  const modeClicked = (newMode: "lent" | "borrowed") => {
    if (newMode === mode) return;
    setMode(newMode);
    if (newMode === "borrowed")  {
      const pool = selectFirstValidPool(borrowedPools);
      setSelectedPool(pool);
    } else if (newMode === "lent") {
      const pool = selectFirstValidPool(lentPools);
      setSelectedPool(pool);
    }
  }

  // check if the UpgradeImplementation component should be shown
  const isPoolEligibleForUpgrade = async () => {
    if (selectedPool) {
      // get necessary contracts
      const factoryContract = getPoolFactoryContract(
        provider?.getSigner() as any, 
        chainId
      );
      const poolContract = new ethers.Contract(
        selectedPool.id, 
        LendingPoolABI, 
        provider
      );
      // check if upgrade is allowed
      const canUpgrade = await factoryContract.allowUpgrade();
      // check if pool version is less than 3
      let versionEligible = false;
      try {
        const poolVersion:BigNumber = await poolContract.version();
        versionEligible = poolVersion.lte(BigNumber.from("3"));
      } catch (e) {
        // console.log(e);
        versionEligible = false;
      }
      return(canUpgrade && versionEligible);
    } else {
      return false;
    }
  }

  // auto select the first valid pool based on some criteria
  const selectFirstValidPool = (poolList: POOL_DATA[]):POOL_DATA | undefined => {
    let validPool:POOL_DATA | undefined = undefined;
    // convert fullPoolData object into iterable list
    const fullPoolDataList:POOL_DATA[] = [...new Array(Object.values(fullPoolData).length)].map((_, index) => 
      Object.values(fullPoolData)[index] as POOL_DATA
    );
    // is the found pool in the correct pool list
    const isInPoolList = (pool: POOL_DATA):boolean => {
      const inList = poolList.findIndex((currentPool: POOL_DATA) => currentPool.id === pool.id);
      return inList > -1;
    }
    // find and select the first valid pool if it exists
    for (const pool of fullPoolDataList) {
      if (showExpiredPools && isPoolExpired(pool) && isInPoolList(pool)) {
        validPool = pool;
        break;
      } else if (!showExpiredPools && !isPoolExpired(pool) && isInPoolList(pool)) {
        validPool = pool;
        break;
      }
    }
    return validPool;
  }

  const updatePoolData = () => {
    // setLentPools([]);
    // setBorrowedPools([]);
    getUserBorrowedPools();
    getUserLentPools();
    getGraphData();
  };

  // get all of the required data to display utilization data
  const getGraphData = async () => {
    if (!selectedPool || !chainId || !selectedPool.externalDataLoaded) return;

    // extract vars from pool
    const { rawTotalFees, totalBorrowed, poolLendBalance, lendDecimals } = fullPoolData[selectedPool.id];

    // format into human readable number
    const formattedTotalFees = parseFloat(ethers.utils.formatUnits(rawTotalFees || BigNumber.from("0"), lendDecimals));
    const formattedTotalBorrwed = parseFloat(totalBorrowed);
    const formattedPoolLendBalance = parseFloat(poolLendBalance || "0");

    // calculate utilization 
    let num1 = formattedTotalBorrwed + formattedPoolLendBalance - formattedTotalFees;
    // don't include total fees if the result makes num1 negative
    if (num1 < 0) num1 = formattedTotalBorrwed + formattedPoolLendBalance;
    const num2 = formattedTotalBorrwed;
    let utilization = num1 / num2;
    utilization = isNaN(utilization) ? 0 : 100 / utilization;

    let utilizationData = [
      { name: "Used", value: Math.min(utilization, 100)},
      { name: "Unused", value: 100 - utilization}
    ];

    setUtilizationData(utilizationData)
  }


  // return a list of a users borrow or lend positions based on the functions passed in
  const getUserPositionsFromPositionTracker = async (count: number, positionFunction: any) => {
    let positions:any[] = [];
    const noStart = "0x0000000000000000000000000000000000000000000000000000000000000000";
    let start = noStart;
    for (let i = 0; i < count; i++) {
      // fetch positions
      const userPositions = await positionFunction(
        account,
        start,
        20
      ) as any[];
      // set the starting point for the next iteration
      start = userPositions[0].id;
      // remove empty positions 
      for (const position of userPositions) {
        if (position.id !== noStart)
          positions.push(position);
      }
    }
    // reverse the array
    positions.reverse();
    return positions;
  }

  // get the pools that the user has borrowed from
  const getUserBorrowedPools = async () => {

    // wallet data is undefined at first so don't set loaded to true yet 
    // don't load pools if user isn't on supported network or if no account
    if ((!account || !supportedNetworks.includes(chainId) || !multicallProvider)) {
      setBorrowedPoolsLoaded(true);
      setBorrowedPools([]);
      return;
    }

    // setup the correct multicall address
    handleMulticallAddress(chainId, multicallProvider);

    // get reference to position tracker contract
    const positionTrackerContract = getPositionTrackerContract(
      provider as any,
      chainId
    );

    // get user's number of lend positions
    const positionCount:BigNumber = await positionTrackerContract.borrowPositionCount(account);
    const borrowPositions = await getUserPositionsFromPositionTracker(
      positionCount.gt(BigNumber.from("20")) ? 3 : 1,
      positionTrackerContract.getBorrowPositions
    )
    // const poolAddresses = lendPositions.map(({pool}: any) => {return {id: pool}}) as POOL_DATA[];
    const borrowPositionCalls = borrowPositions.map(({pool}) => {
      // setup contracts for multicall
      const poolContract = new MulticallContract(pool, LendingPoolABI);
      return poolContract.getPoolSettings();
    });
    // execute multicall
    const response = await multicallProvider.all(borrowPositionCalls);
    // extract pool data
    let pools = response.map((pool, index: number) => {return({...pool, id: borrowPositions[index].pool})});
    // filter out pools with old USDT address
    pools = pools.filter((pool: any) => {
      const oldUSDT = "0xbAc565f93f3192D35E9106E67B9d5c9348bD9389".toLowerCase();
      if (pool.colToken.toLowerCase() === oldUSDT || pool.lendToken.toLowerCase() === oldUSDT)
        return false;
      else return true;
    });
    setBorrowedPools(pools);
    setBorrowedPoolsLoaded(true);
  };

  const getUserV1BorrowedPools = async () => {
    // don't load pools if user isn't on supported network or if no account
    if (!account || (!supportedNetworks.includes(chainId))) {
      setBorrowedPoolsLoaded(true);
      setBorrowedPools([]);
      return;
    }

    // @ts-ignore
    const url:any = getNetworkData(chainId)?.v1GraphUrl;

    const query = `{
      borrowers(where: {id: "${account.toLowerCase()}"}) {
        positions {
          id
          effectiveRate 
          pool {
            id
            _deployer
            _colToken
            _lendToken
            _feeRate
            _expiry
            _mintRatio
            _startTime
            _borrowers
            _totalBorrowed
            _colBalance
            _lendBalance
            _paused
            _protocolFee
            _type
          }
        }
      }
    }`;

    // make graph request
    const borrowed = await axios.post(url,{ query });
    const res = borrowed.data.data.borrowers;

    // check to see if the user has any active borrow positions 
    let hasActivePositions = false;
    const borrowPositions = res[0].positions;
    borrowPositions.forEach((position: any) => {
      const pool = position.pool;
      if (pool._expiry > Math.floor(Date.now() / 1000))
        hasActivePositions = true;
    });

    // set the state
    if (hasActivePositions)
      setHasV1BorrowedPools(true);
  }

  // check if the user has any pools on the v1 site
  const getUserV1LentPools = async () => {

    // check for lent pools
    try {

      // wallet data is undefined at first so don't set loaded to true yet 
      // don't load pools if user isn't on supported network or if no account
      if (!account || !supportedNetworks.includes(chainId) || !multicallProvider) {
        setLentPoolsLoaded(true);
        setLentPools([]);
        return;
      }

      // @ts-ignore
      const url:any = getNetworkData(chainId)?.v1GraphUrl;
      
      const query = await axios.post(
        url,
        {
          query: `
          {
            pools(where: {
              _deployer: "${account.toLowerCase()}", _expiry_gt: ${Math.floor(Date.now() / 1000)}
            }) {
              id
              _deployer
              _colToken
              _lendToken
              _feeRate
              _expiry
              _mintRatio
              _startTime
              _borrowers
              _totalBorrowed
              _colBalance
              _lendBalance
              _paused
              _protocolFee
              _type
            }
          }
          `,
        }
      );
      const res = query.data.data.pools;
      if (res.length > 0) setHasV1LentPools(true);
    } catch (e) {}
  }

  // get the pools that the user has deployed / lent into
  const getUserLentPools = async () => {

    // wallet data is undefined at first so don't set loaded to true yet 
    // don't load pools if user isn't on supported network or if no account
    if ((!account || !supportedNetworks.includes(chainId) || !multicallProvider)) {
      setLentPoolsLoaded(true);
      setLentPools([]);
      return;
    }

    // get reference to position tracker contract
    const positionTrackerContract = getPositionTrackerContract(
      provider as any, 
      chainId
    );

    // get user's number of lend positions
    const positionCount:BigNumber = await positionTrackerContract.lendPositionCount(account);
    const lendPositions = await getUserPositionsFromPositionTracker(
      positionCount.gt(BigNumber.from("20")) ? 3 : 1,
      positionTrackerContract.getLendPositions   
    )
    // const poolAddresses = lendPositions.map(({pool}: any) => {return {id: pool}}) as POOL_DATA[];
    const lendPositionCalls = lendPositions.map(({pool}) => {
      // setup contracts for multicall
      const poolContract = new MulticallContract(pool, LendingPoolABI);
      return poolContract.getPoolSettings();
    });
    // execute multicall
    const response = await multicallProvider.all(lendPositionCalls);
    let uniquePools:any[] = [];
    // extract pool data
    let pools = response.map((pool, index: number) => {
      return({
        ...pool, 
        id: lendPositions[index].pool
      })
    });
    const oldUSDT = "0xbAc565f93f3192D35E9106E67B9d5c9348bD9389".toLowerCase();
    // remove duplicate pools
    pools = pools.filter((pool: any) => {
      // filter out pools with old USDT address
      if (pool.colToken.toLowerCase() === oldUSDT || pool.lendToken.toLowerCase() === oldUSDT)
        return false;
      if (uniquePools.includes(pool.id)) return false;
      else {
        uniquePools.push(pool.id);
        return true;
      }
    });
    setLentPools(pools);
    setLentPoolsLoaded(true);
  };

  // render the switch for toggling between active and expired pools
  const renderSwitch = () => {
    let activeText = "Active Pools";
    let expiredText = "Expired Pools";
    if (screenWidth < mobileBreakpoint) {
      activeText = "Active";
      expiredText = "Expired";
    }
    return (
      <div className="mode-switch-container">
        <div className={`mode-switch-slider ${showExpiredPools ? 'left' : 'right'}`}></div>
        <button
          onClick={() => setShowExpiredPools(false)}
          className={`mode-switch-button ${showExpiredPools ? '' : 'active'}`}
        >
          {activeText}
        </button>
        <button
          onClick={() => setShowExpiredPools(true)}
          className={`mode-switch-button ${showExpiredPools ? 'active' : ''}`}
        >
          {expiredText}
        </button>
      </div>
    )
  }

  // render a percent for how much of the pool is being utilized
  const renderUtilizationValue = () => {
    let data = utilizationData.find((data) => data.name === "Used");
    return (
      <Label position="center" className="utilization-value">
        {`${(data.value).toFixed(1)}%`}
      </Label>
    )
  }

  // render the stats for the selected pool
  const renderRateStats = (noData?: boolean) => {
    let strategyRate = "N/A";
    let poolInterestOwed = "N/A";
    let lendSymbol = "";
    // if there is data and a selected pool, set the stats
    if (!noData && selectedPool && selectedPool.externalDataLoaded) {
      lendSymbol = selectedPool.lendSymbol;
      poolInterestOwed = `${selectedPool.poolInterestOwed.toFixed(3)}`;
      // if the pool is using a strategy show the rate
      if (selectedPool.strategy !== ZERO_ADDRESS)
        strategyRate = `${selectedPool.strategyRate.toFixed(3)}%`;
    }
    return (
      <div className="rate-stats-wrapper">
        <div className="rate-stat">
          <div className="rate-stat-label">Strategy Rate: </div>
          <div className="rate-stat-value">{strategyRate}</div>
        </div>
        <div className="rate-stat">
          <div className="rate-stat-label">Fees Earned: </div>
          <div className="rate-stat-value">{poolInterestOwed} {lendSymbol}</div>
        </div>
      </div>
    )
  }

  const renderUtilizationData = () => {

    const radius = 75;

    // fake utilization data for when there is no data
    const dummyData = [
      { name: "Unused", value: 50},
      { name: "Used", value: 50}
    ];

    // gradient colors for price graph area
    const gradientColors = () => {
      if (!isDarkMode()) 
        return (
          <linearGradient id="pieGradient" x1="0" y1="0" x2="1" y2="0" spreadMethod="reflect">
            <stop offset="0%" stopColor="var(--cta-blue)" stopOpacity={1} />
            <stop offset="95%" stopColor="var(--cta-orange)" stopOpacity={1} />
          </linearGradient>
        );
      else 
        return (
          <linearGradient id="pieGradient" x1="0" y1="0" x2="1" y2="0" spreadMethod="reflect">
            <stop offset="0%" stopColor="rgba(221, 128, 34, 1)" stopOpacity={1} />
            <stop offset="40%" stopColor="rgba(219, 90, 35, 1)" stopOpacity={1} />
            <stop offset="80%" stopColor="rgba(23, 92, 211, 1)" stopOpacity={1} />
          </linearGradient>
        );
    };

    const unusedColor = !isDarkMode() ? 'var(--input)' : "black" ;

    // what to render if no data is available
    const noData = (
      <Fragment>
        <span className="no-data-message">No Data Available</span>
        <ResponsiveContainer width="100%" height="83%">
          <PieChart>
          <defs>
            {gradientColors()}
          </defs>
          <Pie
            data={dummyData}
            cx="50%"
            cy="50%"
            labelLine={false}
            animationDuration={500}
            animationBegin={0}
            outerRadius={radius}
            strokeLinecap={"round"}
            strokeLinejoin={"round"}
            paddingAngle={1}
            blendStroke={true}
            innerRadius={radius - 16}
            dataKey="value"
            cornerRadius={10}
          >
            {dummyData.reverse().map((entry, index) => {
              return <Cell 
                  key={`cell-${index}`} 
                  fill={`${entry.name === "Used" ? "url(#pieGradient)"  : unusedColor}`} 
                  style={{zIndex: `${entry.name === "Used" ? "2" : "1"}`}}
                />
            })}
          </Pie>
          </PieChart>
        </ResponsiveContainer>
        {renderRateStats(true)}
      </Fragment>
    );

    // render either noData component or show real data
    if ((mode === "borrowed" && borrowedPools.length === 0 && !poolsLoaded) || (!selectedPool && poolsLoaded)) 
      return(noData);
    else if ((mode === "lent" && lentPools.length === 0 && !poolsLoaded) ||  (!selectedPool && poolsLoaded)) 
      return(noData);
    else 
      return (
        <Fragment>
          <ResponsiveContainer width="100%" height="83%">
            <PieChart>
              <defs>
                {gradientColors()}
              </defs>
              <Pie
                data={utilizationData}
                cx="50%"
                cy="50%"
                labelLine={false}
                animationDuration={500}
                animationBegin={0}
                outerRadius={radius}
                paddingAngle={utilizationData[0].value <= 0 || utilizationData[0].value >= 100 ? 0 : 1}
                strokeLinecap={"round"}
                strokeLinejoin={"round"}
                blendStroke={true}
                innerRadius={radius - 16}
                cornerRadius={100}
                dataKey="value"
              >
                {utilizationData.map((entry, index) => {
                  return <Cell 
                      key={`cell-${index}`} 
                      fill={`${entry.name === "Used" ? "url(#pieGradient)"  : unusedColor}`} 
                      z={`${entry.name === "Used" ? 1 : 2}`}
                    />
                })}
              {renderUtilizationValue()}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          {renderRateStats()}
        </Fragment>
      );
  }

  const renderPriceData = () => {
    if (!selectedPool)
      return (
        <PoolSimulation
          // @ts-ignore
          colToken={tokenData.WETH.address[chainId]}
          // @ts-ignore
          lendToken={tokenData.USDC.address[chainId]}
          mintRatio={0}
          fakeData={true}
        />
      )
    else 
      return (
        <PoolSimulation
          colToken={selectedPool.colToken}
          lendToken={selectedPool.lendToken}
          mintRatio={ethers.utils.formatUnits(selectedPool.mintRatio, 18)}
          startTime={selectedPool.auctionStartDate}
        />
      );
  }

  const renderV1Message = () => {
    const alertClicked = () => {
      const side = hasV1BorrowedPools ? "borrowed" : "lent";
      window.open(`https://v1.vendor.finance/my-pools/${side}/?chain=arbitrum`, "_blank");
    }
    return (
      <div 
        className="v1-message"
      >
        <Alert severity="info">
          <span>You have pools on Vendor Finance V1. <u onClick={alertClicked}>Click here</u> to access your V1 pools.</span>
        </Alert>
        <a href="https://docs.vendor.finance/overview/v2-migration" target="_blank" rel="noreferrer">
          Read More
        </a>
      </div>
    )
  }

  const renderRightSection = () => {
    if (screenWidth > tabletBreakpoint)
      return (
        <section className="my-pools-right-section page-right-section">
          {mode === "lent" && shouldShowUpgrade && 
            <UpgradeImplementation
              checkPoolEligibility={checkPoolEligibility}
            />
          }
          <BalanceManager 
            mode={mode}
            updatePoolData={updatePoolData}
          />
          {mode === "lent" && (selectedPool && !isPoolExpired(selectedPool) && selectedPool.owner.toLowerCase() === account?.toLowerCase()) ?  
            <SettingsManager
              updatePoolData={updatePoolData}
            />
          :''}
        </section>
      );
    else 
      if (showPoolModal)
        return (
          <section className="my-pools-right-section modal">
            <div className="my-pools-close-modal" onClick={() => setShowPoolModal(false)}>
              <X/>
            </div>
            <BalanceManager 
              mode={mode}
              updatePoolData={updatePoolData}
            />
            {mode === "lent" && (selectedPool && !isPoolExpired(selectedPool)) ?  
              <div className="my-pools-small-widgets-wrapper fade-in">
                {shouldShowUpgrade && 
                  <UpgradeImplementation
                    checkPoolEligibility={checkPoolEligibility}
                  />
                }
                <SettingsManager
                  updatePoolData={updatePoolData}
                />
              </div>
            :''}
          </section>
        );
      else return null;
  }

  const renderTabTitle = (tab: string) =>{
    // filter out expired pools if showExpiredPools is false
    let pools = tab === "Lent" ? lentPools : borrowedPools;
    if (showExpiredPools) 
      pools = pools.filter(pool => isPoolExpired(pool));
    else 
      pools = pools.filter(pool => !isPoolExpired(pool));

    return ([
      <div className="tab-title-wrapper">
        <span className={`tab-name ${tab.toLowerCase() === mode ? "active" : ""}`}>{tab}</span>
        <span className="tab-value">{pools.length}</span>
      </div>,
    ])
  }

  return (
    <div className="my-pools-wrapper fade-in">
      <PoolDataContext.Provider value={{ selectedPool, setSelectedPool, lentPools, selectedRollover, setSelectedRollover }}>
        <section className="my-pools-left-section page-left-section">
          <WrongNetwork/>
          <section className="my-pools-graph-section">
            <div className="my-pools-left-graph">
              {renderPriceData()}
            </div>
            <div className="my-pools-right-graph graph-container">
              {selectedPool && poolsLoaded && <span className="my-pools-utilization-text">Utilized</span>}
              <div className="my-pools-graph-title">
                <ReactTooltip 
                  id={`pool-stats-tip`} 
                  type="info" 
                  className="tool-tip" 
                  place={screenWidth > mobileBreakpoint ? "bottom" : "left"}
                >
                  <PoolStats
                    showModal={showPoolStats}
                    setShowModal={setShowPoolStats}
                    selectedPool={selectedPool}
                    mode={mode}
                    hover={true}
                  />
                </ReactTooltip>
                <span>Pool Utilization</span>
                <div 
                  className="pool-stats-button" 
                  data-tip data-for={`pool-stats-tip`} 
                  onClick={() => setShowPoolStats(true)}
                >
                  <BarChart2/>
                </div>
              </div>
              {!poolsLoaded ? 
                <PuffLoader className="loader"/>
              :
                renderUtilizationData()
              }
            </div>
          </section>
          <div className="my-pools-header">
            {renderSwitch()}
            <PoolFilters 
              setSearchValue={setSearchValue} 
              children={undefined}
            />
          </div>
          <div className="my-pools-line-tab-wrapper">
            <LineTabs
              tabs={["Lent", "Borrowed"].map((tab) => renderTabTitle(tab))}
              activeTab={mode === "lent" ? 0 : 1}
              setActiveTab={(index: number) => {
                setMode(index === 0 ? "lent" : "borrowed");
                modeClicked(index === 0 ? "lent" : "borrowed");
              }}
            />
          </div>
          <PoolRowsContainer
            pools={mode === "borrowed" ? borrowedPools : lentPools}
            mode={mode}
            showPools={true}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            searchValue={searchValue}
            showExpiredPools={showExpiredPools}
            myPools={true}
            poolsLoaded={poolsLoaded}
          />
          {(hasV1LentPools || hasV1BorrowedPools) && renderV1Message()}
        </section>
        {renderRightSection()}
      </PoolDataContext.Provider>
    </div>
  );
};

export default MyPools;