import { 
  Heart,
  DollarSign,
  TrendingUp,
  RefreshCw,
  Clock
} from "react-feather";
import "./SendPoolReaction.css";
import Modal from "../Modal/Modal";
import { useContext, useState } from "react";
import ActionButton from "../ActionButton/ActionButton";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import axios from "axios";
import { AppDataContext } from "../../context/AppDataContext";
import { useSnackbar } from "notistack";
import { useDevFunctions } from "../../utils/Utils";

const SendPoolReaction = (props: {
  showModal: boolean,
  setShowModal: (show: boolean) => void,
  pool: POOL_DATA
}) => {

  // currently selected reaction
  const [selectedReaction, setSelectedReaction] = useState<number>(-1);
  // import contexts
  const { account, provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending, recaptchaRef } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  // import snackbar
  const { enqueueSnackbar } = useSnackbar();

  // list of valid reactions
  const reactions = [
    {
      icon: <RefreshCw/>,
      text: "Please make a rollover pool!"
    },
    {
      icon: <TrendingUp/>,
      text: "The interest rate is too high!"
    },
    {
      icon: <Clock/>,
      text: "The loan is too short!"
    },
    {
      icon: <DollarSign />,
      text: "Please deposit more funds!"
    },
    {
      icon: <Heart/>,
      text: "Great pool terms!"
    }
  ];

  // called when a reaction is clicked
  const optionClicked = (index: number) => {
    if (index === selectedReaction) 
      setSelectedReaction(-1);
    else 
      setSelectedReaction(index);
  }

  const renderReactions = () => {
    return reactions.map((reaction, index) => {
      return (
        <div 
          className={`reaction-option ${selectedReaction === index ? "selected" : ""}`}
          key={index}
          onClick={() => optionClicked(index)}
        >
          <div className="reaction-option-icon">
            {reaction.icon}
          </div>
          <div className="reaction-option-text">
            {reaction.text}
          </div>
        </div>
      )
    });
  }

  const sendReaction = async () => {

    // user can't make a request without a wallet
    if (!account || !provider) {
      enqueueSnackbar("Please connect your wallet to send a pool reaction");
      return;
    }

    setPending(true);
    let error = "Failed to send reaction";
    try {

      enqueueSnackbar("Please sign the message in your wallet to continue");

      // get recaptcha token
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      if (!token || token === "") {
        enqueueSnackbar("Failed to get recaptcha token");
        setPending(false);
        return;
      }

      // request user signature
      const signer = provider.getSigner();
      const message = "Please sign this message to send a pool reaction";
      const signedMessage = await signer.signMessage(message);

      const url = useDevFunctions
        ? new URL("http://127.0.0.1:5001/vendor-finace/us-central1/savePoolReaction")
        : new URL("https://us-central1-vendor-finace.cloudfunctions.net/savePoolReaction");
      url.searchParams.append("poolId", props.pool.id.toLowerCase());
      url.searchParams.append("poolOwner", props.pool.owner.toLowerCase());
      url.searchParams.append("message", reactions[selectedReaction].text);
      url.searchParams.append("sender", account);
      url.searchParams.append("signature", signedMessage);
      url.searchParams.append("reCaptchaResponse", token);
      url.searchParams.append("chainId", chainId.toString());
      url.searchParams.append("expireAt", props.pool.expiry);
      
      // send request to backend
      const req = await axios.get(url.href);
      // handle custom error
      if (req.data.error) {
        error = `Failed to send reaction: ${req.data.error}`;
        throw new Error(error);
      }
      enqueueSnackbar("Pool reaction successfully sent");
      // close modal
      props.setShowModal(false);

    } catch (e) {
      console.log(e);
      enqueueSnackbar(error);
    }
    setPending(false);
  }

  return (
    <Modal showModal={props.showModal} setShowModal={props.setShowModal}>
      <div className="reactions-wrapper">
        <div className="reactions-header">
          <span className="header-title">Pool Reactions</span>
          <span className="header-description">Choose a reaction to send to the owner of this pool</span>
        </div>
        {renderReactions()}
        <div className="reaction-submit-button">
          <ActionButton
            title={pending ? "Sending..." : "Send Reaction"}
            disabled={selectedReaction === -1 || pending}
            action={sendReaction}
          />
        </div>
      </div>
    </Modal>
  )
}

export default SendPoolReaction;