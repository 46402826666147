import { BigNumber, ethers } from "ethers";
import { Fragment, useContext, useEffect, useState } from "react";
import { Copy, HelpCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import Selector from "../../../components/Selector/Selector";
import TokenModal from "../../../components/TokenModal/TokenModal";
import TokenSelector from "../../../components/TokenSelector/TokenSelector";
import { AppDataContext } from "../../../context/AppDataContext";
import { CreatePoolContext } from "../../../context/CreatePoolContext";
import { WalletDataContext } from "../../../context/WalletDataContext";
import { APP_DATA_CONTEXT, CREATE_POOL_CONTEXT, CREATE_POOL_PAGES_CONTEXT, WALLET_DATA_CONTEXT } from "../../../utils/Interfaces";
import { useScreenSize } from "../../../utils/useScreenSize";
import { contractAddresses, getCurrentPage, getPoolFactoryContract, handleMulticallAddress, hasOracle, mobileBreakpoint, readableABIs, tabletBreakpoint } from "../../../utils/Utils";
import { Contract as MulticallContract } from "ethers-multicall";
import "./Page.css";
import { Alert } from "@material-ui/lab";
import { CreatePoolPagesContext } from "../../../context/CreatePoolPagesContext";
import { Slider } from "@material-ui/core";
import { useSnackbar } from "notistack";

const Page1 = () => {

  const [showTokenModal, setShowTokenModal] = useState<boolean>(false);
  const [selectedInput, setSelectedInput] = useState("0");
  const [tempSelectedMintRatio, setTempSelectedMintRatio] = useState<number>(0);

  // import relvant context variables
  const { screenWidth } = useScreenSize();
  const { enqueueSnackbar /* closeSnackbar */ } = useSnackbar();
  const { provider, chainId, account, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { 
    lendRatioChanged, 
    setLendRatioChanged, 
    hideRecommendation,
    calculatedLtv
  } = useContext(CreatePoolPagesContext) as CREATE_POOL_PAGES_CONTEXT;
  const { tokenPrices, calculateLTV } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { 
    selectedCollateral, 
    setSelectedCollateral, 
    selectedLend, 
    setSelectedLend,
    selectedMintRatio,
    setSelectedMintRatio,
    selectedExpiry,
    setSelectedExpiry,
    setStepButtonState,
    initialDeposit,
    setInitialDeposit,
    setSelectedMintRatioButton,
    lendBalance,
    setLendBalance,
    setLendAllowance,
    setSelectedAuctionDates,
    setProtocolFee,
    autoPause,
    setAutoPause,
    selectedAPR,
    protocolFee,
    rateType
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;

  useEffect(() => {
    manageActionButton();
  // eslint-disable-next-line
  }, [selectedMintRatio, selectedExpiry, initialDeposit, selectedLend, selectedCollateral, lendBalance]);

  // get the token price on load
  useEffect(() => {
    getLendAllowance();
    // eslint-disable-next-line
  }, [chainId, multicallProvider]);

  useEffect(() => {
    getFeeRate();
    // setTempSelectedMintRatio(Math.round(getLtv(selectedMintRatio) * 10));
    // check if this pool creation is for a rollover pool
    const urlParams = new URLSearchParams(window.location.search);
    const originPoolQuery = urlParams.get("originPool");
    const lendRatioQuery = urlParams.get("lendRatio")
    if (originPoolQuery !== null && lendRatioQuery !== null && !lendRatioChanged) {
      const formattedLendRatio = Number(ethers.utils.formatUnits(lendRatioQuery, 18));
      setTempSelectedMintRatio(getLtv(formattedLendRatio));
      // setTempSelectedMintRatio(50)
    }
  // eslint-disable-next-line
  }, [selectedMintRatio, tokenPrices]);

  useEffect(() => {
    setTempSelectedMintRatio(calculatedLtv);
  }, [calculatedLtv]);

  const getFeeRate = async () => {
    if (!provider || !chainId) return;
    const poolFactoryContract = getPoolFactoryContract(provider, chainId);
    const res = await poolFactoryContract.protocolFee();
    setProtocolFee(res / 1000000);
  }

  // check the allowance for the currently selected lend token
  const getLendAllowance = async () => {
    if (!provider || !chainId || !multicallProvider) return;
    // setup the correct multicall address
    handleMulticallAddress(chainId, multicallProvider);
    // get reference to contract
    const tokenContract = new MulticallContract(
      ethers.utils.getAddress(selectedLend.address),
      readableABIs.erc20
    );
    // execute contract calls 
    const response = await multicallProvider.all([
      // @ts-ignore
      tokenContract.allowance(account, contractAddresses.POOL_FACTORY.address[chainId]),
      tokenContract.balanceOf(account)
    ]);
    // extract multicall responses
    const [ allowance, balance ] = response;
    // update state
    setLendAllowance(allowance);
    setLendBalance(balance);
  }

  // manage input errors and display error message
  const manageActionButton = () => {
    let rawInitialDeposit: any;
    try {
      rawInitialDeposit = initialDeposit.toString().length === 0 
        ? BigNumber.from("0")
        : ethers.utils.parseUnits(initialDeposit.toFixed(selectedLend.decimals), selectedLend.decimals);
    } catch (e) {
      rawInitialDeposit = BigNumber.from("0");
    }
    if (selectedCollateral.symbol === selectedLend.symbol) {
      setStepButtonState("Invalid Collatera/Lend Pair");
    } else if (selectedMintRatio <= 0) {
      setStepButtonState("Invalid Lend Ratio");
    } else if (new Date(selectedExpiry).getTime() < new Date().getTime()) {
      setStepButtonState("Invalid Expiry");
    } else if (lendBalance.lt(rawInitialDeposit) && getCurrentPage() !== "request-pool") {
      setStepButtonState(`Insufficient ${selectedLend.symbol} balance`);
    } else {
      setStepButtonState("");
    }
  }

  // calculate mint ratio based on % button click
  const calculateMintRatio = (buttonValue: number) => {
    const colPrice = (tokenPrices[selectedCollateral.address] || 0);
    const lendPrice = (tokenPrices[selectedLend.address] || 0);
    if (colPrice === 0 || lendPrice === 0) return 0;
    // get a percentage value of the collateral price
    const colValue = colPrice * (buttonValue / 100);
    // get how many lend tokens are needed to match that percent value 
    const lendValue = colValue / lendPrice;
    // different precision for different token pairs
    const res = lendValue.toFixed(lendValue > 0.001 ? 3 : 5);
    return (Number(res));
  }

  // listen for mint ratio button clicks
  const mintRatioClicked = (value: any) => {
    setSelectedMintRatioButton(value);
    setLendRatioChanged(true);
    if (value === "Custom") {
      setSelectedMintRatio("");
      const input = document.querySelector("input.lend-ratio-input") as HTMLInputElement;
      if (input) input.focus();
      return;
    } else {
      setSelectedMintRatio(calculateMintRatio(value));
    }
  };

  // listen for changes in initial deposit
  const initialDepositInputChange = (e:any) => {
    let val = 0;
    if (!isNaN(Number(e.target.value))) 
      val = e.target.value;
    setInitialDeposit(val);
  }

  const handleCopyAddress = (symbol: string, address: string) => {
    navigator.clipboard.writeText(address);
    enqueueSnackbar(`${symbol} address copied to clipboard`, {
      persist: false,
      disableWindowBlurListener: true,
    });
  }

  // render inputs for selecting col/lend tokens
  const renderTokenSelectors = () => {
    return (
      <div>
        <div className="inner-token-input-container">
          <div className="row-title">
            <span className="row-title-label">
              <ReactTooltip 
                id="collateral-token-tip" 
                type="info" 
                className="tool-tip"
                place={screenWidth > tabletBreakpoint ? "top" : "right"}
                arrowColor="var(--cta-blue)"
              >
                Copy Address
              </ReactTooltip>
              Collateral Token
              <Copy 
                onClick={() => handleCopyAddress(selectedCollateral.symbol, selectedCollateral.address)}
                className="row-title-token-link"
                data-for="collateral-token-tip"
                data-tip
              />
            </span>
          </div>
          <div className="token-selector-long-wrapper">
            <TokenSelector
              value={(tokenPrices[selectedCollateral.address] || 0).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: selectedCollateral.displayDecimals,
                }
              )}
              symbol={selectedCollateral.symbol}
              id="0"
              select={true}
              text=""
              setShowModal={setShowTokenModal}
              setSelectedInput={setSelectedInput}
            ></TokenSelector>
          </div>
        </div>
        <div className="inner-token-input-container">
          <div className="row-title">
            <span className="row-title-label">
              <ReactTooltip 
                id="lend-token-tip" 
                type="info" 
                className="tool-tip"
                place={screenWidth > tabletBreakpoint ? "top" : "right"}
                arrowColor="var(--cta-blue)"
              >
                Copy Address
              </ReactTooltip>
              Lend Token
              <Copy 
                onClick={() => handleCopyAddress(selectedLend.symbol, selectedLend.address)}
                className="row-title-token-link"
                data-for="lend-token-tip"
                data-tip
              />
            </span>
          </div>
          <div className="token-selector-long-wrapper">
            <TokenSelector
              value={(tokenPrices[selectedLend.address] || 0).toLocaleString(undefined, {
                minimumFractionDigits: selectedLend.displayDecimals,
              })}
              symbol={selectedLend.symbol}
              id="1"
              select={true}
              text=""
              setShowModal={setShowTokenModal}
              setSelectedInput={setSelectedInput}
            ></TokenSelector>
          </div>
        </div>
        <div className="info-section-container block">
          <ReactTooltip 
            id="lend-ratio-tip" 
            type="info" 
            className="tool-tip"
            place={screenWidth > tabletBreakpoint ? "top" : "right"}
            effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            arrowColor="var(--cta-blue)"
          >
            <span>
              Amount of {selectedLend.symbol} that is lent per 1 unit of{" "}
              {selectedCollateral.symbol}. The slider represents the initial LTV.
            </span>
          </ReactTooltip>
          <ReactTooltip 
            id="custom-lend-ratio-tip" 
            type="info" 
            className="tool-tip"
            place={screenWidth > tabletBreakpoint ? "top" : "top"}
            effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
          >
            <span>
              Custom amount of {selectedLend.symbol} that is lent per 1 unit of{" "}
              {selectedCollateral.symbol}. This is <em>not</em> a percent.
            </span>
          </ReactTooltip>

        </div>
      </div>
    )
  }

  const getLtv = (_ratio?: number) => {
    if (!selectedLend.address || !selectedCollateral.address) return 0;
    // pass in what the fee rate would be
    const currentFeeRate = rateType === "Decay" 
      // decaying rate is calculated based on the time left in the pool
      ? selectedAPR[0] * (selectedExpiry - new Date().getTime()) / (31_536_000 * 1000)
      // fixed and rates are just the selected rate
      : selectedAPR[0];

    // sanitize the ratio to prevent crashing
    let ratio = selectedMintRatio === ""
      ? 0
      : selectedMintRatio;

    if (_ratio)
      ratio = _ratio;

    // data for the current pool parameters
    const poolData:any = {
      lendToken: selectedLend.address,
      colToken: selectedCollateral.address,
      mintRatio: ethers.utils.parseUnits(ratio.toString(), 18),
      currentFeeRate: currentFeeRate * 10000,
      protocolFee: protocolFee
    }
    // calculate ltv based on pool parameters
    const ltv = calculateLTV(poolData);
    return ltv;
  }

  const renderLendRatioInput = () => {

    const marks = [
      { value: 20, label: '20%' },
      { value: 40, label: '40%' },
      { value: 60, label: '60%' },
      { value: 80, label: '80%' },
    ];

    return (
      <Fragment>
        <div className="lend-ratio-input-container">
          <div className="info-title lend-ratio-title" >
            <div>
              <span>Lend Ratio <HelpCircle data-tip data-for="lend-ratio-tip"/></span>
              <span className="lend-ratio-hint">
                ({selectedLend.symbol} per {selectedCollateral.symbol})
              </span>
            </div>
            <input
              type="number"
              placeholder="Amount"
              className="lend-ratio-input"
              value={selectedMintRatio}
              onChange={(e) => {
                mintRatioClicked("Custom");
                setLendRatioChanged(true);
                setSelectedMintRatio(e.target.value);
                // set the temp value to the calculated LTV which will be used to set the slider
                setTempSelectedMintRatio(Math.round(getLtv() * 10));
              }}
            />
          </div>
          <div className="double-row">
            <div className="lend-ratio-selector">
              <Slider
                className={`lend-ratio-slider ${isPoolUndercollateralized() ? "disabled" : ""}`}
                marks={marks}
                value={tempSelectedMintRatio}
                onChangeCommitted={(e, value) => {
                  setLendRatioChanged(true);
                  mintRatioClicked(tempSelectedMintRatio);
                }}
                onChange={(e, value) => {
                  let snapped = false;
                  const snapRange = 3;
                  // snap to mark values if user is within {snapRange} of a mark
                  marks.forEach((mark) => {
                    if (Math.abs(mark.value - Number(value)) < snapRange) {
                      setTempSelectedMintRatio(mark.value);
                      snapped = true;
                    }
                  });
                  // if not snapped, set the value to the slider value
                  if (!snapped)
                    setTempSelectedMintRatio(value as number);
                }}
              />
            </div>
          </div>
          {renderRecommendedMessage()}
        </div>
      </Fragment>
    );
  }

  const renderMaxButton = () => {
    const max = Number(ethers.utils.formatUnits(lendBalance, selectedLend.decimals));
    const fillMax = () => {
      setInitialDeposit(max);
    }
    return (
      <span 
        className="initial-deposit-max"
        onClick={fillMax}
      >
        Max
      </span>
    )
  }

  // check if the parameters would make the pool undercollateralized 
  const isPoolUndercollateralized = () => {
    const colPrice = tokenPrices[selectedCollateral.address];
    const lendPrice = tokenPrices[selectedLend.address];
    const borrowValue = Number(selectedMintRatio) * lendPrice;
    return (borrowValue > colPrice)
  }

  // render the initial deposit label for create pool vs request pool
  const renderInitialDepositLabel = () => {
    let label = "Initial Deposit Amount";
    if (getCurrentPage() === "create-pool")
      label = "Initial Deposit Amount";
    else if (getCurrentPage() === "request-pool")
      label = "Borrow Amount";
    return (label);
  }

  // render the inital deposit input 
  const renderInitialDepositInput = () => {
    return (
      <div className="info-section-container">
        <ReactTooltip 
          id="private-pool-tip" 
          type="info" 
          className="tool-tip"
          place={screenWidth > mobileBreakpoint ? "top" : "right"}
        >
          <span>
            The initial balance you want to seed your pool with
          </span>
        </ReactTooltip>
        <div className="info-title-container">
          <div
            className="info-title disable-select"
            data-tip
            data-for="private-pool-tip"
          >
            {renderInitialDepositLabel()} <HelpCircle />
          </div>
        </div>
        <div
          className="initial-deposit-wrapper"
        >
          <input
            type="number"
            placeholder={`${selectedLend.symbol} amount`}
            className="initial-deposit-input"
            value={initialDeposit}
            onChange={(e: any) => initialDepositInputChange(e)}
          />
          {renderMaxButton()}
        </div>
      </div>
    );
  }

  // update the pool expiry and the auction dates
  // TODO: when we enable auction dates, we need to update this
  const updateExpiry = (value: any) => {
    const date = new Date(value);
    setSelectedExpiry(value);
    setSelectedAuctionDates([new Date(), date]);
  }

  const renderRecommendedMessage = () => {
    if (hideRecommendation)
      return null;
    if (!lendRatioChanged && selectedMintRatio !== 0) {
      return (
        <Alert severity="info" className="term-recommendation-message">
          A lend ratio of {selectedMintRatio} ({calculatedLtv}% LTV) is recommended based on recent historic volatility of {selectedCollateral.symbol}
        </Alert>
      )
    } else if (!lendRatioChanged && selectedMintRatio === 0) {
      return (
        <Alert severity="info" className="term-recommendation-message">
          Calculating recommended lend ratio...
        </Alert>
      )
    }
    else return null;
  }

  // render the expiry input
  const renderExpiryInput = () => {
    return(
      <div className="info-section-container">
        <div className="info-title-container">
          <ReactTooltip 
            id="expiry-tip" 
            type="info" 
            className="tool-tip"
            place={screenWidth > mobileBreakpoint ? "top" : "right"}
            arrowColor="var(--cta-blue)"
          >
            <span>
              Date by which the loan has to be repaid or rolled over.
              Partial payments are supported
            </span>
          </ReactTooltip>
          <ReactTooltip id="apr-tip" type="info" className="tool-tip">
            <span>
              The rate at which your {selectedLend.symbol} will be lent out
              to your users
            </span>
          </ReactTooltip>
          <div
            className="info-title disable-select"
            data-tip
            data-for="expiry-tip"
          >
            Due Date<HelpCircle />
          </div>
        </div>
        <div className="info-container">
          <Selector
            text={selectedExpiry}
            value={selectedExpiry}
            setValue={updateExpiry}
            type={"date"}
            disabled={false}
          ></Selector>
        </div>
      </div>
    )
  }

  // render a row with two columns
  const renderColumnedRow = () => {
    return (
      <div className="double-row bottom-row">
        {renderInitialDepositInput()}
        {renderExpiryInput()}
      </div>
    )
  }

  // check if the selected tokens have oracles and if the 
  // auto pause toggle can be enabled
  const autoPauseElligible= () => {
    try {
      if ((selectedCollateral.symbol === "USDC" || selectedLend.symbol === "USDC") && chainId === 42161)
        return false;
      else if (hasOracle(selectedLend.address) && hasOracle(selectedCollateral.address))
        return true;
      else 
        return false;
    } catch {
      return false;
    }
  }

  // toggle the auto pause if pool is eligible
  const toggleClicked = () => {
    if (autoPauseElligible())
      setAutoPause(!autoPause);
  }

  // render the auto pause toggle
  const renderToggles = () => {
    return (
      <div className="toggles-container">
        <div className="toggle-wrapper">
          <ReactTooltip
            id="auto-pause-tip"
            type="info"
            className="tool-tip"
            effect={screenWidth > tabletBreakpoint ? "float" : "solid"}
            place="top"
          >
            <span>
              This will automatically pause your pool if it becomes
              undercollateralized. This is only available if both assets have
              oracles.
            </span>
          </ReactTooltip>
          <span className="info-title">
            Auto Pause Pool{" "}
            <HelpCircle data-tip data-for="auto-pause-tip" />
          </span>
          {!autoPauseElligible() && (
            <ReactTooltip
              id="auto-pause-disabled-tip"
              type="info"
              className="tool-tip"
            >
              <span>
                Auto-pause is not available for pools with one or more
                assets without an oracle
              </span>
            </ReactTooltip>
          )}
          <div
            className={`toggle-wrapper-switch ${
              autoPause? "checked" : ""
            }`}
            data-tip
            data-for="auto-pause-disabled-tip"
            onClick={toggleClicked}
          >
            <input
              type="checkbox"
              className="fee-checkbox"
              checked={autoPause}
              disabled={
                !selectedCollateral.hasOracle || !selectedLend.hasOracle
              }
            />
            <div className="toggle-wrapper-slider"></div>
          </div>
        </div>
      </div>
    );
  }

  const renderUndercollateralizedWarning = () => {
    if (isPoolUndercollateralized() && !autoPause)
      return (
        <Alert severity="warning">
          <span className="alert-header">Undercollateralized Warning</span>
          <span>
            This pool will be undercollateralized. This means that borrowers
            will be able to borrow more than the value of the collateral.
            Please make sure you understand the risks involved.
          </span>
        </Alert>
      );
    else
      return null;
  }

  // reset the initial deposit if the lend or collateral token changes
  const handleModalSelection = (token: any) => {
    setInitialDeposit(0);
    if (selectedInput === "0")
      setSelectedCollateral(token);
    else
      setSelectedLend(token);
  }

  const renderContent = () => {
    return (
      <Fragment>
        <TokenModal
          showModal={showTokenModal}
          setShowModal={setShowTokenModal}
          setSelectedCollateral={handleModalSelection}
          setSelectedLend={handleModalSelection}
          selectedInput={selectedInput}
          selectedCollateral={selectedCollateral}
          selectedLend={selectedLend}
        />
        {renderTokenSelectors()}
        {renderLendRatioInput()}
        {renderColumnedRow()}
        {renderToggles()}
        {renderUndercollateralizedWarning()}
      </Fragment>
    )
  }

  return (
    <div className="create-pool-page-wrapper page-1">
      {renderContent()}
    </div>
  )
}

export default Page1;