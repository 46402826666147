import { useContext, useEffect, useState } from "react";
import axios from "axios";
import "./PoolRequestsContainer.css";
import { getCurrentPage, mobileBreakpoint, useDevFunctions } from "../../utils/Utils";
import { useScreenSize } from "../../utils/useScreenSize";
import PoolRequestRow from "./PoolRequestRow";
import LineTabs from "../LineTabs/LineTabs";
import { Alert } from "@material-ui/lab";
import { WalletDataContext } from "../../context/WalletDataContext";
import { WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { ExternalLink } from "react-feather";
import { Link } from "react-router-dom";

const PoolRequestsContainer = (props: {
  latestPoolRequest: any
  poolRequests: any[]
  setPoolRequests: (poolRequests: any) => void
}) => {

  const { screenWidth } = useScreenSize();
  const { account, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const [selectedPoolRequest, setSelectedPoolRequest] = useState<any>();
  const [loadedRequests, setLoadedRequests] = useState<boolean>(false);

  useEffect(() => {
    if (!loadedRequests)
      getPoolRequests();
  // eslint-disable-next-line
  }, []);

  const getPoolRequests = async () => {
    try {
      const url = useDevFunctions 
        ? "http://127.0.0.1:5001/vendor-finace/us-central1/getPoolRequests"
        : "https://us-central1-vendor-finace.cloudfunctions.net/getPoolRequests"; 
      const res = await axios.get(url);
      // set props
      props.setPoolRequests(res.data);
      setLoadedRequests(true);
    } catch (e) {
      console.log(e);
    }
  }

  // TODO: implement this
  const rowHeaderClicked = () => {

  }

  // TODO: implement this
  const manageChevron = (header: string) => {

  }

  // render the pool request table headers
  const renderHeader = () => {
    return (
      <div className="pool-rows-header-container">
        <div
          className="pool-column-header pair-header"
          onClick={rowHeaderClicked}
        >
          <span>Pair</span>
          {manageChevron("pair")}
        </div>
        <div
          className={`pool-column-header ltv-header`}
          onClick={rowHeaderClicked}
        >
          <span>Lend Ratio</span>
          {manageChevron("lendRatio")}
        </div>
        <div
          className={`pool-column-header term-rate-header`}
          onClick={rowHeaderClicked}
        >
          <span>Term Rate</span>
          {manageChevron("term rate")}
        </div>
        <div
          className="pool-column-header apr-header"
          onClick={rowHeaderClicked}
        >
          <span>APR</span>
          {manageChevron("apr")}
        </div>
        <div
          className={`pool-column-header expiry-header`}
          onClick={rowHeaderClicked}
        >
          <span>{screenWidth > mobileBreakpoint ? "Repayment Due Date" : "Due Date"}</span>
          {manageChevron("repayment due date")}
        </div>
        <div
          className={`pool-column-header available-header`}
          onClick={rowHeaderClicked}
        >
          Requested
        </div>
      </div>
    )
  }

  // filter pool requests by user if on request-pool page
  const filterUserRequests = () => {
    let filteredRequests: any[] = [];
    if (getCurrentPage() === "request-pool")
      filteredRequests = props.poolRequests.filter((poolRequest: any) => 
        poolRequest.owner === account
      ).reverse();
    else 
      filteredRequests = props.poolRequests.reverse();
    // filter by chain id
    return filteredRequests.filter((poolRequest: any) => Number(poolRequest.chainId) === Number(chainId));
  }

  // either render loading, no pools message, or pool requests
  const renderTableAndMessages = () => {
    const filteredRequests:any[] = filterUserRequests().sort((a: any, b: any) => 
      Number(b.timestamp) - Number(a.timestamp)
    );
    if (!loadedRequests) {
      return (
        <Alert severity="info" className="no-pools-message fade-in">
          Loading Requested Pools...
        </Alert>
      )
    } else if (filteredRequests.length === 0) {
      return (
        <Alert severity="info" className="no-pools-message fade-in">
          No Pool Requests Available
        </Alert>
      ) 
    } else if (props.poolRequests && props.poolRequests.length > 0) {
      return (
        <div className="inner-pool-requests-container">
          {filteredRequests.map((poolRequest: any) => 
            <PoolRequestRow 
              poolRequest={poolRequest} 
              selectedPoolRequest={selectedPoolRequest}
              setSelectedPoolRequest={setSelectedPoolRequest}
              poolRequests={props.poolRequests}
              setPoolRequests={props.setPoolRequests}
            />
          )}
        </div>
      )
    } 
  }
  
  // render all pool requests or render loading
  const renderPoolRequests = () => {
    return (
      <div className="pool-requests-container">
        <div className="pool-requests-container-header">
          <LineTabs
            tabs={[getCurrentPage() === "request-pool" ? "My Requested Pools" : "Requested Pools"]}
            activeTab={0}
            setActiveTab={() => {}}
          />
          {getCurrentPage() === "create-pool" &&
            <Link to="/request-pool">
              <span className="pool-request-link">Submit Pool Request<ExternalLink/></span>
            </Link>
          }
        </div>
        {renderHeader()}
        <div className="inner-pool-requests-container">
          {renderTableAndMessages()}
        </div>
      </div>
    )
  }

  return (
    renderPoolRequests()
  )
}


export default PoolRequestsContainer;