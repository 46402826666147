import { Fragment, useContext, useEffect, useState } from "react";
import PoolSimulation from "../PoolSimulation/PoolSimulation";
import RateSimulation from "../RateSimulation/RateSimulation";
import { CreatePoolContext } from "../../context/CreatePoolContext";
import { CREATE_POOL_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import "./GraphTabs.css";
import LineTabs from "../LineTabs/LineTabs";
import { Info } from "react-feather";
import { fetchLocalTokenData } from "../../utils/Utils";
import { WalletDataContext } from "../../context/WalletDataContext";

const GraphTabs = () => {

  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs = ["Collateral Price & Borrow Value", "Term Rate Behavior"];

  const { 
    rateType, 
    selectedAPR,
    selectedAuctionDates,
    selectedExpiry,
    selectedCollateral,
    selectedLend,
    selectedMintRatio,
    currentPage
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const [colData, setColData] = useState<any>({});
  const [lendData, setLendData] = useState<any>({});

  useEffect(() => {
    if (currentPage === 0) setActiveTab(0);
    else if (currentPage === 1) setActiveTab(1);
  }, [currentPage]);

  useEffect(() => {
    const colData = fetchLocalTokenData(selectedCollateral.address, chainId);
    const lendData = fetchLocalTokenData(selectedLend.address, chainId);
    setColData(colData);
    setLendData(lendData);
  }, [selectedCollateral, selectedLend, chainId]);

  const renderTabs = () => {
    return (
      <LineTabs
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    )
  }

  const renderPriceEstimationWarning = () => {
    if ((colData && lendData) && (colData.isLpToken || lendData.isLpToken))
      return (
        <Fragment>
          <span 
            className="price-estimate-warning"
            data-tip
            data-for="price-estimate-tip"
          >
            <Info/>
            LP token prices are estimated
          </span>
        </Fragment>
      )
    else 
      return null;
  }

  const renderActiveTab = () => {
    return (
      <div className="active-graph">
        {activeTab === 0 && 
          <Fragment>
            {renderPriceEstimationWarning()}
            <PoolSimulation
              colToken={selectedCollateral.address}
              lendToken={selectedLend.address}
              mintRatio={selectedMintRatio}
            />
          </Fragment>
        }
        {activeTab === 1 && 
          <RateSimulation
            startDate={selectedAuctionDates[0]}
            endDate={selectedAuctionDates[1]}
            expiry={selectedExpiry}
            startRate={selectedAPR[0]}
            endRate={selectedAPR[1]}
            rateType={rateType}
            key={selectedExpiry}
          />
        }
      </div>
    )
  }

  return (
    <div className="graph-tabs-wrapper">
      {renderTabs()}
      {renderActiveTab()}
    </div>
  );
}

export default GraphTabs;