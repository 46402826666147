import { Alert } from "@material-ui/lab";
import { BigNumber, ethers } from "ethers";
import moment from "moment";
import { Fragment, useContext, useEffect } from "react";
import { HelpCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import TransactionReview from "../../../components/TransactionReview/TransactionReview";
import { AppDataContext } from "../../../context/AppDataContext";
import { CreatePoolContext } from "../../../context/CreatePoolContext";
import { APP_DATA_CONTEXT, CREATE_POOL_CONTEXT } from "../../../utils/Interfaces";
import { getCurrentPage, renderLazyLoad } from "../../../utils/Utils";
import "./Page.css";

const Page3 = () => {

  const { 
    selectedAPR,
    selectedCollateral,
    selectedLend,
    selectedMintRatio,
    selectedExpiry,
    initialDeposit,
    setStepButtonState,
    lendAllowance,
    rateType,
    selectedAuctionDates,
    selectedStrategy,
    privateBorrowers,
    protocolFee
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const { calculateLTV } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    manageActionButton();
  // eslint-disable-next-line
  }, [lendAllowance, initialDeposit, selectedLend]);

  // manage input errors and display error message
  const manageActionButton = () => {
    if (getCurrentPage() === "request-pool") {
      setStepButtonState("");
      return;
    }
    const rawInitialDeposit = Number(initialDeposit).toString().length === 0 
      ? BigNumber.from("0")
      : ethers.utils.parseUnits(Number(initialDeposit).toFixed(selectedLend.decimals), selectedLend.decimals);
    if (Number(selectedAPR) <= 0) {
      setStepButtonState("Invalid Term Rate");
    } else if (lendAllowance.lt(rawInitialDeposit)) {
      setStepButtonState(`Approve ${selectedLend.symbol}`);
    } else {
      setStepButtonState("");
    }
  }

  const formatDate = (timestamp: Date) => {
    return moment(timestamp).format("MMM D, Y hh:mm a");
  }

  const renderInitialDeposit = () => {
    const value = !initialDeposit || initialDeposit === 0 
      ? 0 
      : Number(initialDeposit).toFixed(3);
    return (
      <div className="info-item">
        <span className="info-left">Initial Deposit Amount:</span>
        <span>{value} {selectedLend.symbol}</span>
      </div>
    )
  }

  const renderRateInfo = () => {
    if (rateType === "Fixed")
      return (
        <div className="info-item">
          <span className="info-left">Term Rate:</span>
          <span>{selectedAPR[0]}%</span>
        </div>
      )
    else if (rateType === "Auction")
      return (
        <Fragment>
          <div className="info-item">
            <span className="info-left">Start Rate:</span>
            <span>{selectedAPR[0]}%</span>
          </div>
          <div className="info-item">
            <span className="info-left">End Rate:</span>
            <span>{selectedAPR[1]}%</span>
          </div>
          <div className="info-item">
            <span className="info-left">Auction Start Date:</span>
            <span>{formatDate(selectedAuctionDates[0])}</span>
          </div>
          <div className="info-item">
            <span className="info-left">Auction End Date:</span>
            <span>{formatDate(selectedAuctionDates[1])}</span>
          </div>
        </Fragment>
      );
    else if (rateType === "Decay")
      return (
        <Fragment>
          <div className="info-item">
            <span className="info-left">APR:</span>
            <span>{selectedAPR[0]}%</span>
          </div>
        </Fragment>
      );
  }

  const renderLtvAfterFees = () => {
    // pass in what the fee rate would be
    const currentFeeRate = rateType === "Decay" 
      // decaying rate is calculated based on the time left in the pool
      ? selectedAPR[0] * (selectedExpiry - new Date().getTime()) / (31_536_000 * 1000)
      // fixed and rates are just the selected rate
      : selectedAPR[0];

    // data for the current pool parameters
    const poolData:any = {
      lendToken: selectedLend.address,
      colToken: selectedCollateral.address,
      mintRatio: ethers.utils.parseUnits(selectedMintRatio.toString(), 18),
      currentFeeRate: currentFeeRate * 10000,
      protocolFee: protocolFee
    }
    // calculate ltv based on pool parameters
    const ltv = calculateLTV(poolData);
    return (
      <Fragment>
        <ReactTooltip id="lend-per-col-tip" type="info" className="tool-tip">
          The initial pool LTV for borrowers after pool fees and vendor fees are subtracted
        </ReactTooltip>
        <div className="info-item">
          <span className="info-left">
            LTV After Fees: 
            <HelpCircle data-tip data-for="lend-per-col-tip"/>
          </span>
          <span>
            {renderLazyLoad(!isNaN(ltv), `${ltv.toFixed(2)}%`)}
          </span>
        </div>
      </Fragment>
    )
  }

  // render what the initial max borrow amount would be based on the current pool parameters
  const renderMaxBorrow = () => {
    // pass in what the fee rate would be
    const currentFeeRate = rateType === "Decay" 
      // decaying rate is calculated based on the time left in the pool
      ? (selectedAPR[0] * (selectedExpiry - new Date().getTime()) / (31_536_000 * 1000)) / 100
      // fixed and rates are just the selected rate
      : selectedAPR[0] / 100;
    // calculate the max borrow amount based on the current pool parameters
    const maxBorrowAmount = initialDeposit * (1 - (protocolFee + currentFeeRate));

    return (
      <Fragment>
        <ReactTooltip id="lend-per-col-tip" type="info" className="tool-tip">
          The initial amount of funds that are available to be borrowed from the
          pool after pool fees and vendor fees are subtracted
        </ReactTooltip>
        <div className="info-item">
          <span className="info-left">
            Initial Max Borrow Amount:
            <HelpCircle data-tip data-for="lend-per-col-tip" />
          </span>
          <span>{`${maxBorrowAmount.toFixed(2)}`}</span>
        </div>
      </Fragment>
    );
  }

  // render the transaction info
  const getTransactionInfo = () => {
    return (
      <Fragment>
        <div className="info-item">
          <span className="info-left">Collateral Token:</span>
          <span>{selectedCollateral.symbol}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Lend Token:</span>
          <span>{selectedLend.symbol}</span>
        </div>
        <div className="info-item">
          <span className="info-left">Lend Ratio:</span>
          <span>{selectedMintRatio}</span>
        </div>
        {renderLtvAfterFees()}
        {initialDeposit > 0 && renderMaxBorrow()}
        <div className="info-item">
          <span className="info-left">Rate Type:</span>
          <span>{rateType}</span>
        </div>
        {renderRateInfo()}
        <div className="info-item">
          <span className="info-left">Expiry:</span>
          <span>{formatDate(selectedExpiry)}</span>
        </div>
        {renderInitialDeposit()}
        {selectedStrategy.name !== "No Strategy" &&
          <div className="info-item">
            <span className="info-left">Strategy:</span>
            <span>{selectedStrategy.name}</span>
          </div>
        }
        <div className="info-item">
          <span className="info-left">Private Borrowing Enabled:</span>
          <span>{privateBorrowers.length > 0 ? "Yes" : "No"}</span>
        </div>
        <Alert
          severity="warning"
        >
          <span className="alert-header">Fee Notice</span>
          <span>Vendor Finance takes 0.3% on all borrowed funds.</span>
        </Alert>
      </Fragment>
    );
  }

  const renderContent = () => {
    return (
      <TransactionReview
        transactionInfo={getTransactionInfo()}
        actionButton={null}
        modal={false}
        showReview={false}
        setShowReview={() => false}
      />
    )
  }

  return (
    <div className="create-pool-page-wrapper page-3">
      {renderContent()}
    </div>
  )
}

export default Page3;