import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Modal from "../Modal/Modal";
// import { useSnackbar } from "notistack";
import "./AlertBox.css";
import { Link } from "react-router-dom";
// import ActionButton from "../ActionButton/ActionButton";
import { useSnackbar } from "notistack";
import { Copy } from "react-feather";
import ActionButton from "../ActionButton/ActionButton";

const AlertBox = (props: {
  shrink: boolean;
  message: string;
  setShowNewPool: (val: boolean) => void;
  showNewPool: boolean;
  title: string;
  show: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const getMessage = () => {
    if (props.shrink) {
      let start = props.message.substring(0, 39);
      let end = props.message.substring(
        props.message.length - 3,
        props.message.length
      );
      return `${start}...${end}`;
    }
  };

  const getPoolAddress = () => {
    // extract pool
    return props.message.split("/")[props.message.split("/").length - 1];
  };

  const copyText = () => {
    navigator.clipboard.writeText(props.message);
    enqueueSnackbar("URL copied to clipboard", {
      persist: false,
    });
  };

  return (
    <Modal setShowModal={props.setShowNewPool} showModal={props.showNewPool}>
      <div className={"alert-box-container " + (props.show ? "" : "hidden")}>
        <div className="alert-box-header">{props.title}</div>
        <div className="aler-box-message">
          Your pool has been successfully created.
        </div>
        <div className="alert-box-body">
          <div className="alert-box-button">{getMessage()}</div>
          <Copy
            onClick={copyText}
          />
        </div>
        <div className="alert-box-link-wrapper">
          <Link to={`/my-pools/lent/${getPoolAddress()}`}>
            <ActionButton title={"Go to pool"} disabled={false} />
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default AlertBox;
