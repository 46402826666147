import { Fragment, useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../context/AppDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import "./SetRollover.css"
import LendingPoolABI from "../../abi/LendingPool.js";
import PotentialRollover from "../PotentialRollover/PotentialRollover";
import { Contract, ethers } from "ethers";
import { ChevronDown } from "react-feather";
import { WalletDataContext } from "../../context/WalletDataContext";
import ActionButton from "../ActionButton/ActionButton";
import Dropdown from "../Dropdown/Dropdown";
import { useSnackbar } from "notistack";
import { getGasLimit, getTransactionUrl, handleMulticallAddress, readableABIs } from "../../utils/Utils";
import { Contract as MulticallContract } from "ethers-multicall";
import { Skeleton } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import { PoolDataContext } from "../../context/PoolDataContext";

const SetRollover = () => {

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [whitelistedRollovers, setWhitelistedRollovers] = useState<POOL_DATA[]>([]);
  const [actionButtonText, setActionButtonText] = useState<string>("Enable Rollover Pool");
  const [potentialRollovers, setPotentialRollovers] = useState<POOL_DATA[]>([]);
  const { provider, chainId, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { selectedPool, lentPools, selectedRollover, setSelectedRollover } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setPotentialRollovers(getPotentialRollovers());
    getWhitelistedRollovers();
  // eslint-disable-next-line
  }, [selectedPool]);

  useEffect(() => {
    manageActionButton();
  });

  // check if a pool is in a rollover whitelist
  const checkWhitelist = (searchPool: POOL_DATA) => {
    const search = whitelistedRollovers?.find((pool: POOL_DATA) => 
      searchPool.id === pool.id
    )
    return (search !== undefined);
  }

  const manageActionButton = () => {
    if (potentialRollovers.length > 0) {
      if (selectedRollover && checkWhitelist(selectedRollover)) {
        setActionButtonText("Disable Rollover");
      } else if (selectedRollover && !checkWhitelist(selectedRollover)) {
        setActionButtonText("Enable Rollover");
      }
    } else {
      setActionButtonText("Create Rollover");
    }
  }

  const getWhitelistedRollovers = async () => {
    if (!provider) return;
    const potentialRollovers = getPotentialRollovers();
    try {
      // setup the correct multicall address
      handleMulticallAddress(chainId, multicallProvider);
      // get all calls needed for multicall
      const calls = potentialRollovers.map((pool: POOL_DATA) => {
        const poolContract = new MulticallContract(
          ethers.utils.getAddress(selectedPool.id),
          readableABIs.lendingPool
        );
        return (poolContract.allowedRollovers(ethers.utils.getAddress(pool.id)));
      });
      // execute contract calls 
      const response = await multicallProvider.all(calls);
      let found = false;
      let whitelist:POOL_DATA[] = [];
      // check if any of the results return true and set it as the selected pool
      response.forEach((isSelected: boolean, index: number) => {
        if (isSelected) {
          setSelectedRollover(potentialRollovers[index]);
          whitelist.push(potentialRollovers[index]);
          found = true;
        }
      });
      setWhitelistedRollovers(whitelist);
      // if no potential rollovers found, set to first pool found
      if (!found && potentialRollovers.length > 0) 
        setSelectedRollover(potentialRollovers[0]);
    } catch (e) {
      console.log(e);
    }
  }

  const getPotentialRollovers = () => {
    const potentialRollovers = lentPools.filter((pool: POOL_DATA) => {
      // don't render inelligible pools
      if (
        // same lend and collateral token
        (pool.lendToken !== selectedPool.lendToken || pool.colToken !== selectedPool.colToken) ||
        // same pool id
        pool.id === selectedPool.id ||
        // expires before origin pool
        Number(pool.expiry) < Number(selectedPool.expiry)
      ) return false;
      else return true;
    });

    return potentialRollovers;
  }

  const renderPotentialRollover = () => {
    const potentialRollovers = getPotentialRollovers();
    return (
      <div className="potential-rollovers-wrapper">
        {potentialRollovers.map((pool: POOL_DATA) => {
          return (
            <div
              onClick={() => setShowDropdown(false)}
              key={pool.id}
            >
              <PotentialRollover 
                pool={pool}
                setSelectedRollover={setSelectedRollover}
              />
            </div>
          )
        })}
      </div>
    );
  }

  const setRollover = async () => {
    if (!provider || !selectedRollover) return;
    setPending(true);
    try {
      const poolContract = new Contract(
        selectedPool.id,
        LendingPoolABI,
        provider.getSigner()
      );
      // enable the new rollover pool or disable the currently selected pool
      const shouldEnable = checkWhitelist(selectedRollover) ? false : true;
      const tx = await poolContract.setRolloverPool(
        selectedRollover.id,
        shouldEnable,
        {
          ...getGasLimit(chainId, METHOD_TYPE.SET_ROLLOVER)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Set rollover transaction successful ** ${getTransactionUrl(tx.transactionHash, chainId)}`, {
        persist: false,
      });
      getWhitelistedRollovers()
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Set rollover transaction failed`, {
        persist: false,
      });
    }
    setPending(false);
  }

  const renderPotentialRolloverPreview = () => {
    const potentialRollovers = getPotentialRollovers();
    if (potentialRollovers.length > 0 && !selectedRollover) {
      return (
        <div className="set-rollover-input-preview">
          <Skeleton className="loading-skeleton"/>
        </div>
      )
    } else if (potentialRollovers.length > 0 && selectedRollover) {
      return (
        <Fragment>
          <PotentialRollover 
            setSelectedRollover={setSelectedRollover} 
            pool={selectedRollover}
          /> 
          <ChevronDown/>
        </Fragment>
      )
    } else {
      return (null)
    }

  }

  const renderRolloverHeader = () => {
    if (potentialRollovers.length === 0) 
      return (null);
    else 
      return (
        <div className="potential-rollover-header-wrapper">
          <span>Pair</span>
          <span>Due Date</span>
          <span>LTV</span>
          <span>Term Rate</span>
        </div>
      );
  }
  
  // 
  const createRollover = () => {
    const colToken = selectedPool.colToken;
    const lendToken = selectedPool.lendToken;
    const lendRatio = selectedPool.mintRatio;
    const expiry = selectedPool.expiry;
    const startRate = selectedPool.startRate;
    const strategy = selectedPool.strategy;
    const feeType = selectedPool.feeType;
    const originPool = selectedPool.id;
    const link = `/create-pool/?colToken=${colToken}&lendToken=${lendToken}&lendRatio=${lendRatio}&expiry=${expiry}&startRate=${startRate}&feeType=${feeType}&strategy=${strategy}&originPool=${originPool}`;
    navigate(link);
  }

  const actionButtonActionLogic = () => {
    if (potentialRollovers.length > 0) {
      setRollover();
    } else {
      createRollover();
    }
  }

  return (
    <div className="set-rollover-wrapper small-widget">
      <span className="widget-description">
        Set which pools borrowers can rollover into from this pool
      </span>
      {renderRolloverHeader()}
      <div 
        className={`set-rollover-input-wrapper ${potentialRollovers.length === 0 && "hidden"}`}
        onClick={() => {if (!showDropdown && potentialRollovers.length > 0) setShowDropdown(true)}}
      >
        {renderPotentialRolloverPreview()}
        {showDropdown &&
          <Dropdown
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
          >
            {renderPotentialRollover()}
          </Dropdown>
        }
      </div>
      <div className="settings-tab-button-wrapper set-rollover">
        <ActionButton 
          disabled={pending} 
          title={actionButtonText} 
          action={() => actionButtonActionLogic()}
          secondary={false}
        />
      </div>
      
    </div>
  );
}

export default SetRollover;