import { ExternalLink } from "react-feather";
import "./Notification.css";
import { Alert } from "@material-ui/lab";

const Notification = (props: {
  message: string,
  key: any
}) => {

  const renderMessage = () => {
    let message = props.message;
    // extract link from message if it exists
    if (props.message.includes("**")) {
      message = message.substring(0, message.indexOf("**"));
    }
    return message;
  }

  const renderLink = () => {
    // extract link from message if it exists
    if (props.message.includes("**")) {
      const link = props.message.substring(props.message.indexOf("**") + 2);
      return (
        <ExternalLink
          onClick={() => window.open(link, '_blank')}
          className="notification-link"
        />
      )
    } else {
      return (<div></div>);
    }
  }

  const getNotificationSeverity = () => {
    if (props.message.toLowerCase().includes("failed")) {
      return "error";
    } else if (props.message.toLowerCase().includes("success")) {
      return "success";
    } else {
      return "info";
    }
  }

  const getTransactionHeader = () => {
    if (props.message.toLowerCase().includes("failed")) {
      return "Action Failed";
    } else if (props.message.toLowerCase().includes("success")) {
      return "Action Successful";
    } else {
      return "Notice";
    }
  }

  return (
    <div className="notification-wrapper">
      <Alert severity={getNotificationSeverity()}>
          <span className="alert-header">
            {getTransactionHeader()}
            {renderLink()}
          </span>
          <span>
            {renderMessage()}
          </span>
      </Alert>
    </div>
  )

  // return (
  //   <div className="notification-wrapper">
  //     <section className="notification-top">
  //       <span>{renderMessage()}</span>
  //       <X
  //         className="notification-close"
  //         onClick={() => closeSnackbar(props.key)}
  //       />
  //       {renderLink()}
  //     </section>
  //     <div className="notification-slider"></div>
  //   </div>
  // );
};

export default Notification;
