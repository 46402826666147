import { useEffect, useState } from "react";
import { ChevronRight } from "react-feather";
import { fetchIcon } from "../../../utils/Utils";

const SettingsTab = (props: {
  tab: {
    icon: string,
    title: string,
    component: JSX.Element
  },
  forceOpen?: boolean
}) => {

  // is this tab open
  const [isOpen, setIsOpen] = useState<boolean>(props.forceOpen ? props.forceOpen : false);

  useEffect(() => {
    setIsOpen(props.forceOpen ? props.forceOpen : false);
  }, [props.forceOpen]);

  const tabClicked = () => {
    setIsOpen(!isOpen);
  }

  const renderTabComponent = () => {
    return (
      <div className={`settings-manager-active-tab ${isOpen ? "open" : ""}`}>
        {props.tab.component}
      </div>
    )
  }

  return (
    <div className={`settings-manager-tab ${isOpen ? "open" : ""}`}>
      <div className="settings-manager-header" onClick={tabClicked}>
        <img src={fetchIcon(props.tab.icon)} alt="filter" className="settings-tab-icon"/>
        <span>{props.tab.title}</span>
        <ChevronRight className={`settings-tab-svg ${isOpen ? "open" : ""}`}/>
      </div>
      {renderTabComponent()}
    </div>
  );
}

export default SettingsTab;