import { useState } from "react";
import "./Slideshow.css";

const ProtocolIntro = (props: {
  content: {
    title: string,
    body: string,
    img: any 
  }[]
}) => {

  const [introPage, setIntroPage] = useState<number>(0);

  // render the intro text 
  const renderContent = () => {
    // get header and body text for current intro page
    const header = props.content[introPage].title;
    const body = props.content[introPage].body;
    const img = props.content[introPage].img;
    return (
      <div className="slideshow-content-wrapper">
        <div className="content-media-wrapper">
          <img src={img} alt="slideshow media"/>
        </div>
        <div className="content-text-wrapper">
          <h1>{header}</h1>
          <p>{body}</p>
        </div>
      </div>
    )
  }

  // render progress dots
  const renderDots = () => {

    // listen for clicks to navigate media
    const dotClicked = (index: number) => {
      setIntroPage(index);
    }

    return (
      <div className="intro-dots-wrapper">
        {props.content.map((_: any, index: number) => 
          <div 
            className={`intro-dot ${index === introPage ? 'active' : ''}`}
            onClick={() => dotClicked(index)}
          />
        )}
      </div>
    )
  }

  const renderButtons = () => {

    // listen for clicks to navigate media
    const buttonClicked = (next: boolean) => {
      if (next && introPage < props.content.length - 1)
        setIntroPage(introPage + 1);
      else if (!next && introPage > 0)
        setIntroPage(introPage - 1);
    }

    return (
      <div className="intro-buttons">
        <button className="intro-back-button" onClick={() => buttonClicked(false)}>Back</button>
        <button className="intro-next-button" onClick={() => buttonClicked(true)}>Next</button>
      </div>
    )
  }

  return (
    <div className="slideshow-wrapper fade-in">
      {renderContent()}
      {renderDots()}
      {renderButtons()}
    </div>
  )
}

export default ProtocolIntro;