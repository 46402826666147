import { Slider } from "@material-ui/core";
import { Fragment, useContext, useEffect } from "react";
import { HelpCircle } from "react-feather";
import Selector from "../../../components/Selector/Selector";
import { CreatePoolContext } from "../../../context/CreatePoolContext";
import { CREATE_POOL_CONTEXT, CREATE_POOL_PAGES_CONTEXT } from "../../../utils/Interfaces";
import PrivateBorrowers from "../PrivateBorrowers/PrivateBorrowers";
import StrategySelector from "../../../components/StrategySelector/StrategySelector";
import "./Page.css";
import ReactTooltip from "react-tooltip";
import { getCurrentPage } from "../../../utils/Utils";
import { Alert } from "@material-ui/lab";
import { CreatePoolPagesContext } from "../../../context/CreatePoolPagesContext";

const Page2 = () => {

  const { 
    rateType, 
    setRateType,
    selectedAPR,
    setSelectedAPR,
    setStepButtonState,
    selectedAuctionDates,
    setSelectedAuctionDates,
    selectedExpiry,
    selectedCollateral
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const { termRateChanged, setTermRateChanged, hideRecommendation } = useContext(CreatePoolPagesContext) as CREATE_POOL_PAGES_CONTEXT;

  useEffect(() => {
    manageActionButton();
  // eslint-disable-next-line
  }, [selectedAPR, selectedAuctionDates, rateType]);

  useEffect(() => {
    // set defaults 
    if (rateType === "Fixed")
      setSelectedAPR([selectedAPR[0], selectedAPR[0]]);
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // whenever the rate type is changed to Fixed, make the start and end rates the same
    if (rateType === "Fixed")
      setSelectedAPR([selectedAPR[0], selectedAPR[0]]);
  // eslint-disable-next-line
  }, [rateType]);

  // manage input errors and display error message
  const manageActionButton = () => {
    if ((selectedAPR[0] <= 0 || selectedAPR[1] <= 0) && rateType !== "Decay") {
      setStepButtonState("Invalid Term Rate");
    } else if (selectedAuctionDates[0] > selectedAuctionDates[1]) {
      setStepButtonState("Start Date Must Be Before End Date");
    } else if (selectedAPR[0] < selectedAPR[1]) {
      setStepButtonState("Start Rate Must Be Larger Than End Rate");
    } else {
      setStepButtonState("");
    }
  }

  const renderRateInfo = (option: string) => {
    // these have to be reversed for some reason
    if (option === "Decay")
      return("All borrowers will pay the fixed term rate selected below");
    else if (option === "Fixed")
      return("Each borrower will borrow at a term rate that is annualized to the APR selected below (recommended)");
    else 
      return("");
  }

  const renderRateSelection = () => {
    const options = ["Decay", "Fixed"];

    // listen for clicks on rate options
    const optionClicked = (option: string) => {
      setTermRateChanged(true);
      setRateType(option as "Fixed" | "Decay" | "Auction");
    }

    const fixedInputChanged = (val: any) => {
      setSelectedAPR([Number(val), Number(val)]);
    }

    const decayingInputChanged = (val: any) => {
      console.log(val)
      setSelectedAPR([Number(val), 0]);
    }

    const renderFixedRate = () => {
      const rateChanged = (e: any, val: any) => {
        setSelectedAPR([val, val]);
      }
      return (
        <div className="term-rate-selection-container">
          <div className="term-rate-label-container">
            <span>Term Rate</span>
            <div className="rate-input-wrapper">
              <input 
                value={selectedAPR[0]}
                onInput={(e: any) => fixedInputChanged(e.target.value)}
                type="number"
              />
              <span>%</span>
            </div>
          </div>
          <Slider
            min={0}
            max={100}
            onChange={rateChanged}
            value={selectedAPR[0]}
            step={0.5}
            track={"normal"} 
          />
        </div>
      )
    }

    const renderDecayingRate = () => {
      const rateChanged = (e: any, val: any) => {
        setSelectedAPR([val, 0]);
        setTermRateChanged(true);
      }
      return (
        <div className="decaying-rate-input-wrapper">
          <div className="term-rate-selection-container">
            <div className="term-rate-label-container">
              <span>APR</span>
              <div className="rate-input-wrapper">
                <input 
                  value={selectedAPR[0]}
                  onInput={(e: any) => decayingInputChanged(e.target.value)}
                  type="number"
                />
                <span>%</span>
              </div>
            </div>
            <Slider
              min={0}
              max={100}
              onChange={(e:any, val: any) => rateChanged(e, val)}
              value={selectedAPR[0]}
              step={0.5}
              track={"normal"} 
            />
          </div>
        </div>
      )
    }

    // TODO: include strategy/prevaling rate 
    const renderRecommendedMessage = () => {
      if (hideRecommendation)
        return null;
      if (!termRateChanged)
        return (
          <Alert severity="info" className="term-recommendation-message">
            A minimum APR of {selectedAPR[0]}% is recommended based on recent historical volatility of {selectedCollateral.symbol}
          </Alert>
        )
      else return null;
    }

    const renderAuctionRate = () => {

      // logic for disabling certain dates on datetime picker
      const shouldDisableDate = (value: any, start: boolean) => {
        if (start) {
          // the start date cannot be start before the current time
          return value < new Date();
        } else {
          // the end date cannot end after expiry or before start date
          return value > selectedExpiry || value < selectedAuctionDates[0];
        }
      }

      // listen for changes for the apr start/end rates
      const rateChanged = (_: any, val: any, start: boolean) => {
        setTermRateChanged(true);
        if (start)
          setSelectedAPR([val, selectedAPR[1]]);
        else 
          setSelectedAPR([selectedAPR[0], val]);
      }

      // listen for changes for the auction start/end dates
      const dateChanged = (val: Date, start: boolean) => {
        if (start)
          setSelectedAuctionDates([val, selectedAuctionDates[1]]);
        else 
          setSelectedAuctionDates([selectedAuctionDates[0], val]);
      }

      return (
        <div className="auction-rate-input-wrapper">
          <div className="term-rate-selection-container">
            <div className="term-rate-label-container">
              <span>Start Rate <HelpCircle/></span>
              <div>
                <input 
                  value={selectedAPR[0]}
                  onInput={(e: any) => setSelectedAPR(e.target.value)}
                />
                %
              </div>
            </div>
            <Slider
              min={0}
              max={100}
              onChange={(e:any, val: any) => rateChanged(e, val, true)}
              value={selectedAPR[0]}
              step={0.5}
              track={"normal"} 
            />
            <div className="term-rate-label-container">
              <span>Start Date <HelpCircle/></span>
            </div>
            <Selector
              type="date"
              text={selectedAuctionDates[0]}
              value={selectedAuctionDates[0]}
              setValue={(val) => dateChanged(val, true)}
              disabled={false}
              shouldDisableDate={(val: any) => shouldDisableDate(val, true)}
            />
            <Slider
              onChange={(_:any, val: any) => {
                setSelectedAuctionDates([new Date(val),selectedAuctionDates[1]])
              }}
              min={new Date().getTime()}
              max={selectedExpiry}
              value={selectedAuctionDates[0].getTime()}
              step={0.5}
              track={"normal"} 
            />
          </div>
          <div className="term-rate-selection-container">
            <div className="term-rate-label-container">
              <span>End Rate <HelpCircle/></span>
              <div>
                <input 
                  value={selectedAPR[1]}
                  onInput={(e: any) => setSelectedAPR(e.target.value)}
                />
                %
              </div>
            </div>
            <Slider
              min={0}
              max={100}
              onChange={(e:any, val: any) => rateChanged(e, val, false)}
              value={selectedAPR[1]}
              step={0.5}
              track={"normal"} 
            />
            <div className="term-rate-label-container">
              <span>End Date <HelpCircle/></span>
            </div>
            <Selector
              type="date"
              text={selectedAuctionDates[1]}
              value={selectedAuctionDates[1]}
              setValue={(val) => dateChanged(val, false)}
              disabled={false}
              shouldDisableDate={(val: any) => shouldDisableDate(val, false)}
            />
            <Slider
              onChange={(_:any, val: any) => {
                setSelectedAuctionDates([selectedAuctionDates[0], new Date(val)])
              }}
              // min={selectedAuctionDates[0].getTime()}
              min={new Date().getTime()}
              max={selectedExpiry.getTime()}
              value={selectedAuctionDates[1].getTime()}
              step={0.5}
              track={"normal"} 
            />
          </div>
        </div>
      )
    }

    const renderInputs = () => {
      if (rateType === "Fixed") {
        return (renderFixedRate());
      } else if (rateType === "Decay") {
        return (renderDecayingRate());
      } else if (rateType === "Auction") {
        return (renderAuctionRate());
      }
    }

    const renderOptionName = (type: string) => {
      if (type === "Fixed") {
        return "Fixed Rate";
      } else if (type === "Decay") {
        return "Fixed APR";
      }
    }

    return (
      <Fragment>
        <span className="info-title">Term Rate Type</span>
        <div className="rate-selection-container">
          {options.map((option: string) => 
            <button
              className={`rate-option ${rateType === option ? 'active' : ''}`}
              onClick={() => optionClicked(option)}
            >
              {renderOptionName(option)}
              <ReactTooltip 
                id="term-rate-type-tip" 
                type="info" 
                className="tool-tip"
                arrowColor="var(--cta-blue)"
              >
                <span>
                  {renderRateInfo(option)}
                </span>
              </ReactTooltip>
              <HelpCircle data-tip data-for="term-rate-type-tip"/>
            </button>
          )}
        </div>
        {renderInputs()}
        {renderRecommendedMessage()}
      </Fragment>
    )
  }

  const renderContent = () => {
    return (
      <Fragment>
        {renderRateSelection()}
        {getCurrentPage() !== "request-pool" &&
          <PrivateBorrowers/>
        }
        {getCurrentPage() !== "request-pool" &&
          <StrategySelector/>
        }
      </Fragment>
    )
  }

  return (
    <div className="create-pool-page-wrapper page-2">
      {renderContent()}
    </div>
  )
}

export default Page2;