import { ChevronRight, HelpCircle } from "react-feather";
import "./PoolRequestButton.css";
import { Link } from "react-router-dom";

const PoolRequestButton = () => {

  return (
    <Link to="/request-pool" className="pool-request-button fade-in">
      <HelpCircle/>
      Don't see any pools you like? Request a pool
      <section className="pool-request-right-section">
        <ChevronRight/>
      </section>
    </Link>
  )
}

export default PoolRequestButton;