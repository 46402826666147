import { X } from "react-feather";
import "./ClearBorrowers.css";

const ClearBorrowers = (props: {
    setPrivateBorrowers: (val: string[]) => void
    setPrivateBorrowersInput: (val: string) => void
}) => {

    const clearBorrowers = () => {
        props.setPrivateBorrowers([]);
        props.setPrivateBorrowersInput("");
    }

    return (
        <div 
            className="clear-borrowers-wrapper"
            onClick={clearBorrowers}
        >
            <span>Remove All</span>
            <X/>
        </div>
    )
}

export default ClearBorrowers;