import { useContext, useEffect, useState } from "react";
import { ChevronDown, HelpCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import Dropdown from "../Dropdown/Dropdown";
import { CreatePoolContext } from "../../context/CreatePoolContext";
import { CREATE_POOL_CONTEXT, CREATE_POOL_PAGES_CONTEXT, STRATEGY } from "../../utils/Interfaces";
import { renderLazyLoad, strategies } from "../../utils/Utils";
import "./StrategySelector.css";
import { CreatePoolPagesContext } from "../../context/CreatePoolPagesContext";

const StrategySelector = () => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [aaveRate, setAaveRate] = useState<number>(0);
  const [hasStrategies, setHasStrategies] = useState<boolean>(false);
  // const [hasPresetStrategy, setHasPresetStrategy] = useState<boolean>(false);

  // import contexts
  const { selectedLend, selectedStrategy, setSelectedStrategy } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const { strategyRates } = useContext(CreatePoolPagesContext) as CREATE_POOL_PAGES_CONTEXT;

  useEffect(() => {
    getAaveInterestRate();
  // eslint-disable-next-line
  }, [selectedStrategy]);

  useEffect(() => {
    // reset the strategy to no strategy if the lend token changes
    // if (!hasPresetStrategy)
    //   setSelectedStrategy(strategies["No Strategy"]);
    checkLendTokenStrategyOptions();
  // eslint-disable-next-line
  }, [selectedLend])

  const getAaveInterestRate = async () => {
    let rate = 0;
    if (strategyRates[selectedLend.address]) 
      rate = strategyRates[selectedLend.address];
    setAaveRate(rate);
  }

  const checkLendTokenStrategyOptions = () => {
    let hasStrategies = false;
    Object.entries(strategies).forEach((data: any) => {
      const strategyData = data[1];
      // if the strategy is no strategy, skip
      if (strategyData.name === "No Strategy") return;
      // if the strategy supports the lend token, set hasStrategies to true
      if (strategyData.supportedTokens[selectedLend.address])
        hasStrategies = true;
    });
    // if there are no strategies, set the strategy to no strategy
    if (!hasStrategies) setSelectedStrategy(strategies["No Strategy"]);
    // set the hasStrategies state
    setHasStrategies(hasStrategies);
  }

  // render all options
  const renderStrategyOptions = () => {
    return (Object.entries(strategies).map((data: any) => {
      const strategyData = data[1];
      if (strategyData.name === "No Strategy") {
        return (renderStrategyOption(strategyData, true));
      } else {
        if (strategyData.supportedTokens[selectedLend.address])
          return (renderStrategyOption(strategyData, true));
        else return null
      }
    }));
  }

  // listen for strategy option clicks
  const strategyClicked = (strategy: STRATEGY) => {
    setSelectedStrategy(strategy);
    setShowDropdown(false);
  }

  // render a single strategy option
  const renderStrategyOption = (strategy: STRATEGY, showHelp: boolean) => {
    const strategyName = strategy.name;
    // render the strategy rate
    const renderRate = () => {
      if (strategyName === "AAVE" && aaveRate > 0)
        return (
          <span className="strategy-rate">
            {aaveRate === 0
              ? renderLazyLoad(aaveRate === 0,"")
              : `(${aaveRate.toFixed(3)}% APY)`
            }
          </span>
        )
      else return null;
    }
    return (
      <div 
        className="strategy-option-wrapper"
        onClick={() => strategyClicked(strategy)}
      >
        <ReactTooltip 
          id={`${strategyName}-strategy-tip`}
          type="info" 
          className="tool-tip"
        >
          <span>
            {strategy.description}
          </span>
        </ReactTooltip>
        <span className="strategy-name">
          {strategyName}
          {renderRate()}
        </span>
        <span className="strategy-address">
          <a
            href={strategy.docs}
            target="_blank"
            rel="noreferrer"
          >
            {strategy.name !== "No Strategy" ? "More Info" : ""}
          </a>
          {showHelp && <HelpCircle data-tip data-for={`${strategyName}-strategy-tip`}/>}
        </span>
      </div>
    )
  }

  if (!hasStrategies)
    return (null);
  else 
    return (
      <div className="strategy-selector-wrapper info-section-container">
        <ReactTooltip 
          id={`strategy-selector-tip`}
          type="info" 
          className="tool-tip"
        >
          <span>
            Strategies allows the lender to earn yield on their unborrowed funds
          </span>
        </ReactTooltip>
        <span className="info-title">
          Select a Pool Strategy
          <HelpCircle data-tip data-for="strategy-selector-tip"/>
        </span>
        <div 
          className="strategy-selector-input-wrapper"
          onClick={() => setShowDropdown(true)}
        >
          {renderStrategyOption(selectedStrategy, false)}
          <ChevronDown/>
        </div>
        {showDropdown && <Dropdown
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
        >
          {renderStrategyOptions()}
        </Dropdown>}
      </div>
    )
}

export default StrategySelector;