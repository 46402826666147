import React, { Fragment } from "react";
import "./TokenSelector.css";
import { ChevronDown } from "react-feather";
import { fetchTokenLogo, getDisplayNumber, renderLazyLoad } from "../../utils/Utils";
import ReactTooltip from "react-tooltip";

const TokenSelector = (props: {
  sync?: (val: any, id: any) => void,
  id?: any,
  select?: boolean,
  setSelectedInput?: (val: any) => void,
  setShowModal?: (val: boolean) => void,
  symbol: string,
  value: string | number,
  disabled?: boolean,
  balance?: any,
  clickValue?: any,
  text?: string,
  type?: string
  loading?: boolean
}) => {

  const handleChange = (event: any) => {
    if (props.sync)
      props.sync(event.target.value, props.id);
  };

  const setValue = (val: any) => {
    if (props.sync)
      props.sync(val, props.id);
  };

  const inputClicked = () => {
    if (!props.select) return;
    if (props.setSelectedInput) props.setSelectedInput(props.id);
    if (props.setShowModal) props.setShowModal(true);
  };

  const renderTokenSymbol = (symbol: string) => {
    const overflow = symbol.length > 7;

    if (overflow)
      return (
        <Fragment>
          <ReactTooltip
            className="tool-tip"
            id={`token-filter-symbol-tooltip-${symbol}`}
          >
            {symbol}
          </ReactTooltip>
          <span 
            className="token-symbol" 
            data-tip 
            data-for={`token-filter-symbol-tooltip-${symbol}`}
          >
            {symbol}
          </span>
        </Fragment>
      )
    else 
      return (
        <span className="token-symbol">{symbol}</span>
      )
  }

  return (
    <Fragment>
      {props.symbol.length > 10 && (
        <ReactTooltip
          className="tool-tip"
          id={`token-filter-symbol-tooltip-${props.symbol}`}
        >
          {props.symbol}
        </ReactTooltip>
      )}
      <div
        className={`token-selector-container ${props.select ? "select" : ""}`}
        onClick={inputClicked}
      >
        <div 
          className="token-drop-down"
          data-tip 
          data-for={`token-filter-symbol-tooltip-${props.symbol}`}
        >
          <img
            className="token-logo"
            src={fetchTokenLogo(props.symbol)}
            alt="token"
          ></img>
          {renderTokenSymbol(props.symbol)}
          {!props.select && <div className="token-selector-separator"></div>}
        </div>
        <input
          onChange={handleChange}
          onClick={inputClicked}
          className="token-amount-entry"
          placeholder="0.0"
          min="0"
          value={`${props.select ? "$" : ""}${props.value}`}
          disabled={props.select || props.disabled}
        ></input>
        {props.select && <ChevronDown />}
        {renderLazyLoad(
          !props.loading,
          <Fragment>
            {props.balance  ? 
              <span className="token-balance" onClick={() => setValue(getDisplayNumber(props.clickValue ? props.clickValue : props.balance, props.symbol, false))}>
                {props.text ? `${props.text}:` : ''} {getDisplayNumber(props.balance, props.symbol, true)}
              </span>
            : ""}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default TokenSelector;
