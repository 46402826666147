import { BigNumber, ethers } from "ethers"
import { Provider } from "ethers-multicall";
import { ReactNode } from "react";

export enum METHOD_TYPE {
  APPROVE="APPROVE",
  DEPOSIT="DEPOSIT",
  COLLECT="COLLECT",
  BORROW="BORROW",
  REPAY="REPAY",
  DEPLOY="DEPLOY",
  WITHDRAW="WITHDRAW",
  ROLLOVER="ROLLOVER",
  PAUSE_BORROWING="PAUSE_BORROWING",
  SET_FEES="SET_FEES",
  SET_ROLLOVER="SET_ROLLOVER",
  UPGRADE_IMPLEMENTATION="UPGRADE_IMPLEMENTATION"
}

export interface NETWORK_DATA {
  chainId: number,
  chainName: string,
  isTestChain: boolean,
  isLocalChain: boolean,
  isLive: boolean,
  multiVersionSupport?: boolean,
  multicallAddress: string,
  graphUrl: string,
  fallbackCacheKey: string,
  rpcUrl: string,
  getExplorerAddressLink: (address: string) => string,
  getExplorerTransactionLink: (transactionHash: string) => string
}

export interface STRATEGY {
  "name": string,
  "description": string,
  "docs": string,
  "supportedTokens": {
    [address: string]: {
      address: string,
      key: string
    }
  }
}

export interface POOL_DATA {
  pauseTime: number;
  // graph data
  id: string,
  deployer: string,
  borrowers: string[], 
  colBalance: string,
  lendBalance: string,
  _paused: boolean,
  _mintRatio: string,
  _type: string,
  protocolFee: number,
  rawProtocolFee: number,
  startTime: string,
  _totalBorrowed: string,
  // contract data
  isUnder: boolean,
  paused: boolean,
  totalBorrowed: string,
  owner: string;
  colToken: string,
  lendToken: string,
  expiry: string,
  mintRatio: string,
  annualizedFeeRate: number,
  feeRatesAndType: string,
  rawPoolInterestOwed: BigNumber,
  poolInterestOwed: number,
  strategyRate: number,
  strategy: string,
  borrowingDisabled: boolean,
  externalDataLoaded: boolean,
  poolLendBalance: string, 
  poolColBalance: string,
  rawTotalFees: BigNumber,
  lendDecimals: number,
  colSymbol: string,
  lendSymbol: string,
  colPrice: number,
  lendPrice: number,
  colDecimals: number,
  currentFeeRate: number,
  ltv: number,
  userDebt: string,
  rateType: number, 
  startRate: number,
  endRate: number,
  auctionStartDate: number,
  auctionEndDate: number,
  feeType: number,
  maxBorrowAmount: number
}

export interface FEATURED_POOL_DATA {
  poolAddress: string,
  sponsor: string,
  featuredDuration: number
}

export interface FEATURED_POOLS_MANAGER_CONTEXT {
  featuredPoolsList: FEATURED_POOL_DATA[],
  setFeaturedPoolsList: (val: FEATURED_POOL_DATA[]) => void,
  featuredDuration: number,
  setFeaturedDuration: (val: number) => void,
  featuredPoolsPrice: number,
  setFeaturedPoolsPrice: (val: number) => void,
  fetchFeaturedPools: () => void,
  isPoolFeatured: (poolAddress: string, _featuredPoolsList?: FEATURED_POOL_DATA[]) => boolean,
  getFeaturedPoolData: (poolAddress: string) => FEATURED_POOL_DATA | undefined,
}

export interface APP_DATA_CONTEXT{
  tokenPrices: any;
  setTokenPrices: (prices: any) => void,
  historicalPrices: any;
  setHistoricalPrices: (prices: any) => void;
  pending: boolean,
  setPending: (val: boolean) => void,
  calculateLTV: (pool: POOL_DATA, _currentFeeRate?: number, _protocolFee?: number) => number,
  liquidityRange: number[],
  setLiquidityRange: (range: number[]) => void,
  termRateRange: number[],
  setTermRateRange: (range: number[]) => void,
  aprRange: number[],
  setAprRange: (range: number[]) => void,
  dateRange: Date[],
  setDateRange: (range: Date[]) => void,
  fullPoolData: any,
  setFullPoolData: (val: {id: POOL_DATA} | any) => void,
  getPoolData: (pools: POOL_DATA[], updateBorrowed?: boolean) => void,
  fullPoolDataLoaded: boolean,
  showNetworkWarning: boolean,
  setShowNetworkWarning: (val: boolean) => void,
  darkMode: boolean,
  setDarkMode: (val: boolean) => void,
  recaptchaRef: any
}

export interface CREATE_POOL_PAGES_CONTEXT {
  termRateChanged: boolean,
  setTermRateChanged: (val: boolean) => void,
  lendRatioChanged: boolean,
  setLendRatioChanged: (val: boolean) => void,
  hideRecommendation: boolean,
  setHideRecommendation: (val: boolean) => void,
  strategyRates: any,
  calculatedLtv: number
  setCalculatedLtv: (val: number) => void,
}


export interface CREATE_POOL_CONTEXT {
  selectedLend: any,
  setSelectedLend: (val: any) => void,
  selectedCollateral: any,
  setSelectedCollateral: (val: any) => void,
  selectedMintRatio: any,
  setSelectedMintRatio: (val: any) => void
  selectedMintRatioButton: any,
  setSelectedMintRatioButton: (val: any) => void,
  selectedExpiry: any,
  setSelectedExpiry: (val: any) => void,
  pages: string[],
  currentPage: number,
  setCurrentPage: (val: number) => void,
  renderNextButton: () => ReactNode ,
  stepButtonState: string,
  setStepButtonState: (val: string) => void,
  rateType: "Fixed" | "Decay" | "Auction",
  setRateType: (val: "Fixed" | "Decay" | "Auction") => void
  selectedAPR: number[],
  setSelectedAPR: (val: number[]) => void,
  selectedAuctionDates: Date[],
  setSelectedAuctionDates: (val: Date[]) => void,
  initialDeposit: number,
  setInitialDeposit: (val: number) => void,
  lendBalance: BigNumber,
  setLendBalance: (val: BigNumber) => void,
  lendAllowance: BigNumber,
  setLendAllowance: (val: BigNumber) => void,
  selectedStrategy: STRATEGY,
  setSelectedStrategy: (val: STRATEGY) => void,
  privateBorrowers: string[],
  setPrivateBorrowers: (val: string[]) => void,
  queryParamsLoaded: boolean,
  protocolFee: number,
  setProtocolFee: (val: number) => void,
  autoPause: boolean,
  setAutoPause: (val: boolean) => void,
}

export interface WALLET_DATA_CONTEXT {
  multicallProvider: Provider,
  provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcProvider | undefined,
  account: string | undefined,
  chainId: number | 42161 
  setProvider: (provider: ethers.providers.Web3Provider | ethers.providers.JsonRpcSigner | undefined) => void,
  setAccount: (account: string) => void,
  setChainId: (chainId: number) => void,
  setMulticallProvider: (provider: Provider) => void
}

export interface POOL_DATA_CONTEXT {
  selectedPool: POOL_DATA,
  setSelectedPool: (val: POOL_DATA | any) => void,
  selectedFeaturedPools: FEATURED_POOLS | undefined 
  setSelectedFeaturedPools: (pool: FEATURED_POOLS | undefined) => void
  selectedRollover: POOL_DATA,
  setSelectedRollover: (val: POOL_DATA | any) => void,
  lentPools: POOL_DATA[],
}

export interface LICENSE_DATA {
  colDiscount: number,
  currentPoolCount: BigNumber,
  lendDiscount: number,
  expiry: number,
  maxPoolCount: BigNumber,
  id: BigNumber 
}

export interface FEATURED_POOLS {
  deployerAddress: string,
  name: string, 
  img: string,
  pools: number,
  filterMethod: (pools: POOL_DATA[], extraData?: any) => string[]
}

export interface POOL_ROW {
  poolData?: any,
}

export interface POOL_CARD {
  poolData?: any,
}

export interface TOKEN_OUTPUT {
  value: string | number,
  symbol: string,
}

export interface COLLECT_DATA {
  poolAddress: string,
  colTokenAddress: string,
  lendTokenAddress: string,
  expiry: string,
  feeRate: string,
  updatePoolData: () => void
}

export interface LOCAL_TOKEN_DATA {
  address: any,
  displayDecimals: number,
  tokenDecimals: number,
  hasOracle: boolean,
  symbol: string,
  historicalPriceNetwork: any[]
}

export interface SELECTED_TOKEN {
  address: string,
  symbol: string,
  decimals: number,
  price: number
}