import { useEffect } from "react";
import { BarChart2, Book, CheckCircle, FileText, Inbox, XCircle } from "react-feather";
import { fetchSocialLogo, isDarkMode, tabletBreakpoint } from "../../utils/Utils";
import { useCookies } from "react-cookie";
import "./OptionsMenu.css";
import { useSnackbar } from "notistack";

const OptionsMenu = (props: {
    setShowOptionsMenu: (show: boolean) => void
}) => {

    const [cookies, setCookie] = useCookies(['infiniteApprovalDisabled']);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        window.addEventListener('mousedown', onClick);
        return () => {
            window.removeEventListener('mousedown', onClick);
        };
    // eslint-disable-next-line
    }, [cookies.infiniteApprovalDisabled]);

    // hide dropdown when user clicks anywhere else
    const onClick = () => {
        setTimeout(() => {
            props.setShowOptionsMenu(false);
        }, 300);
    }

    // toggle infinite approvals
    const approvalOptionClicked = () => {
        let word = "";
        if (cookies.infiniteApprovalDisabled && cookies.infiniteApprovalDisabled === "true") {
            setCookie("infiniteApprovalDisabled", false);
            word = "enabled";
        } else {
            setCookie("infiniteApprovalDisabled", true);
            word = "disabled";
        }
        enqueueSnackbar(`Infinite approvals ${word}`, {
            persist: false,
        });
    }

    // decide whether to get dark or light version of logo
    const getSocialLogo = (name: string, light?: boolean) => {
      const isDesktop = window.innerWidth > tabletBreakpoint;
      return (fetchSocialLogo(isDesktop && !light ? `${name}-dark` : name));
    }

    return (
      <div className="options-menu-wrapper fade-in">
        <a
          className="options-menu-option"
          href="https://discord.gg/mZWtWVJvzZ"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <img src={getSocialLogo("discord", isDarkMode())} alt="discord" />
          Discord
        </a>
        <a
          className="options-menu-option"
          href="https://discord.gg/bonsaidao"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <img src={getSocialLogo("discord", isDarkMode())} alt="bonsai" />
          Bonsai 
        </a>
        <a
          className="options-menu-option"
          href="https://twitter.com/VendorFi"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <img src={getSocialLogo("twitter", isDarkMode())} alt="twitter" />
          Twitter
        </a>
        <a
          className="options-menu-option"
          href="https://github.com/VendorFinance"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <img src={getSocialLogo("github", isDarkMode())} alt="github" />
          Github
        </a>
        <a
          className="options-menu-option"
          href="https://vendorfinance.medium.com/"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <img src={getSocialLogo("medium", isDarkMode())} alt="medium" />
          Medium
        </a>
        <a
          className="options-menu-option bug-bouny"
          href="https://discord.com/channels/945189813869490247/945412786647883826"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <Inbox/>
          Bug Report 
        </a>
        <a
          className="options-menu-option"
          href="https://docs.vendor.finance/overview/what-is-vendor-finance"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <Book />
          Docs
        </a>
        <a
          className="options-menu-option"
          href="https://dune.com/mcman/vendor-finance-version-2"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <BarChart2/>
          Analytics 
        </a>
        <a
          className="options-menu-option"
          href="https://github.com/VendorFinance/vendor-contracts-v2/blob/main/Vendor%20Finance%20-%20Zellic%20Audit%20Report.pdf"
          target="_blank"
          rel="noreferrer"
          onClick={() => props.setShowOptionsMenu(false)}
        >
          <FileText />
          Audit
        </a>
        <div className="options-menu-option" onClick={approvalOptionClicked}>
          {cookies.infiniteApprovalDisabled &&
          cookies.infiniteApprovalDisabled === "true" ? (
            <XCircle color="var(--cherry)"/>
          ) : (
            <CheckCircle color="var(--sea-green)"/>
          )}
          Infinite Approval
        </div>
      </div>
    );
}

export default OptionsMenu;