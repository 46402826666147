import axios from "axios";
import OracleABI from "../abi/OracleABI";
import PoolFactory from "../abi/PoolFactory";
import LicenseManager from "../abi/LicenseManager";
import PositionTracker from "../abi/PositionTracker";
import { ethers, BigNumber } from "@usedapp/core/node_modules/ethers";
import { Skeleton } from "@material-ui/lab";
import { Arbitrum, Mainnet } from "@usedapp/core";
import { db } from "./firebase-config";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore"; 
import moment from "moment";
import FeeManager from "../abi/FeeManager";
import AAVELendingPool from "../abi/AAVELendingPool";
import FeaturedPoolsManager from "../abi/FeaturedPoolsManager";
import { METHOD_TYPE, POOL_DATA } from "./Interfaces";
import { Provider, Contract as MulticallContract } from "ethers-multicall";
import LendingPool from "../abi/LendingPool";
import { subDays } from "date-fns";

export const prodRPCAddress = "https://thrilling-virulent-flower.arbitrum-mainnet.quiknode.pro/f1c88a01f0b68ef4fb58fb3c4ae1adbc2b74ee70/";
// time to poll token balances
export const pollTime = 5000;
// breakpoints for mobile/tablet
export const tabletBreakpoint = 1183;
export const mobileBreakpoint = 600;
// use dev cloud functions
export const useDevFunctions = false;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

// readable ABIs for multicall contract instantiation
export const readableABIs = {
  erc20: [
    "function balanceOf(address _owner) public view returns (uint256)",
    "function decimals() public view returns (uint256)",
    "function allowance(address owner, address spender) public view returns (uint256)"
  ],
  lendingPool: [
    "function totalFees() public view returns (uint256)",
    "function undercollateralized() public view returns (uint256)",
    "function allowedRollovers(address) public view returns (bool)",
    "function getPoolSettings() external view returns (uint8,address,uint48,address,uint48,address,uint48,uint48,uint256,address[],bytes32)"
  ],
  feeManager: [
    "function feeRates(address _pool) public view returns (uint48)",
    "function getCurrentRate(address _pool) public view returns (uint48)"
  ]
}

// get graph data for an individual pool
export const getPoolsFromGraph = async (
  chainId: number, 
  useFallback?: boolean, 
) => {

  let res:any[] = [];

  try {
    const networkData = getNetworkData(chainId);
    // setup graph query url
    const url = useFallback 
      ? "https://us-central1-vendor-finace.cloudfunctions.net/getEntities"
      : networkData?.graphUrl;

    // setup graph query key
    const queryKey = useFallback 
      ? networkData.fallbackCacheKey 
      : "pools";

    const queryFilters = useFallback
      ? ""
      : `where: {paused: false, expiry_gt: ${Math.floor(new Date().getTime() / 1000)}}`;

    const query = await axios.post(
      url,
      {
        query: `
        {
          ${queryKey}(first: 300, ${queryFilters}) {
            id
            deployer
            colToken
            lendToken
            expiry
            mintRatio
            startTime
            borrowers
            totalBorrowed
            colBalance
            lendBalance
            paused
            startRate
            endRate
            auctionStartDate
            auctionEndDate
            feeType
          }
        }
        `,
      }
    );
    res = query.data.data[queryKey];
  } catch (e) {
    console.log(e);
    return undefined;
  }

  return res;
}

export const getRolloverPools = async (selectedPool: POOL_DATA, owner: string, chainId: number) => {

  // get graph URL
  const url:any = getNetworkData(chainId)?.graphUrl;

  // get collateral and lend tokens
  const colToken = selectedPool.colToken;
  const lendToken = selectedPool.lendToken;

  const rolloverPoolsGraph = await axios.post(url, {query: `
    {
      pools(where: {
        lendToken: "${lendToken.toLowerCase()}",
        colToken: "${colToken.toLowerCase()}",
        deployer: "${owner.toLowerCase()}"
      }) {
        id
        lendToken
        deployer
        colToken
        expiry
        mintRatio
        startTime
        paused
      }
    }
    `
    }
  );
  let filteredRolloverPools = [];
  if (rolloverPoolsGraph.data.data.pools.length !== 0) {
    // only work with the correct parameters
    filteredRolloverPools = rolloverPoolsGraph.data.data.pools.filter(
      (p: any) =>
        p.id !== selectedPool.id &&
        p.expiry > selectedPool.expiry &&
        !p.paused
    )
  }

  return (filteredRolloverPools);
}

export const isDarkMode = () => {
  // check cookies for darkMode 
  const darkMode = document.cookie.split(";").filter((item) => item.includes("darkMode"))[0];
  return darkMode ? darkMode.split("=")[1] === "true" : false;
}

// get the current page "borrow", "create-pool", "my-pools", etc.
export const getCurrentPage = () => {
  const pathname = window.location.pathname;
  return pathname.split("/")[1];
}

// get the web3 provider that allows for signing transactions 
export const getWeb3Provider = (rpcUrl?: ethers.providers.JsonRpcProvider | any, jsonProvider?: boolean): ethers.providers.Web3Provider | any => {
  if (!jsonProvider) {
    const provider = new ethers.providers.Web3Provider(rpcUrl, "any");
    return(provider);
  } else {
    const provider = new ethers.providers.JsonRpcProvider(rpcUrl ? rpcUrl : prodRPCAddress);
    return (provider);
  }
}

// get the current chainId from the Blocknative provider
export const getChainId = (wallet: {chains: any[]}): number | undefined => {
  try {
    return parseInt(wallet.chains[0].id);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

// get the current account address from the Blocknative provider
export const getAccount = (wallet: {accounts: any[]}): string | undefined => {
  try {
    return wallet.accounts[0].address;
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

// check if a given pool is expired
export const isPoolExpired = (pool: POOL_DATA) => {
  return (Number(pool.expiry) * 1000 < new Date().getTime());
}

// add chain query if user is not on arbitrum 
export const getChainQuery = (chainId: number) => {
  const chainQuery = chainId === networks.Arbitrum.chainId 
    ? ""
    : `?chain=${getNetworkData(chainId).chainName.toLowerCase()}`
  return chainQuery;
}

// create fake historical price data
export const generateFakePriceData = (skip: number = 40):any[] => {
  let historicalData = [];
  const randomInRange = () => Math.random() * (500 - 400) + 400;
  let lastRandom = 1500;
  for (let i = 0; i < 1465; i+=skip) {
    // if (i % 2 === 0) continue;
    const randomValue = i % 2 === 0 ? randomInRange() : lastRandom
    if (randomValue !== lastRandom) lastRandom = randomValue;
    historicalData.push({
      // name: addDays(firstDate, i),
      // name: moment(new Date(priceData[0])).format("MMM D"),
      // timestamp: new Date(priceData[0]),
      // "Expiry": ,
      "Collateral Price": randomValue,
      "Borrow Value": 0
    });
  }

  return historicalData;
}

// create fake historical price data
export const generateFakeRateData = ():any[] => {
  let rateData = [];
  let now = new Date().getTime();
  for (let i = 0; i++ < 20; now+=20000000) {
    const rate = 5;
    rateData.push({
      name: now,
      rate: rate
    });
  }
  return rateData;
}

export const getCalendarLink = (timestamp: number, message: string) => {
	const rawTimestamp = moment.utc(timestamp).format('YYYYMMDD,HHmmss');
	const split = rawTimestamp.split(',');
	const date = split[0];
	const time = split[1];
	return `https://www.timeanddate.com/countdown/generic?iso=${date}T${time}&p0=%3A&msg=${message}&font=sanserif&csz=1`;
}

export const getTokenContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  address: string,
  useMulticall?: boolean
) => {
  if (useMulticall) 
    return new MulticallContract(
      address,
      readableABIs.erc20
    );
  else 
    return new ethers.Contract(
      address,
      readableABIs.erc20,
      wallet 
    );
}

export const getPoolContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  address: string,
  useMulticall?: boolean
) => {
  if (useMulticall) 
    return new MulticallContract(
      address,
      LendingPool,
    );
  else 
    return new ethers.Contract(
      address,
      LendingPool,
      wallet 
    );
}

export const getAaveLendingPoolContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider | ethers.providers.JsonRpcSigner,
  chainId: number,
  useMulticall?: boolean
) => {
  if (useMulticall || !wallet) 
    return new MulticallContract(
      // @ts-ignore
      contractAddresses.AAVE_LENDING_POOL.address[chainId],
      AAVELendingPool,
    );
  else 
    return new ethers.Contract(
      // @ts-ignore
      contractAddresses.AAVE_LENDING_POOL.address[chainId],
      AAVELendingPool,
      wallet 
    );
}

// get a contract reference to the featured pools manager 
export const getFeaturedPoolsManagerContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider | ethers.providers.JsonRpcSigner,
  chainId: number,
  useMulticall?: boolean
) => {
  if (useMulticall) 
    return new MulticallContract(
      // @ts-ignore
      contractAddresses.FEATURED_POOLS_MANAGER.address[chainId],
      FeaturedPoolsManager,
    );
  else 
    return new ethers.Contract(
      // @ts-ignore
      contractAddresses.FEATURED_POOLS_MANAGER.address[chainId],
      FeaturedPoolsManager,
      wallet 
    );
}

// get a contract reference to license engine
export const getFeeManagerContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number,
  useMulticall?: boolean
) =>{
  if (useMulticall) 
    return new MulticallContract(
      // @ts-ignore
      contractAddresses.FEE_MANAGER.address[chainId],
      FeeManager,
    );
  else 
    return new ethers.Contract(
      // @ts-ignore
      contractAddresses.FEE_MANAGER.address[chainId],
      FeeManager,
      wallet 
    );
}

// get a contract reference to license engine
export const getLicenseEngineContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.LICENSE_ENGINE.address[chainId],
    LicenseManager,
    wallet 
  );
}

// get a contract reference to oracle 
export const getOracleContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.ORACLE_ADDRESS.address[chainId],
    OracleABI,
    wallet 
  );
}

// get a contract reference to pool factory 
export const getPoolFactoryContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.POOL_FACTORY.address[chainId],
    PoolFactory,
    wallet 
  );
}

// get a contract reference to position tracker 
export const getPositionTrackerContract = (
  wallet: ethers.Wallet | ethers.providers.JsonRpcProvider,
  chainId: number
) =>{
  return new ethers.Contract(
    // @ts-ignore
    contractAddresses.POSITION_TRACKER.address[chainId],
    PositionTracker,
    wallet 
  );
}

// get a transaciton URL based on hash and chainId
export function getTransactionUrl(hash: string, chainId: number) {
  const network = getNetworkData(chainId);
  const url = network?.getExplorerTransactionLink(hash);
  return url;
}

export function getWithdrawToRepay(
  input: BigNumber, 
  totalFees: BigNumber, 
  mintRatio: BigNumber, 
  colDecimals: number | string, 
  lendDecimals: number | string 
) {
  const num = input.mul(mintRatio).mul(ethers.utils.parseUnits("1", lendDecimals));
  const denom = ethers.utils.parseUnits("1", colDecimals).mul(ethers.utils.parseUnits("1", 18));
  return num.div(denom).add(totalFees);
}

// replace boosted vaults symbols with liquid umami vaults symbols if needed
const handleUmamiBoostedVaults = (symbol: string) => {
  if (symbol.toUpperCase() === "GLPUSDCB") 
    return "glpUSDC";
  else if (symbol.toUpperCase() === "GLPWETHB")
    return "glpWETH";
  else if (symbol.toUpperCase() === "GLPWBTCB")
    return "glpWBTC";
  else if (symbol.toUpperCase() === "GLPLINKB")
    return "glpLINK";
  else if (symbol.toUpperCase() === "GLPUNIB")
    return "glpUNI";
  else 
    return symbol;
}

export async function fetchTokenPriceFirebase(_symbol: string) {
  // replace boosted vaults with liquid umami vaults if needed
  let symbol = handleUmamiBoostedVaults(_symbol);
  // get timestamp docs for the last ~hour
  const q = query(collection(db, symbol), orderBy("timestamp", "desc"), limit(1));
  const querySnapshot = await getDocs(q);
  let price = 0;
  // get the latest timestamp
  await querySnapshot.forEach(async(doc: any) => {
    const data = doc.data() || true;
    if (price === 0) price = (data.price);
  });
  return price;
}

export function generateUid() {
  let uid =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);
  uid = uid.replace(/\d+/g, '')
  return (uid);
}

export function getRepayToWithdraw(
  input: BigNumber, 
  totalFees: BigNumber, 
  mintRatio: BigNumber, 
  colDecimals: number | string, 
  lendDecimals: number | string
) {
  /*
    (_repayAmount * 1e18 * (10**colToken.decimals())) /
      (10**lendToken.decimals()) /
      mintRatio;
  */
  let val = input.sub(totalFees);
  const term1 = val.mul(ethers.utils.parseUnits("1", 18)).mul(ethers.utils.parseUnits("1", colDecimals));
  const term2 = term1.div(ethers.utils.parseUnits("1", lendDecimals));
  const term3 = term2.div(mintRatio);
  val = term3;
  return val.gt(0) ? val : ethers.BigNumber.from(0);
}

export async function fetchBulkPrices (ids: string[]) {
  try {
    const tokenQuery = ids.map((id) => `coingecko:${id}`);
    const query = `${tokenQuery.join(",")}`;
    const prices = await axios.get(
      `https://coins.llama.fi/prices/current/${query}`
    );
    return (prices.data.coins);
  } catch (e) {
    console.log(e);
    console.log("failed to fetch bulk prices");
  }
}

export async function fetchGeckoTerminalPrice(address: string, pairAddress: string) {
  try {
    const res = await axios.get(`https://api.geckoterminal.com/api/v2/networks/arbitrum/pools/${pairAddress}`);
    const data = res.data.data;
    const price = data.attributes.base_token_price_usd;
    return Number(price);
  } catch (e) {
    return 0;
  }
}

export async function fetchTokenPrice(_address: string, chainId: number, provider?: any) {
  try {
    // checksum address
    const address = ethers.utils.getAddress(_address.toLowerCase());
    const currentTokenData = fetchLocalTokenData(address, chainId);
    // @ts-ignore
    if (!currentTokenData.usesFirebase) {
      // don't fetch prices for unsupported chains
      if (!supportedNetworks.includes(chainId)) return 0;

      if (currentTokenData.pairAddress) {
        const price = await fetchGeckoTerminalPrice(address, currentTokenData.pairAddress);
        return price;
      }

      // attempt to get price using oracle first
      const rawOraclePrice:number = await fetchTokenPriceOracle(address, chainId, provider);
      const oraclePrice = parseFloat(ethers.utils.formatUnits(rawOraclePrice, 8));

      // if oracle fails use coingecko
      if (oraclePrice < 0) {
        // const tokenData = fetchLocalTokenData(address, chainId);
        const currency = "usd";
        // get token data
        // get the price feed network for the token
        const network = currentTokenData.historicalPriceNetwork[1];
        const priceNetworkAddress = currentTokenData.address[currentTokenData.historicalPriceNetwork[0]];
        if (network !== "none") {
          const query = `contract_addresses=${priceNetworkAddress}&vs_currencies=${currency}`;
          const prices = await axios.get(
            `https://api.coingecko.com/api/v3/simple/token_price/${network}?${query}`
          );
          return prices && prices.data[priceNetworkAddress.toLowerCase()]?.[currency];
        } else {
          return 0;
        }
      } else {
        return oraclePrice;
      }
    } else {
      // use firebase for some tokens without oracles 
      const tokenData = fetchLocalTokenData(address, chainId);
      return await fetchTokenPriceFirebase(tokenData.symbol);
    }
  } catch (e) {
    console.log(e);
    console.log("failed to load token price for ", _address);
    return 0;
  }
}

// fetch a token's price from an on-chain oracle
export async function fetchTokenPriceOracle(
  address: string, 
  chainId: number, 
  provider: ethers.providers.JsonRpcProvider | ethers.providers.Web3Provider
) {
  try {
    const oracleContract = getOracleContract(provider, chainId);
    const price = await oracleContract.getPriceUSD(address);
    return price;
  } catch (e) {
    console.log(e);
    return -1;
  }
}

// locally fetch an icon from a given name
export function fetchIcon(name: string, light?: boolean) {
  return (require(`../img/icons/${name}${light? "-light" : ""}.svg`));
}

// locally fetch the logo for a given social media site
export function fetchSocialLogo(name: string) {
  return require(`../../public/assets/socials/${name}.svg`);
}

export function fetchLocalTokenDataBySymbol(symbol: string) {
  const data = Object.values(tokenData).find((token) => token.symbol.toLowerCase() === symbol.toLowerCase());
  return data; 
}

// locally fetch the logo for a given token symbol
export function fetchTokenLogo(symbol: string | undefined) {
  const tokenData = fetchLocalTokenDataBySymbol(symbol || "");
  if (tokenData) {
    // if the token is an LP token, use the LP logo
    // @ts-ignore
    if (tokenData.isLpToken)
      return require(`../img/tokens/${"LP"}-logo.png`);
    else
      return require(`../img/tokens/${tokenData.symbol.toUpperCase()}-logo.png`);
  } else { 
    return null;
  }
}

// fetch historical price data for a given token from firebase 
export async function fetchHistoricalFirebasePriceData(
  _symbol: string
):Promise<any[]>{
  // replace boosted vaults with liquid umami vaults if needed
  const symbol = handleUmamiBoostedVaults(_symbol);
  // get timestamp docs
  const q = query(collection(db, symbol), orderBy("timestamp", "desc"), limit(500));
  const querySnapshot = await getDocs(q);
  let priceData:any[] = [];
  let i = 0;
  // iterate through timestamps and store the price
  await querySnapshot.forEach(async(doc: any) => {
    const data = doc.data() || true;
    if (i++ % 3 === 0)
      priceData.unshift({timestamp: data.timestamp, price: data.price});
  });
  return priceData;
}

// fetch historical prices using the gecko terminal api
export async function fetchHistoricalGeckoTerminalData(
  pairAddress: string
) {
  try {
    // setup timeframes
    const aggregate = 12;
    const timeframe = "hour";
    const limit = 300;
    // make request
    const res = await axios.get(`https://api.geckoterminal.com/api/v2/networks/arbitrum/pools/${pairAddress}/ohlcv/${timeframe}?aggregate=${aggregate}&limit=${limit}`)
    const priceData = res.data.data.attributes.ohlcv_list;
    let prices:{timestamp: number, price: number}[] = [];
    // parse data and extract timestamp and price
    for (const data of priceData) {
      const timestamp = data[0];
      const closePrice = data[4];
      prices.push({timestamp, price: closePrice});
    }
    // reverse the order
    prices.reverse();
    // filter out every other price to reduce the number of points
    prices = prices.filter((_, i) => i % 2 === 0 || i === prices.length - 1);
    return prices;
  } catch (e) {
    console.log(e);
    return [];
  }
}

// fetch historical price data for a given token from coingecko
export async function fetchHistoricalPriceData(
  address: string, 
  chainId: number, 
  days=30, 
  currency="usd"
) {
  const data = await fetchLocalTokenData(address, chainId);
  if (!data.usesFirebase && !data.pairAddress) {
    const startTimestamp = Math.floor(subDays(new Date(), days).getTime() / 1000);
    const endTimestamp = Math.floor(new Date().getTime() / 1000);
    const url = `https://coins.llama.fi/chart/coingecko:${data.id}?start=${startTimestamp}?end=${endTimestamp}&span=100&period=1d`;
    const priceRequest = await axios.get(url);
    return (priceRequest.data.coins[`coingecko:${data.id}`].prices);
  } else if (data.usesFirebase) {
    // use firebase for tokens with usesFirebase value
    const fetchedData = await fetchHistoricalFirebasePriceData(data.symbol);
    let priceData = [...fetchedData];
    // filter out every other price to reduce the number of points
    // but only for tokens that have been backfilled (ie: LP tokens)
    if (data.isLpToken && data.symbol.indexOf("3CRV") === -1)
      priceData = priceData.filter((data, i) => {
        if (data.timestamp < 1686689219)
          return true;
        else if (data.timestamp > 1686689219 && i % 15 === 0)
          return true;
        else return false;
      });
    return (priceData);
  } else if (data.pairAddress) {
    // use gecko terminal api for tokens with a pairAddress value
    const priceData = await fetchHistoricalGeckoTerminalData(data.pairAddress);
    return (priceData);
  } else {
    return [];
  }
}

// locally fetch the symbol for a token given the token address
export function fetchTokenSymbol(address: string, chainId = defaultChain) {
  const data = fetchLocalTokenData(address, chainId);
  return data?.symbol || "";
}

// fetch the data for locally stored tokens
export function fetchLocalTokenData (address: string, chainId = defaultChain) {
  for (const [key, _value] of Object.entries(tokenData)) {
    try {
      let value:any = _value;
      if (value.address[chainId].toLowerCase() === address.toLowerCase() && key) {
        return {
          ...value,
          hasOracle: hasOracle(value.address[chainId]),
        };
      }
    } catch (e) {
      continue;
    }
  };
  return undefined;
}

export function getDisplayNumber (num: BigNumber, _symbol: string | undefined, truncate = false) {
  const symbol = _symbol ? _symbol : "";
  const data = Object.values(tokenData).find((data) => {
    return data.symbol.toUpperCase() === symbol.toUpperCase();
  });
  if (!data) return "0";
  try {
    if (truncate) {
      let res:any = ethers.utils.formatUnits(num, data.tokenDecimals);
      res = Math.floor(res * Math.pow(10, data.displayDecimals)) / Math.pow(10, data.displayDecimals);
      return (res.toString());
    } else {
      return (ethers.utils.formatUnits(num, data.tokenDecimals));
    }
  } catch (e) {
    console.log(symbol);
    console.log(data);
    return "0";
  }
}

// if the value is valid render the element, else render a skeleton component
export function renderLazyLoad (value: any, element: any) {
  if (value || value === 0) {
    return element
  } else {
    return (
      <Skeleton 
        className="loading-skeleton"
        height={23}
      />
    );
  }
}

export function truncateNumber(num: number | any, fixed = 0, ignoreBorder = false) {
  try {
    let repeatCount = fixed === 0 ? 0 : fixed - 1
    let borderNumber = parseFloat(`0.${"0".repeat(repeatCount)}1`)
    if (!ignoreBorder && num < borderNumber) return 0;
    else {
      // eslint-disable-next-line
      const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
      return num.toString().match(re)[0];
    }
  } catch (e) {
    // console.log(e);
    return "0";
  }
}

export const computeRate = (
  _startDate: Date,
  _endDate: Date,
  startRate: number,
  endRate: number,
  _expiry: Date,
  time: number,
  rateType: "Decay" | "Auction" | "Fixed" 
) => {
  // convert date objects into timestamps
  const startDate = _startDate.getTime();
  const endDate = _endDate.getTime();
  const expiry = _expiry.getTime();
  if (rateType === "Auction") {
    if (time < startDate) 
      return startRate;
    else if (time > endDate) 
      return endRate;
    else 
      return endRate + ((startRate - endRate) * (endDate - time) / (endDate - startDate));
  } else if (rateType === "Decay") {
    return startRate * (expiry - time) / (31_536_000 * 1000);
  } else {
    const apr = endRate;
    return apr;
  }
}

export const computeAnnualizedRate = (
  startRate: number,
  endRate: number,
  auctionStartDate: number,
  auctionEndDate: number,
  expiry: number,
  feeType: number
) => {
  let annualizedFeeRate = 0;
  if (feeType === 1) {
    const currentRate = startRate === endRate 
      ? startRate
      : computeRate(
          new Date(Number(auctionStartDate)),
          new Date(Number(auctionEndDate)),
          Number(startRate),
          Number(endRate),
          new Date(expiry),
          new Date().getTime() / 1000,
          "Decay" 
        );
    // annualizedFeeRate = feeRateResp;
    annualizedFeeRate = (31536000 / (((Number(expiry)) - new Date().getTime() / 1000))) * currentRate;
  } else {
    /*
          auctionStartDate     auctionEndDate
      ------------|----------------|----------
          A               B             C
      If now is in region A -> APR=auctionStartRate
      If now is in region B -> APR= (AED-now)/ (AER-ASR)
      If now is in region C -> APR=auctionEndRate
    */
    const now = Math.trunc(new Date().getTime() / 1000);
    // logic for determining APR for pools with type 2 (linear decay with auction)
    if (now < auctionStartDate)  {
      annualizedFeeRate = startRate;
    } else if (now >= auctionStartDate && now < auctionEndDate)  {
      annualizedFeeRate = endRate + (startRate - endRate) * (auctionEndDate - now) / (auctionEndDate - auctionStartDate);
    } else if (now >= auctionEndDate) {
      annualizedFeeRate = endRate;
    }
  }
  return annualizedFeeRate;
}

export const shouldRenderPool = (
  pool: POOL_DATA, 
  chainId: number,
  tokenPrices: any,
  account: string | undefined,
  liquidityRange: number[],
  aprRange: number[],
  dateRange: Date[],
  queryPool: string | undefined,
  ltv: number,
  showJunkPools?: boolean
) => {
  try {
    let show = true;
    // get lend/col token balance and value
    const lendTokenData = fetchLocalTokenData(pool.lendToken, chainId);
    const colTokenData = fetchLocalTokenData(pool.colToken, chainId);
    const lendBalance = ethers.utils.formatUnits(pool.lendBalance, lendTokenData.tokenDecimals);
    const colBalance = ethers.utils.formatUnits(pool.colBalance, colTokenData.tokenDecimals);
    const lendPrice = tokenPrices[lendTokenData.address[`${chainId}`]];
    const colPrice = tokenPrices[colTokenData.address[`${chainId}`]];
    let colValue = Math.max(0,Math.floor(parseFloat(colBalance) * parseFloat(colPrice) * 100) / 100);
    if (isNaN(colValue)) colValue = 0;
    // truncate value to 2 decimals
    let lendValue = Math.max(0,Math.floor(parseFloat(lendBalance) * parseFloat(lendPrice) * 100) / 100);
    if (isNaN(lendValue)) lendValue = 0;
    // is the user allowed to borrow
    // remove empty items from the borrowers array
    const borrowers = pool.borrowers.filter((b: any) => b !== "");
    let isPrivate = borrowers.length > 0;
    let userWhitelisted = !isPrivate;
    if (isPrivate && borrowers.includes(account || ""))
      userWhitelisted = true;
    // is pool liquidity in range
    const inLiquidityRange =  lendValue >= liquidityRange[0] && lendValue <= liquidityRange[1];
    const annualizedRate = computeAnnualizedRate(
      Number(pool.startRate) / 10000,
      Number(pool.endRate) / 10000,
      Number(pool.auctionStartDate),
      Number(pool.auctionEndDate),
      Number(pool.expiry),
      pool.feeType
    );
    // const inTermRateRange = termRate >= termRateRange[0] && termRate <= termRateRange[1];
    const inAPRRange = annualizedRate >= aprRange[0] && annualizedRate <= aprRange[1];
    // check if junk pool (no lend and collateral)
    const junkPool = (lendValue === 0 && colValue === 0) && !showJunkPools;
    const inDateRange = 
      Number(pool.expiry) >= dateRange[0].getTime() / 1000 && 
      Number(pool.expiry) <= dateRange[1].getTime() / 1000;
    // is pool disabled
    // TODO: figure out when to check if pool is paused
    // const isDisabled = pool._paused;
    // is pool the query pool
    const isQueryPool = pool.id.toLowerCase() === queryPool;
    // is the pool underwater
    // const ltv = calculateLTV(pool);
    const isUnderWater = ltv > 100;
    if (isQueryPool) show = true;
    else if (isPrivate && !userWhitelisted) show = false;
    else if (junkPool) show = false;
    else if (isUnderWater && !isPrivate) show = false;
    else if (!inLiquidityRange || !inAPRRange || !inDateRange) show = false;
    // if (pool.id === "0xc0cba3ceac7e330c2e4a057f315a37bf9ee79775") {
    //   console.log(show);
    // }
    return show;
  } catch (e) {
    return false;
  }
}

// decide whether to use hardcoded gas limit or let wallet estimate
export const getGasLimit = (chainId: number, methodType: METHOD_TYPE, override?: boolean) => {
  // @ts-ignore
  if (chainId !== 137)
    return {}
  else 
    return {
      // @ts-ignore
      gasLimit: gasLimits[methodType][chainId]
    }
}

export const gasLimits = {
  APPROVE: {
    5: 80000,
    137: 80000,
    8453: 80000,
    42161: 2500000,
    421613: 2500000
  },
  DEPOSIT: {
    5: 120000,
    137: 120000,
    8453: 120000,
    42161: 2000000,
    421613: 2000000
  },
  COLLECT: {
    5: 170000,
    137: 170000,
    8453: 170000,
    42161: 2000000,
    421613: 2000000
  },
  BORROW: {
    5: 350000,
    137: 350000,
    8453: 350000,
    42161: 2500000,
    421613: 2500000
  },
  REPAY: {
    5: 170000,
    137: 170000,
    8453: 170000,
    42161: 2500000,
    421613: 2500000
  },
  DEPLOY: {
    5: 700000,
    137: 700000,
    8453: 700000,
    42161: 4500000,
    421613: 2500000
  },
  WITHDRAW: {
    5: 95000,
    137: 95000,
    8453: 95000,
    42161: 1500000,
    421613: 100000 
  },
  ROLLOVER: {
    5: 500000,
    137: 500000,
    8453: 500000,
    42161: 2000000,
    421613: 2000000 
  },
  PAUSE_BORROWING: {
    5: 35000,
    137: 35000,
    8453: 35000,
    42161: 2000000,
    421613: 2000000 
  },
  SET_FEES: {
    5: 0,
    137: 65000,
    8453: 65000,
    42161: 2000000, 
    421613: 2000000,
  },
  SET_ROLLOVER: {
    5: 40000,
    137: 35000,
    8453: 35000,
    42161: 2000000,
    421613: 2000000
  },
  UPGRADE_IMPLEMENTATION: {
    5: 700000,
    137: 700000,
    8453: 700000,
    42161: 4500000,
    421613: 2500000
  }
}

export const networkNames = {
	1: "Ethereum",
	3: "Ropsten",
	4: "Rinkeby",
	5: "Goerli",
	42: "Kovan",
	56: "BSC",
  137: "Polygon",
	250: "Fantom",
  8453: "Base",
  5001: "Mantle Testnet",
  42161: "Arbitrum",
  421613: "AGoerli",
  43114: "Avalanche",
};

export const supportedNetworks = [
  1,      // Mainnet
  // 5,   // Goerli
  137,    // Polygon
  5001,   // Mantle Testnet
  8453,   // Base
  42161,  // Arbitrum
  421613, // aGoerli
];

export const contractAddresses = {
  ORACLE_ADDRESS: {
    address: {
      1: "0x04415967D0C06ea40470B81Baad8212E9d815Bf5",
      5: "",
      42: "",
      137: "",
      5001: "0xAf013ba5cE2e3AC97BF93Ea968031B3D0B4682Cf",
      8453: "0x6C47eb80f0B61409E00Dd608D9c87365FDeaA70d",
      42161: "0xc000281a3aE4F82CD0a430f0ae8b2A0610cA26A5",
      421613: "0xd2CB6f0b0E8D3cF769dFd64CB9231D78b9839094"
    }
  },
  POSITION_TRACKER: {
    address: {
      1: "0x6eB1775410642CDF4ba2DCF2fdAEF4d0BBd08652",
      5: "",
      42: "",
      137: "",
      5001: "0xF2D77e0840A3E5941ed9E4925539E30491f27194",
      8453: "0x37D2f55F34f3F7D5A7Deb0367915e5ab4aC15193",
      42161: "0x93E73571A71D27cd35a20E14BA5B352C3C2236fC",
      421613: "0x5bB76249a1453F8E548131398f99dbC7E83BEa4C"
    }
  },
  POOL_FACTORY: {
    address: {
      1: "0x8dc36968eA4C8D3CBE916F37A75Fdb787a0B4967",
      5: "",
      42: "",
      137: "",
      5001: "0xeCBFd6cF5Eebe9313D386A19a42a474a2998e56b",
      8453: "0xAf013ba5cE2e3AC97BF93Ea968031B3D0B4682Cf",
      42161: "0x0b2B8Fbf3dfd6237921A89355cfc08f107bFbf98",
      421613: "0xE6DaEA29844180b03fd5C94749b237811b3D526c"
    }
  },
  FEE_MANAGER: {
    address: {
      1: "0x0Cc43a4C570e7EED16c34Ce8540ae5dA037fDf0A",
      5: "",
      42: "",
      137: "",
      5001: "0x37D2f55F34f3F7D5A7Deb0367915e5ab4aC15193",
      8453: "0xC9CC9366337429E65252fA35B77498Ba2F48a11d",
      42161: "0x6c58D106C613627Bd11A885fC15A1572a358AA27",
      421613: "0x9ADf66f256A998Fb9f430d87C8fdfa1501897340"
    }
  },
  AAVE_LENDING_POOL: {
    address: {
      1: "0x7B4EB56E7CD4b454BA8ff71E4518426369a138a3",
      8453: "0x2d8A3C5677189723C4cB8873CfC9C8976FDF38Ac",
      42161: "0x69FA688f1Dc47d4B5d8029D5a35FB7a548310654",
      421613: "0x2Fc0604AE02FA8AB833f135B0C01dFa45f88DAa2"
    }
  },
  FEATURED_POOLS_MANAGER: {
    address: {
      42161: "0x23Dc629A5A7BE30Cc8e41F2D4b714BE3274fdc57"
    }
  }
}

// check URL for an ethereum address using regex
export const checkQueryPool = (poolAddress: string) => {
  const regex = /0x[a-fA-F0-9]{40}/;
  const match = window.location.pathname.match(regex);
  if (match) {
    const queryAddress = match[0];
    return poolAddress === queryAddress;
  } else {
    return false;
  }
}

export const handleMulticallAddress = (chainId: number, multicallProvider: Provider) => {
  const networkData = getNetworkData(chainId);
  // @ts-ignore
  multicallProvider._multicallAddress = networkData?.multicallAddress;
}

// get network data based on chainId
export const getNetworkData = (chainId: number) => {
  const network = Object.values(networks).find((network) => 
    network.chainId === chainId
  )
  // return requested network if it exists
  if (network) return network;
  // else return arbitrum as default
  else return networks.Arbitrum;
}

// get network data based on chain name 
export const getNetworkDataByName = (networkName: string) => {
  const network = Object.values(networks).find((network) => 
    network.chainName.toLowerCase() === networkName.toLowerCase()
  )
  // return requested network if it exists
  if (network) return network;
  // else return arbitrum as default
  else return networks.Arbitrum;
}

export const encodeFeeRates = (
  rateType: number, 
  startRate: number, 
  endRate: number, 
  auctionStartDate: number, 
  auctionEndDate: number
): string => {
  let startRateComponent = ((startRate).toString(16)).padStart(12, "0");
  let endRateComponent = ((endRate).toString(16)).padStart(12, "0");
  let startDateComponent = ((auctionStartDate).toString(16)).padStart(12, "0");
  let endDateComponent = ((auctionEndDate).toString(16)).padStart(12, "0");
  let freeSpace = "0".repeat(14);
  if (rateType < 0 || rateType > 2) throw new Error("Invalid fee type on encoding");
  let feeTypeComponent = (rateType).toString().padStart(2, "0");
  return `0x${feeTypeComponent}${freeSpace}${startDateComponent}${endDateComponent}${startRateComponent}${endRateComponent}`;
}

export const networks = {
  Arbitrum: {
    chainId: Arbitrum.chainId,
    chainName: "Arbitrum",
    isTestChain: false,
    isLocalChain: false,
    isLive: true,
    multicallAddress:  "0xB064Fe785d8131653eE12f3581F9A55F6D6E1ca3",
    v1GraphUrl: `https://gateway-arbitrum.network.thegraph.com/api/${process.env.REACT_APP_V1_GRAPH_KEY}/subgraphs/id/CycasGMVHZbqdgBUCWscBq2bAYZRASquemRavtTgAzya`,
    graphUrl: `https://gateway-arbitrum.network.thegraph.com/api/${process.env.REACT_APP_GRAPH_KEY}/subgraphs/id/QMC5tfVVQKLUR7nnhfKLJhqLc61K7wa62gysKEG4UFR`,
    fallbackCacheKey: "v2Arb_Pools",
    rpcUrl: "https://thrilling-virulent-flower.arbitrum-mainnet.quiknode.pro/a5cb0ee9381141f1363d26b2cdddcbaa81b5ad4d/",
    getExplorerAddressLink: (address: string) => `https://arbiscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://arbiscan.io/tx/${transactionHash}`,
  },
  Base: {
    chainId: 8453,
    chainName: "Base",
    isTestChain: false,
    isLocalChain: false,
    isLive: false,
    multicallAddress:  "0xcA11bde05977b3631167028862bE2a173976CA11",
    v1GraphUrl: `https://gateway-arbitrum.network.thegraph.com/api/${process.env.REACT_APP_V1_GRAPH_KEY}/subgraphs/id/CycasGMVHZbqdgBUCWscBq2bAYZRASquemRavtTgAzya`,
    graphUrl: `https://api.studio.thegraph.com/query/48389/vendor-finance-base-v2/version/latest`,
    fallbackCacheKey: "v2Arb_Pools",
    rpcUrl: "https://1rpc.io/base",
    getExplorerAddressLink: (address: string) => `https://basescan.org/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://basescan.org/tx/${transactionHash}`,
  },
  Mainnet: {
    chainId: Mainnet.chainId,
    chainName: "Ethereum",
    isTestChain: false,
    isLocalChain: false,
    isLive: true,
    multicallAddress:  "0xeefba1e63905ef1d7acba5a8513c70307c1ce441",
    graphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendor-finance-v2-mainnet",
    fallbackCacheKey: "v2Eth_Pools",
    fallbackGraphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendor-finance-v2-mainnet",
    rpcUrl: "https://restless-quiet-brook.quiknode.pro/66364d93f180612e202a140d37d33944d3620845/",
    getExplorerAddressLink: (address: string) => `https://etherscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://etherscan.io/tx/${transactionHash}`,
  },
  MantleTestnet: {
    chainId: 5001,
    chainName: "Mantle Testnet",
    isTestChain: false,
    isLocalChain: false,
    isLive: true,
    multicallAddress:  "0x33fF9B03111fb1E8e025Cf92Ae94256fa03A9329",
    // v1GraphUrl: `https://gateway-arbitrum.network.thegraph.com/api/${process.env.REACT_APP_V1_GRAPH_KEY}/subgraphs/id/CycasGMVHZbqdgBUCWscBq2bAYZRASquemRavtTgAzya`,
    graphUrl: `https://graph.testnet.mantle.xyz/subgraphs/name/vendor-finance-mantle-testnet-v2`,
    fallbackCacheKey: "v2Arb_Pools",
    rpcUrl: "https://rpc.testnet.mantle.xyz",
    getExplorerAddressLink: (address: string) => `https://explorer.testnet.mantle.xyz/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://explorer.testnet.mantle.xyz/tx/${transactionHash}`,
  },
  ArbitrumGoerli: {
    chainId: 421613,
    chainName: "AGoerli",
    multiVersionSupport: true,
    isTestChain: true,
    isLocalChain: false,
    isLive: false,
    multicallAddress:  "0x21cd0D37ca11DA6C4035C4727CD091C0dd9F800F",
    graphUrl: "https://api.thegraph.com/subgraphs/name/forrestchew/graph-v2-v3",
    fallbackCacheKey: "v2Goerli_Pools",
    v1GraphUrl: "https://api.thegraph.com/subgraphs/name/0xtaiga/vendor-finance-arbitrum-goerli",
    rpcUrl: "https://goerli-rollup.arbitrum.io/rpc",
    getExplorerAddressLink: (address: string) => `https://goerli.arbiscan.io/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) => `https://goerli.arbiscan.io/tx/${transactionHash}`,
  }
}

export const hasOracle = (address: string) => {
  return [
    // mainnet
    // WETH
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    // USDC
    "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    // DAI
    "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    // LINK
    "0x514910771AF9Ca656af840dff83E8264EcF986CA",
    // AAVE
    "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
    // UNI
    "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    // CVX
    "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
    // WSTETH
    "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
    // FXS
    "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
    // LDO
    "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
    // APE
    "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
    // CBETH
    "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704",
    // YFI
    "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
    // WBTC
    "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    // CRV
    "0xD533a949740bb3306d119CC777fa900bA034cd52",
    // USDT
    "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    
    // base
    // WETH 
    "0x4200000000000000000000000000000000000006",
    // DAI 
    "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
    // cbETH
    "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22",

    // arbitrum
    // WETH
    "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    // ARB 
    "0x912CE59144191C1204E64559FE8253a0e49E6548",
    // USDC.e 
    "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    // MIM 
    "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",
    // FRAX
    "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F",
    // OHM 
    "0xf0cb2dc0db5e6c66B9a70Ac27B06b878da017028",
    // GOHM 
    "0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1",
    // MAGIC 
    "0x539bdE0d7Dbd336b79148AA742883198BBF60342",
    // RETH
    "0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8",
    // GMX 
    "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    // WBTC 
    "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
    // CRV 
    "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978",
    // SPELL 
    "0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF",
    // DAI 
    "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
    // USDT 
    "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    // MIM
    "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A",

    // aGoerli
    // USDC (421613)
    "0x72A9c57cD5E2Ff20450e409cF6A542f1E6c710fc",
    // USDT (421613)
    "0x8F30ec9Fb348513494cCC1710528E744Efa71003",
    // WBTC (421613)
    "0x8F30ec9Fb348513494cCC1710528E744Efa71003"
  ].map((address) => address.toLowerCase())
  .includes(address.toLowerCase());
}

export const tokenData = {
  WETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "WETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 8453, 42161, 421613],
    hasOracle: true,
    id: "weth",
    address: {
      1: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      5: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      42: "0xd0A1E359811322d97991E03f863a0C30C2cF029C",
      137: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      8453: "0x4200000000000000000000000000000000000006",
      42161: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
    }
  },
  BONSAI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "BONSAI",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "bonsai",
    address: {
      42161: "0x79EaD7a012D97eD8DeEcE279f9bC39e264d7Eef9"
    }   
  },
  GMUSDC: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "gmUSDC",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    pairAddress: "0xa0168c3ee7058f992f9bc0ecd356129e75304086",
    address: {
      42161: "0x5f851F67D24419982EcD7b7765deFD64fBb50a97"
    } 
  },
  ZRO: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "ZRO",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "layerzero",
    address: {
      42161: "0x6985884C4392D348587B19cb9eAAf157F13271cd"
    }
  },
  WMNT: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "wMNT",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [5001],
    hasOracle: false,
    id: "wrapped-mantle",
    address: {
      5001: "0x8734110e5e1dcF439c7F549db740E546fea82d66"
    }   
  },
  FMDUC: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "FMDUC",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [5001],
    hasOracle: false,
    usesFirebase: true,
    address: {
      5001: "0x86E192d5cB858Cf1D6B70773A222296169246144"
    }   
  },
  // FSX: {
  //   displayDecimals: 4,
  //   tokenDecimals: 18,
  //   symbol: "FSX",
  //   historicalPriceNetwork: [0, "none"],
  //   supportedNetworks: [5001],
  //   hasOracle: false,
  //   address: {
  //     5001: "0x6dFB16bc471982f19DB32DEE9b6Fb40Db4503cBF"
  //   }
  // },
  ARB: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "ARB",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "arbitrum",
    address: {
      42161: "0x912CE59144191C1204E64559FE8253a0e49E6548"
    }
  },
  GRAI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "GRAI",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: false,
    pairAddress: "0x57cae627f2ccc7414b91de8c188d479e656c3c2e",
    address: {
      // 42161: "0x894134a25a5faC1c2C26F1d8fBf05111a3CB9487"
    }   
  },
  GDAI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "gDAI",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: false,
    pairAddress: "0xd353018ada2232dffa497129b6851329b1746ce6",
    address: {
      42161: "0xd85e038593d7a098614721eae955ec2022b9b91b"
    }      
  },
  GNS: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "GNS",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: false,
    id: "gains-network",
    address: {
      42161: "0x18c11FD286C5EC11c3b683Caa813B77f5163A122"
    }   
  },
  "PT-GDAI-28MAR2024": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-GDAI-28MAR2024",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0x1684B747cd46858aE6312A7074353D2101154eF7"
    }   
  },
  "PT-GLP-28MAR2024": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-GLP-28MAR2024",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0x96015D0Fb97139567a9ba675951816a0Bb719E3c"
    }   
  },
  "PT-STARGATE USDT-27JUN2024": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-STARGATE USDT-27JUN2024",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    category: "LP",
    usesFirebase: true,
    address: {
      42161: "0x7D180a4f451FC15B543B5d1Ba7dDa6b3014A4c49"
    }   
  },
  "PT-RETH-26JUN2025": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-RETH-26JUN2025",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    category: "LP",
    usesFirebase: true,
    address: {
      42161: "0x685155D3BD593508Fe32Be39729810A591ED9c87"
    }   
  },
  "PT-WSTETH-26JUN2025": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-WSTETH-26JUN2025",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    category: "LP",
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0x1255638EFeca62e12E344E0b6B22ea853eC6e2c7"
    }   
  },
  "PT-WSTETH-28DEC2023": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-WSTETH-28DEC2023",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    category: "LP",
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0xBB33e51bdC598D710FF59FdF523E80ab7c882C83"
    }   
  },
  "PT-WSTETH-27JUN2024": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PT-WSTETH-27JUN2024",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    category: "LP",
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0x9741CAc1a22Ff3615FA074fD0B439975a5E137e9"
    }   
  },
  glpUSDCb: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "glpUSDCb",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0xdCa4e88C00a8800ebcebaD63aBDBAAaa755557f9"
    }   
  },
  glpWETHb: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "glpWETHb",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0xf2aD33E12A9780f1E42d878A29A3e0756008c838"
    }   
  },
  glpWBTCb: {
    displayDecimals: 4,
    tokenDecimals: 8,
    symbol: "glpWBTCb",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0x83C19EC75d649aeC7c99e2C6663cA055569da7C0"
    }
  },
  glpLINKb: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "glpLINKb",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0xB0d9e1832BD973aBd8f3b4D710eAd21FcbEfcb7C"
    }
  },
  glpUNIb: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "glpUNIb",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0xEE57E7E3776e4868976F315E07A883955c9225d5"
    }
  },
  glpUSDC: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "glpUSDC",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0x727eD4eF04bB2a96Ec77e44C1a91dbB01B605e42"
    }
  },
  glpWETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "glpWETH",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    address: {
      42161: "0xbb84D79159D6bBE1DE148Dc82640CaA677e06126"
    }
  },
  glpWBTC: {
    displayDecimals: 4,
    tokenDecimals: 8,
    symbol: "glpWBTC",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0x6a89FaF99587a12E6bB0351F2fA9006c6Cd12257"
    }
  },
  glpLINK: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "glpLINK",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0xe0A21a475f8DA0ee7FA5af8C1809D8AC5257607d"
    }
  },
  glpUNI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "glpUNI",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    hasOracle: false,
    usesFirebase: true,
    address: {
      // 42161: "0x37c0705A65948EA5e0Ae1aDd13552BCaD7711A23"
    }
  },
  RLB: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "RLB",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: false,
    id: "rollbit-coin",
    address: {
      1: "0x046EeE2cc3188071C02BfC1745A6b17c656e3f3d"
    }
  },
  COW: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "COW",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1],
    hasOracle: false,
    id: "cow-protocol",
    address: {
      1: "0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB"
    }
  },
  PLSARB: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PLSARB",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "plutus-arb",
    address: {
      42161: "0x7a5D193fE4ED9098F7EAdC99797087C96b002907"
    }
  },
  CMUMAMI: {
    displayDecimals: 4,
    tokenDecimals: 9,
    symbol: "cmUMAMI",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "compounded-marinated-umami",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0x1922C36F3bc762Ca300b4a46bB2102F84B1684aB"
    }
  },
  AGEUR: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "AGEUR",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "ageur",
    category: "stablecoin",
    address: {
      42161: "0xfa5ed56a203466cbbc2430a43c66b9d8723528e7"
    }
  },
  DMT: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "DMT",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    pairAddress: "0x410c879c62f22794bD5eE98e2EE01490F6d47A6b",
    address: {
      42161: "0x8B0E6f19Ee57089F7649A455D89D7bC6314D04e8"
    }
  },
  ALP: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "ALP",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    pairAddress: "0xb0a4c5237c905f07118a87ffef40ba58266104f6",
    // id: "arbitrove-alp"
    address: {
      42161: "0xb49B6A3Fd1F4bB510Ef776de7A88A9e65904478A"
    }
  },
  "MIM3CRV-f": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "MIM3CRV-f",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    isLpToken: true,
    category: "LP",
    address: {
      42161: "0x30dF229cefa463e991e29D42DB0bae2e122B2AC7"
    }
  },
  "WETH-SUSHI-LP": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "WETH-SUSHI",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    category: "LP",
    isLpToken: true,
    address: {
      42161: "0x3221022e37029923aCe4235D812273C5A42C322d"
    }
  },
  "WETH-MIM-LP": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "WETH-MIM",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [42161],
    hasOracle: false,
    usesFirebase: true,
    category: "LP",
    isLpToken: true,
    address: {
      42161: "0xb6DD51D5425861C808Fd60827Ab6CFBfFE604959"
    }
  },
  "AURABB-A-USD": {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "AURABB-A-USD",
    historicalPriceNetwork: [0, "none"],
    supportedNetworks: [],
    usesFirebase: true,
    hasOracle: false,
    isLpToken: true,
    category: "LP",
    address: {
      42161: "0x4fa10a40407ba386e3a863381200b4e6049950fa",
    }
  },
  RAM: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "RAM",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    pairAddress: "0x688547381eEC7C1d3d9eBa778fE275D1D7e03946",
    address: {
      42161: "0xAAA6C1E32C55A7Bfa8066A6FAE9b42650F262418"
    }
  },
  neadRAM: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "neadRAM",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    pairAddress: "0x96237035C53b060C403bB0700Da44Ba60D66Ff39",
    address: {
      42161: "0x40301951Af3f80b8C1744ca77E55111dd3c1dba1"
    }
  },
  OHM: {
    displayDecimals: 4,
    tokenDecimals: 9,
    symbol: "OHM",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: true,
    pairAddress: "0xf5d6509a0869A24A8568A65140F16197d19EE104",
    address: {
      42161: "0xf0cb2dc0db5e6c66B9a70Ac27B06b878da017028"
    }
  },
  XGRAIL: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "xGRAIL",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161, 421613],
    hasOracle: false,
    id: "camelot-token",
    address: {
      42161: "0x3CAaE25Ee616f2C8E13C74dA0813402eae3F496b",
      421613: "0x45bd859A0533d29B4D55a860bd70A332AE810dEE"
    }
  },
  // PLVGLP: {
  //   displayDecimals: 4,
  //   tokenDecimals: 18,
  //   symbol: "plvGLP",
  //   historicalPriceNetwork: [0, "none"],
  //   supportedNetworks: [42161],
  //   usesFirebase: true,
  //   hasOracle: false,
  //   address: {
  //     1: "",
  //     5: "",
  //     42: "",
  //     42161: "0x5326e71ff593ecc2cf7acae5fe57582d6e74cff1"
  //   }
  // },
  GOHM: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "gOHM",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: true,
    id: "governance-ohm",
    overrideFilter: true,
    address: {
      1: "",
      42161: "0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1"
    }
  },
  PENDLE: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "PENDLE",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "pendle",
    address: {
      42161: "0x0c880f6761F1af8d9Aa9C466984b80DAb9a8c9e8"
    }
  },
  JUSDC: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "jUSDC",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "jones-usdc",
    overrideFilter: true,
    address: {
      42161: "0xe66998533a1992ecE9eA99cDf47686F4fc8458E0"
    }
  },
  JGLP: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "jGLP",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "jones-glp",
    overrideFilter: true,
    address: {
      42161: "0x7241bC8035b65865156DDb5EdEf3eB32874a3AF6"
    }
  },
  magicGLP: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "magicGLP",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: true,
    hasOracle: false,
    overrideFilter: true,
    address: {
      42161: "0x85667409a723684Fe1e57Dd1ABDe8D88C2f54214"
    }
  },
  UMAMI: {
    displayDecimals: 4,
    tokenDecimals: 9,
    symbol: "UMAMI",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "umami-finance",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0x1622bF67e6e5747b81866fE0b85178a93C7F86e3"
    }
  },
  MAGIC: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "MAGIC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "magic",
    address: {
      1: "0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A",
      5: "",
      42: "",
      42161: "0x539bdE0d7Dbd336b79148AA742883198BBF60342"
    }
  },
  Y2K: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "Y2K",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "y2k",
    address: {
      42161: "0x65c936f008BC34fE819bce9Fa5afD9dc2d49977f"
    }
  },
  /*
  RDPX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "RDPX",
    historicalPriceNetwork: [1, "ethereum"],
    hasOracle: true,
    address: {
      1: "0xeec2be5c91ae7f8a338e1e5f3b5de49d07afdc81",
      5: "",
      42: "",
      42161: "0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55"
    }
  },
  */
  RETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "RETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "reth",
    address: {
      1: "0xae78736Cd615f374D3085123A210448E74Fc6393",
      5: "",
      42: "",
      42161: "0xEC70Dcb4A1EFa46b8F2D97C310C9c4790ba5ffA8"
    }
  },
  XCAL: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "XCAL",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    usesFirebase: false,
    hasOracle: false,
    id: "3xcalibur",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0xd2568acCD10A4C98e87c44E9920360031ad89fCB"
    }
  },
  GMX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "GMX",
    historicalPriceNetwork: [42161, "arbitrum-one"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "gmx",
    address: {
      1: "",
      5: "",
      42: "",
      42161: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a"
    }
  },
  DPX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "DPX",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: false,
    id: "dopex",
    address: {
      1: "0xEec2bE5c91ae7f8a338e1e5f3b5DE49d07AfdC81",
      5: "",
      42: "",
      42161: "0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55"
    }
  },
  AAVE: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "AAVE",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "aave",
    address: {
      1: "0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9",
    }   
  },
  UNI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "UNI",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "uniswap",
    address: {
      1: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
    }   
  },
  CVX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "CVX",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "convex-finance",
    address: {
      1: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
    }   
  },
  WSTETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "WSTETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "wrapped-steth",
    address: {
      1: "0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0",
    }
  },
  FXS: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "FXS",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "frax-share",
    address: {
      1: "0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0",
    }
  },
  LDO: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "LDO",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "lido-dao",
    address: {
      1: "0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32",
    }
  },
  APE: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "APE",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "apecoin",
    address: {
      1: "0x4d224452801ACEd8B2F0aebE155379bb5D594381",
    }
  },
  CBETH: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "CBETH",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [8453],
    hasOracle: true,
    id: "coinbase-wrapped-staked-eth",
    address: {
      1: "0xBe9895146f7AF43049ca1c1AE358B0541Ea49704",
      8453: "0x2Ae3F1Ec7F1F5012CFEab0185bfc7aa3cf0DEc22"
    }
  },
  YFI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "YFI",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "yearn-finance",
    address: {
      1: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e",
    }
  },
  WBTC: {
    displayDecimals: 4,
    tokenDecimals: 8,
    symbol: "WBTC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161, 421613],
    hasOracle: true,
    id: "wrapped-bitcoin",
    address: {
      1: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      5: "0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05",
      42: "0xd3a691c852cdb01e281545a27064741f0b7f6825",
      137: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      42161: "0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f",
      421613: "0x2Df743730160059c50c6bA9E87b30876FA6Db720"
    }
  },
  CRV: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "CRV",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "curve-dao-token",
    address: {
      1: "0xD533a949740bb3306d119CC777fa900bA034cd52",
      5: "",
      42: "",
      42161: "0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978"
    }
  },
  SPELL: {
    displayDecimals: 5,
    tokenDecimals: 18,
    symbol: "SPELL",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "spell-token",
    address: {
      1: "0x090185f2135308BaD17527004364eBcC2D37e5F6",
      42161: "0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF"
    }
  },
  DAI: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "DAI",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 5001, 8453, 42161],
    hasOracle: true,
    id: "dai",
    category: "stablecoin",
    address: {
      1: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      42: "0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa",
      137: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      5001: "0xB38E748dbCe79849b8298A1D206C8374EFc16DA7",
      8453: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
      42161: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1"
    }
  },
  LINK: {
    displayDecimals: 3,
    tokenDecimals: 18,
    symbol: "LINK",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "chainlink",
    address: {
      1: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      5: "0x326C977E6efc84E512bB9C30f76E30c160eD06FB",
      42161: ""
    }
  },
  COMP: {
    displayDecimals: 3,
    tokenDecimals: 18,
    symbol: "COMP",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [],
    hasOracle: true,
    id: "compound-governance-token",
    address: {
      1: "0xc00e94Cb662C3520282E6f5717214004A7f26888",
      42: "0x61460874a7196d6a22D1eE4922473664b3E95270",
      42161: ""
    }
  },
  STG: {
    displayDecimals: 3,
    tokenDecimals: 18,
    symbol: "STG",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [8453],
    hasOracle: false,
    id: "stargate-finance",
    address: {
      8453: "0xE3B53AF74a4BF62Ae5511055290838050bf764Df"
    }
  },
  USDT: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "USDT",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 5001, 42161, 421613],
    hasOracle: true,
    id: "tether",
    category: "stablecoin",
    address: {
      1: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      42: "0x07de306FF27a2B630B1141956844eB1552B956B5",
      137: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      5001: "0xa9b72cCC9968aFeC98A96239B5AA48d828e8D827",
      42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
      421613: "0x8F30ec9Fb348513494cCC1710528E744Efa71003"
    }
  },
  USDBC: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "USDbC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [8453],
    hasOracle: false,
    id: "usd-coin",
    category: "stablecoin",
    address: {
      8453: "0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA"
    }
  },
  USDC: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "USDC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [1, 42161, 421613],
    hasOracle: true,
    id: "usd-coin",
    category: "stablecoin",
    address: {
      1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      42: "0xb7a4F3E9097C08dA09517b5aB877F7a917224ede",
      137: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      42161: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      421613: "0x72A9c57cD5E2Ff20450e409cF6A542f1E6c710fc"
    }
  },
  MUSDC: {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "mUSDC",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [5001],
    hasOracle: false,
    id: "usd-coin",
    category: "stablecoin",
    address: {
      5001: "0xc92747b1e4Bd5F89BBB66bAE657268a5F4c4850C",
    }
  },
  // eslint-disable-next-line
  "USDC.e": {
    displayDecimals: 4,
    tokenDecimals: 6,
    symbol: "USDC.e",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "usd-coin",
    category: "stablecoin",
    address: {
      42161: "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
    }
  },
  MIM: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "MIM",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [8453, 42161],
    hasOracle: true,
    id: "magic-internet-money",
    category: "stablecoin",
    address: {
      1: "0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3",
      5: "",
      42: "",
      8453: "0x4A3A6Dd60A34bB2Aba60D73B4C88315E9CeB6A3D",
      42161: "0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A"
    }
  },
  FRAX: {
    displayDecimals: 4,
    tokenDecimals: 18,
    symbol: "FRAX",
    historicalPriceNetwork: [1, "ethereum"],
    supportedNetworks: [42161],
    hasOracle: true,
    id: "frax",
    category: "stablecoin",
    address: {
      1: "0x853d955aCEf822Db058eb8505911ED77F175b99e",
      5: "",
      42: "",
      42161: "0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F"
    }
  },
}

export const strategies = {
  "No Strategy": {
    name: "No Strategy",
    description: "Normal lending pool",
    docs: "https://vendorfinance.gitbook.io/vendor-finance-developer-documentation/contracts/strategies",
    supportedTokens: {}
  },
  "AAVE": {
    name: "AAVE",
    description: "Unborrowed lender funds will be deployed into an AAVE pool allowing the lender to earn yield while their pool is inactive",
    docs: "https://vendorfinance.gitbook.io/vendor-finance-developer-documentation/contracts/strategies/vendor4626lendstrategy",
    supportedTokens: {
      [tokenData.USDC.address[1]]: {
        address: "0x3f53936e45B50e38C25d6e52dA294E66B8909609",
        key: "0x1010000000000000000000003f53936e45B50e38C25d6e52dA294E66B8909609"
      },
      [tokenData.USDC.address[421613]]: {
        address: "0x527851DCF13E2C640CB7c6E725E74F6D1B2Ce1e0",
        key: "0x101000000000000000000000527851DCF13E2C640CB7c6E725E74F6D1B2Ce1e0"
      },
      [tokenData.USDT.address[421613]]: {
        address: "0x3ec4BFE44E46BAF3a7af428f9BcB3BD453f9127f",
        key: "0x1010000000000000000000003ec4BFE44E46BAF3a7af428f9BcB3BD453f9127f"
      },
      [tokenData["USDC.e"].address[42161]]: {
        address: "0x88aD0E7fFF0CbC9Be23f72058db29c7E85A87717",
        key: "0x10100000000000000000000088aD0E7fFF0CbC9Be23f72058db29c7E85A87717"
      },
      [tokenData.USDBC.address[8453]]: {
        address: "0x0b8f69D521442c667ec01e137569eb00deB54Ce5",
        key: "0x1010000000000000000000000b8f69D521442c667ec01e137569eb00deB54Ce5"
      },
      [tokenData.WETH.address[42161]]: {
        address: "0xaaACc763FeC71c31fFF2552023F8C0b66acce59f",
        key: "0x101000000000000000000000aaACc763FeC71c31fFF2552023F8C0b66acce59f"
      }
    },
  },
}

// Arbitrum
export const defaultChain:number = networks.Arbitrum.chainId;
// Goerli
export const devChain:number = 5;