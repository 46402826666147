import moment from "moment";
import { Fragment, useContext, useEffect, useState } from "react";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, POOL_DATA, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { fetchLocalTokenData, fetchTokenLogo, renderLazyLoad } from "../../utils/Utils";
import "./PotentialRollover.css";

const PotentialRollover = (props: {
  pool: POOL_DATA
  setSelectedRollover: (val: POOL_DATA) => void
}) => {

  const [termRate, setTermRate] = useState<string>();
  const [ltv, setLtv] = useState<string>();
  const { chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { fullPoolData, calculateLTV } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  useEffect(() => {
    if (fullPoolData[props.pool.id]) {
      const poolData:POOL_DATA = fullPoolData[props.pool.id];
      setLtv(calculateLTV(poolData).toFixed(2));
      setTermRate((Number(poolData.currentFeeRate) / 10000).toFixed(2));
    }
  // eslint-disable-next-line
  }, [fullPoolData]);

  const renderPotentialRollover = () => {
    const lendTokenData = fetchLocalTokenData(props.pool.lendToken, chainId);
    const colTokenData = fetchLocalTokenData(props.pool.colToken, chainId);
    const formattedExpiry = moment(new Date(Number(props.pool.expiry) * 1000)).format("MMM D, Y");
    return (
      <div 
        className="potential-rollover"
        onClick={() => props.setSelectedRollover(props.pool)}
      >
        <div className="icon-pair align-left">
          <img
            className="token-logo col-icon"
            alt={`${colTokenData.symbol}`}
            src={fetchTokenLogo(colTokenData.symbol)}
          ></img>
          <img
            className="token-logo lend-icon"
            alt={`${lendTokenData.symbol}`}
            src={fetchTokenLogo(lendTokenData.symbol)}
          ></img>
        </div>
        <span>
          {formattedExpiry}
        </span>
        <span>
          {renderLazyLoad(ltv, 
            <Fragment>
              {ltv}% 
            </Fragment>  
          )}
        </span>
        <span>
          {renderLazyLoad(termRate, 
            <Fragment>
              {termRate}% 
            </Fragment>  
          )}
        </span>
      </div>
    )
  }

  return (renderPotentialRollover());
}

export default PotentialRollover;