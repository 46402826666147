import { useEffect, useState } from "react";
import { Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { computeRate, generateFakeRateData } from "../../utils/Utils";
import "./RateSimulation.css";

const RateSimulation = (props: {
  rateType: "Decay" | "Auction" | "Fixed",
  startDate: Date,
  endDate: Date,
  expiry: Date,
  startRate: number,
  endRate: number
}) => {

  const [rateData, setRateData] = useState<any[]>(generateFakeRateData());

  useEffect(() => {
    createRateData();
  // eslint-disable-next-line
  }, [props.startDate, props.endDate, props.rateType, props.startRate, props.endRate]);

  const createRateData = () => {
    let data:any[] = [{}];
    for (let i = new Date().getTime(); i < props.expiry.getTime(); i+=10000000) {
      const rate = computeRate(
        props.startDate, 
        props.endDate, 
        props.startRate, 
        props.endRate, 
        props.expiry, 
        i,
        props.rateType
      );
      data.push({
        name: i,
        "Term Rate": rate
      });
    }
    setRateData(data);
  }

  const formatDate = (timestamp: number) => {
    if (!timestamp) return "";
    let str = new Date(timestamp).toString();
    const splitStr = str.split(" ");
    str = `${splitStr[1]} ${splitStr[2]}`
    return str;
  }

  const getYAxisDomain = () => {
    // calculate the start rate
    const startRate = computeRate(
      props.startDate,
      props.endDate,
      props.startRate,
      props.endRate,
      props.expiry,
      new Date().getTime(),
      props.rateType
    );
    if (props.rateType === "Fixed" || props.rateType === "Auction") {
      return ([0, 50]);
    } else {
      return ([Math.max(0,startRate - 0.5), startRate + 0.5])
    }
  }

  const CustomTooltip = (data:any) => {
    if (data && data.active && data.payload && data.payload.length) {
      try {
        const timestamp = data.payload[0].payload.name;
        const rate = data.payload[0].payload["Term Rate"];
        return (
          <div className="graph-tooltip">
            <p className="label">{new Date(timestamp).toLocaleString()}</p>
            <p className="label">Term Rate: {rate.toFixed(3)}%</p>
          </div>
        );
      } catch (e) {
        return (
          <div className="graph-tooltip">
            <p className="label">LOADING</p>
          </div>
        );
      }
    }
  
    return null;
  };

  const renderChart = () => {

    // gradient colors for price graph area
    const gradientColors = () => {
      return (
       <linearGradient id="colorView" x1="0" y1="0" x2="1" y2="0">
         <stop offset="30%" stopColor="var(--cta-blue)" stopOpacity={0.08} />
         <stop offset="95%" stopColor="var(--lavender-rose)" stopOpacity={0.08} />
       </linearGradient>
      );
    };

    // gradient colors for price graph area
    const dashed = () => {
      const colors = [...new Array(100)].map((_, index) => 
        <stop 
          offset={`${index}%`} 
          stopColor={`${index % 5 === 0 ? "white" : "black"}`}
          stopOpacity={`${index % 5 === 0 ? 0 : 1}`}
        />
      );
      return (
       <linearGradient id="dashed" x1="0" y1="0" x2="0" y2="1">
          {colors}
       </linearGradient>
      );
    };
    return (
      <ResponsiveContainer width="100%" height="95%">
        <ComposedChart data={rateData}>
          <defs>
            {gradientColors()}
            {dashed()}
          </defs>
          <CartesianGrid
            stroke="hsla(220, 22%, 96%, 1)"
            vertical={false}
          />
          <XAxis 
            dataKey="name"
            scale="auto" 
            tickLine={false}
            minTickGap={10}
            tickFormatter={(tick: any) => formatDate(tick)}
            axisLine={{ stroke: "var(--input)" }}
            tickCount={20}
            stroke="var(--border-color)" 
            domain={[rateData[0].name, rateData[rateData.length - 1].name]}
            type="number"
          />
          <Area
            type="natural"
            dataKey="Term Rate"
            fill="url(#colorView)"
            strokeWidth={2}
            stroke="#4753d8"
            yAxisId={1}
            dot={false}
            animationDuration={500}
          />
          <Legend
            verticalAlign="top"
            height={0}
            iconType="circle"
            iconSize={10}
          />
          <YAxis
            type="number"
            yAxisId={1}
            tickLine={true}
            tickFormatter={(tick: number) => tick.toFixed(2)}
            tick={true}
            strokeWidth={0}
            domain={getYAxisDomain()}
            width={35}
          />
          <Tooltip content={<CustomTooltip />} />
        </ComposedChart>
      </ResponsiveContainer>
    )
  }

  return (
    <div className="rate-simulation-wrapper simulation-wrapper">
      <div className="rate-simulation-title simulation-title">
        <span>Term rate Behavior</span>
      </div>
      {renderChart()}
    </div>
  );
}

export default RateSimulation;