// eslint-disable-next-line
export default [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_NativeAddress",
        "type": "address"
      },
      {
        "internalType": "address[]",
        "name": "_tokensUSD",
        "type": "address[]"
      },
      {
        "internalType": "address[]",
        "name": "_feedsUSD",
        "type": "address[]"
      },
      {
        "internalType": "address[]",
        "name": "_tokensNATIVE",
        "type": "address[]"
      },
      {
        "internalType": "address[]",
        "name": "_feedsNATIVE",
        "type": "address[]"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [],
    "name": "BorrowingPaused",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ColTokenNotSupported",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "DebtIsLess",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "DifferentColToken",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "DifferentLendToken",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "DifferentPoolOwner",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "DiscountTooLarge",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FeeTooHigh",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FeeTooLarge",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "FeedAlreadySet",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "IllegalImplementation",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InsufficientBalance",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidDiscount",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidExpiry",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidParameters",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidTokenPair",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "InvalidType",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "LendTokenNotSupported",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "LicenseNotFound",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "MintRatio0",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NoDebt",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NoPermission",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotAPool",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotAuthorized",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotEnoughLiquidity",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotFactory",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotGranted",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotOwner",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "NotValidPrice",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "OperationsPaused",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "OracleNotSet",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PoolActive",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PoolClosed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "PrivatePool",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "RoundIncomplete",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "StaleAnswer",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "TransferFailed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "UpgradeNotAllowed",
    "type": "error"
  },
  {
    "inputs": [],
    "name": "ZeroAddress",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "previousOwner",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "OwnershipTransferred",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_feed",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "_isNative",
        "type": "bool"
      }
    ],
    "name": "addFeed",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "feedsNATIVE",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "name": "feedsUSD",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_base",
        "type": "address"
      }
    ],
    "name": "getPriceUSD",
    "outputs": [
      {
        "internalType": "int256",
        "name": "",
        "type": "int256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "renounceOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "newOwner",
        "type": "address"
      }
    ],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  }
]
