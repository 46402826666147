import React, { useContext } from "react";
import "./RepayRow.css";
import moment from "moment";
import { mobileBreakpoint, renderLazyLoad, truncateNumber } from "../../utils/Utils";
import { useScreenSize } from "../../utils/useScreenSize";
import { PoolDataContext } from "../../context/PoolDataContext";
import { POOL_DATA_CONTEXT } from "../../utils/Interfaces";
import { ethers } from "ethers";

const RepayRow = (props: {
  mintRatio: string,
  expiry: number,
  borrowAmount: number,
  colSymbol: string,
  lendSymbol: string,
  loading: boolean
}) => {
  const { screenWidth } = useScreenSize();
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  // format the expiry with moment
  const getDateFormatting = () => {
    const expiry = Number(selectedPool.expiry) * 1000;
    if (screenWidth > mobileBreakpoint) return moment(expiry).format("MMM D, Y hh:mm A");
    else return moment(expiry).format("MM/DD/YY hh:mm A");
  }
  return (
    <div className="repay-row">
      <div className="repay-lend-ratio repay-text">
        {Number(ethers.utils.formatEther(selectedPool.mintRatio))}
      </div>
      <div className="repay-expiry repay-text">
        {getDateFormatting()}
      </div>
      <div className="repay-amount-owed repay-text">
        {renderLazyLoad(!props.loading,
          truncateNumber(selectedPool.userDebt, 4)
        )}
      </div>
    </div>
  );
};

export default RepayRow;
