import { useEffect } from "react";
import "./Dropdown.css";

const Dropdown = (props: {
  setShowDropdown: (show: boolean) => void,
  showDropdown?: boolean,
  children: any
}) => {

  // eslint-disable-next-line
  const timestamp = new Date().getTime();

  useEffect(() => {
    window.addEventListener('click', onClick);
    return () => {
      window.removeEventListener('click', onClick);
    };
  // eslint-disable-next-line
  }, []);

  // check if click was outside of the component
  const onClick = (e: any) => {
    const wrapper = document.querySelector(`.dropdown-wrapper`);
    // don't close dropdown as it's being open
    // this statement checks the time between when the dropdown was opened
    // and when this method is being executed
    // it's kind of janky but it works
    if (new Date().getTime() - timestamp > 100) {
      if (props.showDropdown) {
        if (!wrapper?.contains(e.target)) {
          props.setShowDropdown(false);
        }
      }
    }
  }

  return(
    <div className={`dropdown-wrapper fade-in`}>
      {props.children}
    </div>
  );
}

export default Dropdown