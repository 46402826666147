import { Jazzicon } from "@ukstv/jazzicon-react";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { PoolDataContext } from "../../context/PoolDataContext";
import { APP_DATA_CONTEXT, POOL_DATA, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { getNetworkData, useDevFunctions } from "../../utils/Utils";
import { shortenAddress } from "@usedapp/core";
import "./PoolReactions.css"
import moment from "moment";
import { WalletDataContext } from "../../context/WalletDataContext";
import { Info, Trash2 } from "react-feather";
import { AppDataContext } from "../../context/AppDataContext";
import ReactTooltip from "react-tooltip";
import { useSnackbar } from "notistack";

interface POOL_REACTION {
  message: string,
  chainId: string,
  poolId: string,
  sender: string,
  timestamp: number,
  uid: string
}

const PoolReactions = (props: {
  setPoolReactionCount: (count: number) => void
}) => {

  // list of pool reactions
  const [poolReactions, setPoolReactions] = useState<POOL_REACTION[]>([]);
  // have the messages been loaded?
  const [messagesLoaded, setMessagesLoaded] = useState<boolean>(false);
  // currently selected pool used for checking if the pool has changed
  const selectedPoolRef = useRef<POOL_DATA>({id: ""} as POOL_DATA);
  // import contexts
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { chainId, provider, account } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedPool.id !== selectedPoolRef.current.id) {
      setMessagesLoaded(false);
      getPoolReactions();
    }
  // eslint-disable-next-line
  }, [selectedPool]);

  // fetch the pool reactions from the db 
  const getPoolReactions = async () => {
    try {
      // setup url
      const url = useDevFunctions
        ? new URL("http://127.0.0.1:5001/vendor-finace/us-central1/getPoolReactions")
        : new URL("https://us-central1-vendor-finace.cloudfunctions.net/getPoolReactions");
      url.searchParams.append("poolId", selectedPool.id);
      // make request
      const res = await axios.get(url.href);
      let reactions = [...res.data];
      // sort reactions by timestamp
      reactions.sort((a, b) => {
        return b.timestamp - a.timestamp;
      });
      // update state
      setPoolReactions(reactions);
      selectedPoolRef.current = selectedPool;
      // update the pool reaction count in the parent component
      props.setPoolReactionCount(reactions.length);
    } catch (e) {
      console.log(e); 
    }
    setMessagesLoaded(true);
  }

  // delete a pool reaction from the db
  const deletePoolReaction = async (reaction: POOL_REACTION) => {
    if (pending) return;
    // user can't make a request without a wallet
    if (!account || !provider) {
      enqueueSnackbar("Please connect your wallet to make a pool request");
      return;
    }
    setPending(true);
    try {
      enqueueSnackbar("Please sign the message in your wallet to continue");
      // request user signature
      const signer = provider.getSigner();
      const message = "Please sign this message to delete the pool reaction";
      const signedMessage = await signer.signMessage(message);
      // setup url
      const url = useDevFunctions
        ? new URL("http://127.0.0.1:5001/vendor-finace/us-central1/deletePoolReaction")
        : new URL("https://us-central1-vendor-finace.cloudfunctions.net/deletePoolReaction");
      url.searchParams.append("uid", reaction.uid);
      url.searchParams.append("signature", signedMessage);
      // make request
      await axios.get(url.href);
      // update the pool reactions
      getPoolReactions();
      enqueueSnackbar("Successfully deleted the pool reaction");
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to delete the pool reaction");
    }
    setPending(false);
  }

  // format the timestamp to a readable date
  const formatDate = (timestamp: number) => {
    const date = moment(timestamp);
    return `${date.format("MMM. D, YYYY hh:mma")}`
  }

  // get the block explorer address for an address
  const getAddressLink = (address: string) => {
    const networkData = getNetworkData(chainId)
    return(networkData.getExplorerAddressLink(address));
  }

  // render all of the pool reactions
  const renderPoolReactions = () => {
    return poolReactions.map((reaction, index) => {
      return (
        <div key={index} className="pool-reaction-container">
          <div className="pool-reaction-left">
            <Jazzicon
              address={reaction.sender}
              className="pool-reaction-jazzicon"
            />
            <ReactTooltip 
              id="delete-reaction-tip" 
              type="info" 
              className="tool-tip"
            >
              <span>Delete Reaction</span>
            </ReactTooltip>
            <div 
              className="pool-reaction-delete-button"
              data-tip 
              data-for="delete-reaction-tip"
              onClick={() => deletePoolReaction(reaction)}
            >
              <Trash2 />
            </div>
          </div>
          <div className="pool-reaction-right">
            <span className="pool-reaction-message">
              {reaction.message}
            </span>
            <div className="pool-reaction-metadata">
              <span>
                <a 
                  href={getAddressLink(reaction.sender)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {shortenAddress(reaction.sender)}
                </a>
              </span>
              <div className="reaction-metadata-separator"/>
              <span>{formatDate(reaction.timestamp)}</span>
            </div>
          </div>
        </div>
      )
    })
  }

  const messageRenderLogic = () => {
    if (messagesLoaded) {
      if (poolReactions.length === 0) {
        return (
          <div className="no-pool-reactions-container">
            <Info/>
            <span>No Pool Reactions</span>
          </div>
        )
      } else {
        return renderPoolReactions();
      }
    } else {
      return (
        <div className="no-pool-reactions-container">
          <Info/>
          <span>Loading Reactions...</span>
        </div>
      )
    }
  }

  return (
    <div className="pool-reactions-wrapper small-widget">
      <span className="widget-description">
        View messages from current and potential borrowers
      </span>
      <div className="pool-reactions-container">
        {messageRenderLogic()}
      </div>
    </div>
  )
}

export default PoolReactions;