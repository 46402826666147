import { ethers } from "ethers";
import { Slider } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { encodeFeeRates, getGasLimit, getTransactionUrl, supportedNetworks } from "../../utils/Utils";
import ActionButton from "../../components/ActionButton/ActionButton";
import LendingPoolABI from "../../abi/LendingPool.js";
import { useSnackbar } from "notistack";
import { useConnectWallet } from "@web3-onboard/react";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";

const UpdateFeeRate = (props: {
  updatePoolData: () => void,
}) => {

  const [selectedFeeRate, setSelectedFeeRate] = useState<number>(5);
  const { enqueueSnackbar } = useSnackbar();
  const [{ wallet }] = useConnectWallet();
  const { provider, chainId } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;
  const { setPending, pending } = useContext(AppDataContext) as APP_DATA_CONTEXT;

  const getFeeRate = async() => {
    if (!wallet || !selectedPool) return;
    // const poolContract = new ethers.Contract(
    //   props.selectedPool.id,
    //   LendingPoolABI,
    //   library.getSigner()
    // );
    // const disabledBorrowResp = await poolContract.disabledBorrow();
    const {
      rateType
    } = selectedPool;
    if (rateType === 1)
      // for fixed rate pools, the fee being displayed is the term rate
      setSelectedFeeRate(Number(selectedPool.currentFeeRate || 0) / 10000)
    else
      // for decaying rate pools, the fee being displayed is the annualized rate
      setSelectedFeeRate(Number(selectedPool.annualizedFeeRate || 0) / 10000)
  }

  useEffect(() => {
    getFeeRate();
  // eslint-disable-next-line
  }, [selectedPool]);

  const updateFeeRate = async() => {
    if (!supportedNetworks.includes(chainId) || !selectedPool || !provider) return;
    try {
      setPending(true);
      const {
        // endRate,
        auctionStartDate,
        auctionEndDate,
        rateType
      } = selectedPool;

      const newRates = encodeFeeRates(
        rateType,
        selectedFeeRate * 10000,
        selectedFeeRate * 10000,
        auctionStartDate,
        auctionEndDate
      )
      enqueueSnackbar("Please confirm the update rate transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true
      });
      const poolAddress = selectedPool.id;
      const poolContract = new ethers.Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner() 
      );
      const tx = await poolContract.setPoolRates(
        newRates,
        {
          ...getGasLimit(chainId, METHOD_TYPE.SET_FEES)
        }
      );
      await tx.wait();
      enqueueSnackbar("Update rate transaction successful ** " + getTransactionUrl(tx.hash, chainId), {
        persist: false,
        disableWindowBlurListener: true
      });
    } catch (e) {
      enqueueSnackbar("Update rate transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
    props.updatePoolData();
  }
  
  const renderSlider = () => {
    return (
      <div className="update-fee-rate-slider-wrapper">
        <Slider
          onChange={(e, val) => setSelectedFeeRate(Number(val))}
          min={0}
          max={100}
          step={1}
          value={selectedFeeRate}
        />
        <div className="update-fee-rate-slider-input-wrapper">
          <input
            className="update-fee-rate-slider-input"
            value={selectedFeeRate}
            onChange={(e) => setSelectedFeeRate(Number(e.target.value))}
            type="number"
          />
          <span className="percent">%</span>
        </div>
      </div>
    )
  }

  return (
    <div className="update-fee-rate-wrapper small-widget">
      <span className="widget-description">
        You can update the pool's fee rate. 
      </span>
      {renderSlider()}
      <div className="settings-tab-button-wrapper update-fee-rate">
        <ActionButton
          disabled={pending} 
          title={"Update"}
          action={updateFeeRate}
        />
      </div>
    </div>
  );
}

export default UpdateFeeRate;