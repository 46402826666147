import { shortenAddress } from "@usedapp/core";
import { useSnackbar } from "notistack";
import { useContext, useState } from "react";
import { HelpCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import PrivateBorrower from "../../../components/PrivateBorrower/PrivateBorrower";
import { CreatePoolContext } from "../../../context/CreatePoolContext";
import { CREATE_POOL_CONTEXT } from "../../../utils/Interfaces";
import { useScreenSize } from "../../../utils/useScreenSize";
import { mobileBreakpoint } from "../../../utils/Utils";
import ClearBorrowers from "../ClearBorrowers/ClearBorrowers";
import "./PrivateBorrowers.css";

const PrivateBorrowers = () => {

  const [privateBorrowersInput, setPrivateBorrowersInput] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();
  const { screenWidth } = useScreenSize();

  const { 
    privateBorrowers,
    setPrivateBorrowers
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;

  const managePrivateBorrowersInput = () => {
    const el = document.querySelector(".private-borrowers-input") as HTMLInputElement;
    if (!el) return;
    let text = el.value;
    // update the input text
    setPrivateBorrowersInput(text);

    // extract borrowers from input 
    let borrowers = text.split(",").map(e => String(e).trim()).filter(e => e.trim().length === 42);

    // update private borrowers list
    if (borrowers.length > 0) {
      let newText = text;
      let validBorrowers:string[] = [...privateBorrowers];
      let fails = 0;
      text.split(",").forEach((_borrower) => {
        const borrower:string = _borrower.trim();
        try {
          // attempt to shorten the address
          shortenAddress(borrower);
          // if possible, the address is valid (according to ethers)
          validBorrowers.push(borrower);
          // clean up input
          newText = newText.replace(`${borrower}`, "").trim();
          newText = newText.replace(`, `, "").trim();
        } catch {
          fails++;
        }
      });

      // notify user of any invalid addresses
      if (fails > 0) {
        enqueueSnackbar("One or more of the private borrower addresses is invalid.", {
          persist: false,
        });
      }
      setPrivateBorrowersInput(newText);
      //@ts-ignore
      setPrivateBorrowers([...new Set(validBorrowers)]);
    } 
  }

  const privateBorrowersInputChanged = () => {
    managePrivateBorrowersInput(); 
    verifyPrivateBorrowersInput();
  }

  const verifyPrivateBorrowersInput = () => {
    let borrowers = privateBorrowers;
    let preLength = borrowers.length;

    // are there any private borrowers?
    // if so, are the addresses valid?
    if (borrowers.length === 0) 
      return true;
    else
      // TODO: actually validate addresses 
      borrowers = borrowers.filter((e:string) => e.trim().length === 42)

    // list is verified if there are valid borrowers on the list
    return borrowers.length > 0 && borrowers.length === preLength;
  }

  const privateBorrowersWrapperClicked = () => {
    const el = document.querySelector(".private-borrowers-input") as HTMLInputElement;
    el.focus();
  }

  const renderPrivateBorrowersInput = () => {
    return (
      <div className="private-borrowers-wrapper">
        <ReactTooltip 
          id="private-pool-tip" 
          type="info" 
          className="tool-tip"
          place={screenWidth > mobileBreakpoint ? "top" : "right"}
        >
          <span>
            Comma separated list of addresses that will be allowed to borrow
            from this pool. Leave this blank if anyone is allowed to borrow from
            this pool
          </span>
        </ReactTooltip>
        <div className="info-title-container">
          <div
            className="info-title disable-select"
            data-tip
            data-for="private-pool-tip"
          >
            Borrowers <HelpCircle />
          </div>
          {privateBorrowers.length > 0 && (
            <ClearBorrowers
              setPrivateBorrowers={setPrivateBorrowers}
              setPrivateBorrowersInput={setPrivateBorrowersInput}
            />
          )}
        </div>
        <div
          className="private-borrowers-input-wrapper"
          onClick={() => privateBorrowersWrapperClicked()}
        >
          <input
            type="string"
            placeholder="Addresses Separated By Commas"
            className="private-borrowers-input"
            value={privateBorrowersInput}
            onChange={privateBorrowersInputChanged}
          />
          {privateBorrowers.map((borrower, index) => (
            <PrivateBorrower
              address={borrower}
              setPrivateBorrowers={setPrivateBorrowers}
              privateBorrowers={privateBorrowers}
              key={index}
            />
          ))}
        </div>
      </div>
    );
  }

  return (renderPrivateBorrowersInput())
}

export default PrivateBorrowers;