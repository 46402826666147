import { shortenAddress } from "@usedapp/core";
import { Fragment } from "react";
import { X } from "react-feather";
import ReactTooltip from "react-tooltip";
import "./PrivateBorrower.css";

const PrivateBorrower = (props: {
    address: string
    privateBorrowers: string[],
    key: number,
    setPrivateBorrowers: (borrowers: string[]) => void
}) => {

    const removeBorrower = () => {
        const newPrivateBorrowers = [...props.privateBorrowers];
        newPrivateBorrowers.splice(props.privateBorrowers.indexOf(props.address.trim()), 1); 
        props.setPrivateBorrowers(newPrivateBorrowers);
    }

    const formatAddress = () => {
        let addr;
        try {
            addr = shortenAddress(props.address.trim());
        } catch (e) {
           addr = ""; 
        }
        return addr;
    }

    return (
        <Fragment>
            <ReactTooltip id={`private-borrower-${props.address}-tip`} type="info" className="tool-tip">
                <span>{props.address}</span>
            </ReactTooltip>
            <div 
                className="private-borrower-wrapper"
                data-tip
                data-for={`private-borrower-${props.address}-tip`}
            >
                <span className="private-borrower-address">{formatAddress()}</span>
                <X className="private-borrower-remove" onClick={removeBorrower}/>
            </div>
        </Fragment>
    )
}

export default PrivateBorrower;