import React from "react";
// import { truncateNumber } from "../../utils/Utils";
import "./ValueDisplay.css";

const ValueDisplay = (props: { rate: number }) => {
  return (
    <div className="apr-container">
      <span className="monthly">{props.rate.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</span>
    </div>
  );
};

export default ValueDisplay;
