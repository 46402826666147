import axios from "axios";
import millify from "millify";
import moment from "moment";
import { useSnackbar } from "notistack";
import { Fragment, useContext, useEffect, useState } from "react";
import { XCircle } from "react-feather";
import ReactTooltip from "react-tooltip";
import { AppDataContext } from "../../context/AppDataContext";
import { CreatePoolContext } from "../../context/CreatePoolContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, CREATE_POOL_CONTEXT, CREATE_POOL_PAGES_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { useScreenSize } from "../../utils/useScreenSize";
import { computeAnnualizedRate, computeRate, fetchLocalTokenData, fetchTokenLogo, getCurrentPage, mobileBreakpoint, useDevFunctions } from "../../utils/Utils";
import { useNavigate } from "react-router-dom";
import "./PoolRequestsContainer.css"
import { CreatePoolPagesContext } from "../../context/CreatePoolPagesContext";

const PoolRequestRow = (props: {
  poolRequest: any,
  selectedPoolRequest: any,
  setSelectedPoolRequest: any,
  poolRequests: any[],
  setPoolRequests: (poolRequests: any[]) => void,
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const { tokenPrices, pending, setPending } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { chainId, account, provider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { screenWidth } = useScreenSize();
  const { 
    setTermRateChanged, 
    setLendRatioChanged,
    setHideRecommendation
  } = useContext(CreatePoolPagesContext) as CREATE_POOL_PAGES_CONTEXT;
  const { 
    setSelectedCollateral, 
    setSelectedLend,
    setSelectedMintRatio,
    setSelectedExpiry,
    setInitialDeposit,
    setSelectedMintRatioButton,
    setSelectedAPR,
    setRateType,
    setCurrentPage,
    rateType,
    protocolFee,
  } = useContext(CreatePoolContext) as CREATE_POOL_CONTEXT;
  const navigate = useNavigate();

  const [poolId, setPoolId] = useState<string>("");

  useEffect(() => {
    // set the pool id for the this pool request
    if (props.poolRequest) {
      setPoolId(`${props.poolRequest.uid}`);
    }
  // eslint-disable-next-line
  }, [props.poolRequest]);

  // calculate what the initial deposit would be with the current fee rate
  // in order to match the user's requested initial deposit
  const calculateInitialDepositWithFee = () => {
    const {
      startRate,
      expiry,
      initialDeposit: _requestInitialDeposit,
    } = props.poolRequest;
    const requestInitialDeposit = Number(_requestInitialDeposit);
    // pass in what the fee rate would be
    const currentFeeRate = rateType !== "Fixed" 
      // decaying rate is calculated based on the time left in the pool
      ? (Number(startRate) * (Number(expiry) - new Date().getTime()) / (31_536_000 * 1000)) / 100
      // fixed and rates are just the selected rate
      : Number(startRate) / 100;
    // calculate the max borrow amount based on the requested initial deposit + fees
    const maxBorrowAmount = requestInitialDeposit / (1 - (protocolFee + currentFeeRate));
    return Number(maxBorrowAmount);
  }

  // update pool parameters when a pool request is selected
  const requestRowClicked = () => {
    // don't update pool parameters if the user is not on the create pool page
    if (getCurrentPage() !== "create-pool") return;
    navigate("/create-pool?requestedPool=true");
    // get the collateral and lend data for the selected pool request
    const colData = fetchLocalTokenData(props.poolRequest.colToken, chainId);
    const lendData = fetchLocalTokenData(props.poolRequest.lendToken, chainId);
    // setup the selected collateral and lend data
    const requestedSelectedLend = {
      address: lendData.address[chainId],
      symbol: lendData.symbol,
      hasOracle: lendData.hasOracle,
      displayDecimals: lendData.displayDecimals,
      decimals: lendData.tokenDecimals,
      price: tokenPrices[lendData.address[`${chainId}`]],
    }
    const requestedSelectedCol = {
      address: colData.address[chainId],
      symbol: colData.symbol,
      hasOracle: colData.hasOracle,
      displayDecimals: colData.displayDecimals,
      decimals: colData.tokenDecimals,
      price: tokenPrices[colData.address[`${chainId}`]],
    }
    // set state for the selected pool request
    setSelectedCollateral(requestedSelectedCol);
    setSelectedLend(requestedSelectedLend);
    setSelectedMintRatio(props.poolRequest.lendRatio);
    setSelectedExpiry(new Date(Number(props.poolRequest.expiry)));
    setSelectedMintRatioButton("Custom");
    setRateType(props.poolRequest.rateType);
    setSelectedAPR([props.poolRequest.startRate, props.poolRequest.endRate]);
    setTimeout(() => {
      // add a slight delay to allow the selected pool request to be set
      // the initial deposit is reset whenever the selected tokens are changed
      setInitialDeposit(calculateInitialDepositWithFee());
      setTermRateChanged(true);
      setLendRatioChanged(true);
      setHideRecommendation(true);
    }, 100);
    setCurrentPage(0);
    // update the selected pool request
    props.setSelectedPoolRequest(props.poolRequest);
    // show snackbar
    enqueueSnackbar("Pool request selected");
  }

  const renderPairColumn = () => {
    // fetch token data to grab the token symbols 
    const lendData = fetchLocalTokenData(props.poolRequest.lendToken, chainId);
    const colData = fetchLocalTokenData(props.poolRequest.colToken, chainId);
    if (!lendData || !colData) return <></>;
    const lendSymbol = lendData.symbol;
    const colSymbol = colData.symbol;
    return (
      <span className={`pool-row-column-label pair-column`}>
        <Fragment>
          <ReactTooltip id={`token-pair-tip-${colSymbol}-${lendSymbol}`} type="info" className="tool-tip">
            <span>
              {colSymbol}/{lendSymbol}
            </span>
          </ReactTooltip>
          <div data-tip data-for={`token-pair-tip-${colSymbol}-${lendSymbol}`}>
            <div className="icon-pair align-left">
              <img
                className="token-logo col-icon"
                alt={`${colSymbol}`}
                src={fetchTokenLogo(colSymbol)}
              ></img>
              <img
                className="token-logo lend-icon"
                alt={`${lendSymbol}`}
                src={fetchTokenLogo(lendSymbol)}
              ></img>
            </div>
          </div>
        </Fragment>
      </span>
    );
  }

  const renderLendRatioColumn = () => {
    return (
      <span className={`pool-row-column-label ltv-column`}>
        {props.poolRequest.lendRatio}
      </span>
    )
  }

  // render the term rate column
  const renderTermRateColumn = () => {
    const {
      startRate,
      endRate,
      startDate,
      endDate,
      expiry,
      rateType
    } = props.poolRequest;
    // compute the rate based on selected parameters
    const rate = computeRate(
      new Date(startDate),
      new Date(endDate),
      Number(startRate),
      Number(endRate),
      new Date(Number(expiry)),
      new Date().getTime(),
      rateType,
    )
    return (
      <Fragment>
        <ReactTooltip id={`term-rate-tip-${props.poolRequest.uid}`} type="info" className="tool-tip">
          <span>
            {rateType} 
          </span>
        </ReactTooltip>
        <span 
          className={`pool-row-column-label term-rate-column`}
          data-tip
          data-for={`term-rate-tip-${props.poolRequest.uid}`}
        >
          {rate.toFixed(2)}%
        </span>
      </Fragment>
    ) 
  }

  const renderAnnualizedRateColumn = () => {
    const {
      startRate,
      endRate,
      startDate,
      endDate,
      rateType,
      expiry
    } = props.poolRequest;
    // compute the rate based on selected parameters
    const rate = computeAnnualizedRate(
      Number(startRate),
      Number(endRate), 
      new Date(startDate).getTime(),
      new Date(endDate).getTime(),
      Number(expiry) / 1000,
      rateType === "Fixed" ? 1 : 2
    );
    return (
      <span className={`pool-row-column-label apr-column`}>
        {rate.toFixed(2)}%
      </span>
    ) 
  }

  // render the expiry column
  const renderExpiryColumn = () => {

    let dateFormatting;

    // format date for mobile / desktop
    if (screenWidth > mobileBreakpoint) {
      dateFormatting = (
        <span className={`pool-row-column-label`}>
          {moment(Number(props.poolRequest.expiry)).format("MMM D, Y hh:mm a")}
        </span>
      );
    } else {
      dateFormatting = (
        <div className="mobile-expiry-wrapper">
          <span className={`pool-row-column-label`}>
            {moment(Number(props.poolRequest.expiry)).format("M/D/Y")}
          </span>
        </div>
      );
    }
    return (
      <span className={`pool-row-column-label expiry-column`}>
        {dateFormatting}
      </span>
    )
  }

  // render the available column
  const renderAvailableColumn = () => {
    const formattedAmount = Number(props.poolRequest.initialDeposit) < 10000
      ? Number(props.poolRequest.initialDeposit).toFixed(2)
      : millify(Number(props.poolRequest.initialDeposit), {
        precision: 2,
        lowercase: true
      });
    return (
      <span className={`pool-row-column-label available-column`}>
        {formattedAmount}
      </span>
    )
  }

  // delete the pool request from the database based on the uid
  const deletePoolRequest = async () => {
    if (pending || !provider) return;
    enqueueSnackbar("Please sign the request with your wallet to continue");
    setPending(true);
    try {
      // request user signature
      const signer = provider.getSigner();
      const message = "Please sign this message to delete your pool request";
      const signedMessage = await signer.signMessage(message);
      const url = useDevFunctions 
        ? new URL("http://localhost:5001/vendor-finace/us-central1/deletePoolRequest") 
        : new URL("https://us-central1-vendor-finace.cloudfunctions.net/deletePoolRequest");
      url.searchParams.append("uid", props.poolRequest.uid);
      url.searchParams.append("owner", props.poolRequest.owner);
      url.searchParams.append("signature", signedMessage);
      // make request
      await axios.get(url.href);
      // remove the pool request from the list
      const request = props.poolRequests.findIndex((poolRequest: any) => poolRequest.uid === props.poolRequest.uid);
      let copy = [...props.poolRequests];
      copy.splice(request, 1);
      props.setPoolRequests(copy);
      enqueueSnackbar("Pool request successfully deleted");
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Failed to delete pool request");
    }
    setPending(false);
  }

  const renderDeleteButton = () => {
    if (props.poolRequest.owner === account)
      return (
        <Fragment>
          <ReactTooltip id={`delete-pool-request-tip-${poolId}`} type="info" className="tool-tip">
            <span>
              Delete Pool Request
            </span>
          </ReactTooltip>
          <XCircle 
            className={`pool-request-delete-button ${pending ? "pending" : ""}`}
            data-tip
            data-for={`delete-pool-request-tip-${poolId}`}
            onClick={deletePoolRequest}
          />
        </Fragment>
      )
    else return <></>;
  }

  // is the pool request selected
  const isSelectedPoolRequest = () => {
    if (!props.selectedPoolRequest) return false;
    const {
      uid
    } = props.selectedPoolRequest
    return poolId === `${uid}`;
  }

  return (
    <div 
      className={`pool-row-wrapper pool-request-row-wrapper ${isSelectedPoolRequest() ? "selected" : ""}`}
      onClick={requestRowClicked}
    >
      {renderPairColumn()}
      {renderLendRatioColumn()}
      {renderTermRateColumn()}
      {renderAnnualizedRateColumn()}
      {renderExpiryColumn()}
      {renderAvailableColumn()}
      {renderDeleteButton()}
    </div>
  )
}

export default PoolRequestRow;