import { useInterval } from "@usedapp/core";
import { useEffect, useRef } from "react";
import { generateUid } from "../../utils/Utils";
import "./LineTabs.css";

const LineTabs = (props: {
  tabs: any[],
  activeTab: number,
  setActiveTab: (tab: number) => void
}) => {

  // create UID and remove numbers
  const uid = useRef<string>(generateUid());

  useEffect(() => {
    getLinePosition();
  }, [props.activeTab]);

  useInterval(() => {
    getLinePosition();
  }, 1000);

  const getLinePosition = () => {
    const lineTabsUnderline = document.querySelectorAll(`.line-tabs-underline.${uid.current}`)[0] as HTMLElement;
    const activeTab = document.querySelectorAll(`.line-tab.active.${uid.current}`)[0] as HTMLElement;
    if (!activeTab || !lineTabsUnderline) return;
    const activeTabWidth = activeTab.offsetWidth;
    const activeTabLeft = activeTab.offsetLeft;
    lineTabsUnderline.style.width = `${activeTabWidth}px`;
    lineTabsUnderline.style.left = `${activeTabLeft}px`;
  }

  return (
    <div className="line-tabs-wrapper">
      {props.tabs.map((tab, i) => {
        return (
          <div className={`line-tab ${props.activeTab === i ? "active" : ""} ${uid.current}`} onClick={() => props.setActiveTab(i)}>
            {tab}
          </div>
        )
      })} 
      <div className={`line-tabs-underline ${uid.current}`}></div>
    </div>
  )
}

export default LineTabs;