import "./Dashboard.css";
import TopNav from "../TopNav/TopNav";
import { useEffect, useRef, useState } from "react";
import { Route, Routes } from "react-router-dom";
import CreatePool from "../../pages/CreatePool/CreatePool";
import MyPools from "../../pages/MyPools/MyPools";
import Explore from "../../pages/Explore/Explore";
import {
  fetchLocalTokenData,
  getAccount,
  getChainId,
  getWeb3Provider,
  tokenData,
  contractAddresses,
  networks,
  handleMulticallAddress,
  fetchTokenPrice,
  defaultChain,
  fetchBulkPrices,
  getCurrentPage,
  fetchHistoricalPriceData,
  getNetworkDataByName,
  getNetworkData,
  getAaveLendingPoolContract,
  ZERO_ADDRESS,
  isDarkMode,
  getFeaturedPoolsManagerContract,
  strategies,
} from "../../utils/Utils";
import { BigNumber, ethers } from "ethers";
import { useConnectWallet } from "@web3-onboard/react";
import { AppDataContext } from "../../context/AppDataContext";
import { WalletDataContext } from "../../context/WalletDataContext";
import { FeaturedPoolsManagerContext } from "../../context/FeaturedPoolsManagerContext";
import LendingPoolABI from "../../abi/LendingPool.js";
import { FEATURED_POOL_DATA, LOCAL_TOKEN_DATA, POOL_DATA } from "../../utils/Interfaces";
import { Contract, Provider as MulticallProvider, Provider } from "ethers-multicall";
import FeeManager from "../../abi/FeeManager";
import { addDays } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";
declare var window: any;

// let tokenPrices:{} = {}
let localPrices:any = {};

// poolData object that is not stored in state. This prevents race conditions when updating pool data
let nonStatePoolData: any = {};

const Dashboard = () => {
  // pending result
  const [pending, setPending] = useState<boolean>(false);
  // range of liquidity to show
  const [liquidityRange, setLiquidityRange] = useState<number[]>([0, 1000000]);
  // range of apr rates to show
  const [aprRange, setAprRange] = useState<number[]>([0, 800]);
  // range of dates to show
  const [dateRange, setDateRange] = useState<Date[]>([new Date(), addDays(new Date(), 365)]);
  // token prices to be updated in AppData context
  const [tokenPrices, setTokenPrices] = useState<any>({});
  // object holding full contract data for all pools
  const [fullPoolData, setFullPoolData] = useState<any | {}>({});
  // boolean to show/hide network warning
  const [showNetworkWarning, setShowNetworkWarning] = useState<boolean>(false);
  // boolean to set/unset dark mode
  const [darkMode, setDarkMode] = useState<boolean>(false);
  // ref value for google recaptcha
  const recaptchaRef = useRef<any>();

  // featured pools data for the FeaturedPoolsManagerContext
  // list of pools that users have paid to be featured
  const [featuredPoolsList, setFeaturedPoolsList] = useState<FEATURED_POOL_DATA[]>([]);
  // how long the pool should be featured for
  const [featuredDuration, setFeaturedDuration] = useState<number>();
  // price to be featured
  const [featuredPoolsPrice, setFeaturedPoolsPrice] = useState<number>();

  // wallet data to be updated in the WalletDataContext 
  const [{ wallet }] = useConnectWallet();
  const [account, setAccount] = useState<string | any>("");
  const [provider, setProvider] = useState<ethers.providers.Web3Provider | ethers.providers.JsonRpcSigner | undefined>();
  const [chainId, setChainId] = useState<number>();
  const [multicallProvider, setMulticallProvider] = useState<MulticallProvider>();
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [historicalPrices, setHistoricalPrices] = useState<any>({});
  const connecting = useRef<boolean>(false);

  useEffect(() => {
    setDarkMode(isDarkMode());
    // spam prevention
    if (!connecting.current && !initialLoad)
      firstLoadWallet();
  // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // load token prices if they haven't already been loaded
    if (chainId) {
      loadTokenPrices();
      fetchFeaturedPools();
      // getFeaturedPoolsManagerData();
    }
    if (Object.entries(historicalPrices).length === 0) getHistoricalEthPrices();
  // eslint-disable-next-line
  }, [chainId])

  useEffect(() => {
    if (initialLoad) updateWalletData();
  // eslint-disable-next-line
  }, [wallet, initialLoad]);

  useEffect(() => {
    toggleSiteTheme();
  });

  const fetchFeaturedPools = async () => {
    try {

      if (!provider || !chainId || !multicallProvider) return;

      // get featured pools manager contract
      let featuredPoolsManagerContract = getFeaturedPoolsManagerContract(
        provider,
        chainId, 
      );
      // get number of entries
      const numEntries = Number((await featuredPoolsManagerContract.getNumEntries()).toString());
      // setup multicall version of contract
      featuredPoolsManagerContract = getFeaturedPoolsManagerContract(
        provider,
        chainId,
        true
      )
      // get all entries
      let calls = [...new Array(numEntries)].map((_, i) => featuredPoolsManagerContract.getEntry(i))

      // add entry price and feature duration to calls
      calls = calls.concat([
        featuredPoolsManagerContract.entryPrice(),
        featuredPoolsManagerContract.featureDuration()
      ]);

      // setup and execute multicall
      handleMulticallAddress(chainId, multicallProvider);
      const response = await multicallProvider.all(calls);

      let featuredPools:FEATURED_POOL_DATA[] = [];

      // iterate through the entries and add them to the featured pools array
      // only iterate through the section of the array that contains entries
      for (const entry of response.slice(0, numEntries)) {
        // get data from entry
        const [
          poolAddress,
          sponsor,
          featuredDuration,
        ] = entry;

        // format and add to array
        featuredPools.push({
          poolAddress,
          sponsor,
          featuredDuration: Number(featuredDuration.toString())
        });
      }

      // extract the entry price and feature duration from response
      const [
        entryPrice,
        featureDuration
      ] = response.slice(numEntries);

      const foramttedEntryPrice = Number(ethers.utils.formatEther(entryPrice).toString());

      // update state setFeaturedPoolsList
      setFeaturedPoolsList(featuredPools);
      // update state for metadata
      setFeaturedPoolsPrice(foramttedEntryPrice);
      setFeaturedDuration(Number(featureDuration.toString()));
    } catch (e) {
      setFeaturedPoolsList([]);
      setFeaturedPoolsPrice(0);
      // setFeaturedDuration(Number(featureDuration.toString()));
    }
  }

  // go through featured pools list and check if pool should have featured status
  const isPoolFeatured = (poolAddress: string, _featuredPoolList?: FEATURED_POOL_DATA[]) => {
    const poolList = _featuredPoolList ? _featuredPoolList : featuredPoolsList;
    if (poolList.length > 0) {
      for (const entry of poolList) {
        if (
          // check that the pool address matches
          entry.poolAddress.toLowerCase() === poolAddress.toLowerCase() &&
          // check that the featured duration has not expired
          new Date().getTime() / 1000 <= entry.featuredDuration
        ) {
          return true;
        }
      }
    }
    return false;
  }

  // get the entry data for a given featured pool
  const getFeaturedPoolData = (poolAddress: string) => {
    if (featuredPoolsList.length > 0) {
      for (const entry of featuredPoolsList) {
        // check that the pool address matches
        if (entry.poolAddress.toLowerCase() === poolAddress.toLowerCase()) {
          return entry;
        }
      }
    }
    return undefined;
  }

  const toggleSiteTheme = () => {
    // get all elements
    const elements:any[] = [
      document.body,
      document.getElementById("root"),
      document.getElementsByClassName("App")[0]
    ]
    // add dark mode class to all elements
    for (let i = 0; i < elements.length; i++) {
      if (darkMode)
        elements[i].classList.add("dark-mode");
      else
        elements[i].classList.remove("dark-mode");
    }
  }

  // pre-fetch historical prices for WETH before getting to the create pool page
  const getHistoricalEthPrices = async () => {
    // fetch historical price data from backend or load from cached data
    if (!chainId) return;
    let historicalData:[][] = [];
    // @ts-ignore
    const colToken = tokenData.WETH.address[chainId];
    // check if entry exists
    if (!historicalPrices[colToken]) {
      // if no entry, load from backend
      historicalData = await fetchHistoricalPriceData(colToken, chainId, 71);
      let newStoredData = {
        ...historicalPrices,
        [colToken]: historicalData
      }
      // save data to cache
      setHistoricalPrices(newStoredData);
    } else {
      // if entry, load from cache
      historicalData = historicalPrices[colToken];
    }
    return (historicalData);
  }
  
  const updateWalletData = () => {
    if (wallet && getChainId(wallet) === 42161 && chainId === 42161) {
      const provider = getWeb3Provider(wallet?.provider);
      const multicallProvider = new Provider(provider);
      const chainId = getChainId(wallet);
      const account = getAccount(wallet);
      multicallProvider.init();
      handleMulticallAddress(chainId as number, multicallProvider);
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    } else if (wallet && getChainId(wallet) !== chainId) {
      // wallet is connected but chainId is different
      const network = getNetworkData(chainId as number) ;
      const provider = getWeb3Provider(network.rpcUrl, true);
      const multicallProvider = new Provider(provider);
      const account = getAccount(wallet);
      multicallProvider.init();
      handleMulticallAddress(network.chainId, multicallProvider);
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    } else if (wallet && getChainId(wallet) === chainId) {
      // wallet is connected and chainId is the same
      const provider = getWeb3Provider(wallet.provider);
      const multicallProvider = new Provider(provider);
      const account = getAccount(wallet);
      handleMulticallAddress(chainId as number, multicallProvider);
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(chainId);
      setAccount(account);
    }
  }

  // method for initially loading wallet data
  const firstLoadWallet = () => {
    connecting.current = false;
    // const userWallet = wallet;
    let multicallProvider;
    let provider;
    let account;

    // check if there is a network in the url
    const urlParams = new URLSearchParams(window.location.search);
    const queryChain = urlParams.get("chain");
    const network = queryChain 
      ? getNetworkDataByName(queryChain) 
      : networks.Arbitrum

    provider = getWeb3Provider(network.rpcUrl, true);
    multicallProvider = new Provider(provider, network.chainId);

    if (multicallProvider) {
      multicallProvider.init();
      handleMulticallAddress(network.chainId, multicallProvider);
      setProvider(provider);
      setMulticallProvider(multicallProvider);
      setChainId(network.chainId);
      setAccount(account);
    }

    setInitialLoad(true);
    connecting.current = false;
  }

  const calculateLTV = (pool: POOL_DATA) => {
    // get token prices from tokenPrice context object
    // mutliply lendPrice by (1 - all fees)
    let lendPrice = tokenPrices[ethers.utils.getAddress(pool.lendToken)];
    const colPrice = tokenPrices[ethers.utils.getAddress(pool.colToken)];
    const protocolFee = pool.protocolFee; 
    // calculate LTV from lend and collateral prices
    const mintRatio = ethers.utils.formatUnits(pool.mintRatio, 18);
    const ltvLendValue = parseFloat(mintRatio) * lendPrice;
    const ltvColValue = colPrice;
    const totalFees = (ltvLendValue / (1 - protocolFee - (Number(pool.currentFeeRate) / 10000)/100)) - ltvLendValue;
    return ((ltvLendValue - totalFees) / ltvColValue) * 100;
  }

  // get all the contract based pool data along with some calculated values.
  // store the data in an object holding all pool data
  const getPoolData = async (pools: POOL_DATA[], updateBorrowed?: boolean) => {

    if (!multicallProvider || !chainId) return;

    let completed = 0;

    pools.forEach(async (pool: POOL_DATA) => {
      try {

        // skip if provider doesn't exist or 
        if (!provider) {
          completed++;
          return;
        }

        // setup contracts for multicall
        const poolContract = new Contract(pool.id, LendingPoolABI);
        // @ts-ignore
        const feeManagerContract = new Contract(contractAddresses.FEE_MANAGER.address[chainId], FeeManager);

        // get local token data for collateral and lend tokens
        const colData: LOCAL_TOKEN_DATA = fetchLocalTokenData(
          pool.colToken,
          chainId
        );
        const lendData: LOCAL_TOKEN_DATA = fetchLocalTokenData(
          pool.lendToken,
          chainId
        );

        // get the AAVE lending pool contract
        const lendingPool = getAaveLendingPoolContract(
          provider,
          chainId,
          true
        )

        // list of calls to feed through multicall for the current pool
        let calls:any[] = [
          poolContract.colBalance(),
          poolContract.lendBalance(),
          feeManagerContract.poolFeesData(pool.id),
          feeManagerContract.getCurrentRate(pool.id),
          poolContract.lenderTotalFees(),
          poolContract.getPoolSettings(),
          poolContract.strategy(),
        ];

        // check AAVE strategies object and add multicall for reserve data if the lendToken is supported
        if (strategies.AAVE.supportedTokens[pool.lendToken])
          calls.push(lendingPool.getReserveData(pool.lendToken));

        // execute contract calls 
        handleMulticallAddress(chainId, multicallProvider);
        const response = await multicallProvider.all(calls);

        // extract data from calls
        let [
          poolColBalanceResp, 
          poolLendBalanceResp, 
          poolFeesData,
          feeRateResp, 
          rawPoolInterestOwed,
          poolSettings,
          strategy,
          reserveData
        ] = response;

        let liquidityRate = undefined;
        let strategyRate = undefined;

        try {
          if (strategy !== ZERO_ADDRESS) {
            // get the pool's strategy rate
            liquidityRate = reserveData.liquidityRate;
            strategyRate = parseFloat(ethers.utils.formatUnits(liquidityRate, 25)); // 25 decimals for the interest rate
          }
        } catch (e) {

        }

        let userData:any = {};

        // convert raw data to usable format
        const rawProtocolFee = poolSettings.protocolFee;
        const protocolFee = rawProtocolFee / 1000000;

        // get individual user stats if account is present and user is on my-pools
        if (account && getCurrentPage() === "my-pools") {
          try {
            // reinitialize lending pool contract with ethers
            const poolContract = new ethers.Contract(pool.id, LendingPoolABI, provider);
            const rawUserDebtData = await poolContract.debts(account);
            // extract user debt data
            const rawUserDebt = rawUserDebtData.debt;
            const rawUserColAmount = rawUserDebtData.colAmount;
            // format user debt data
            const userDebt:any = ethers.utils.formatUnits(
              rawUserDebt,
              lendData?.tokenDecimals
            );
            const userColAmount = ethers.utils.formatUnits(
              rawUserColAmount,
              colData?.tokenDecimals
            );
            // term1 - feeRate 
            const poolFee = (Number(feeRateResp) / 10000) / 100;
            const userBorrowed = (parseFloat(userDebt) * (1 - protocolFee - poolFee));
            // add debt data to object
            userData = {
              userDebt,
              rawUserDebt,
              userColAmount,
              rawUserColAmount,
              userBorrowed,
            }
          } catch (e) {console.log(e)}
        }

        // exract pool fees data 
        const { 
          auctionEndDate,
          auctionStartDate,
          rateType,
          startRate,
          endRate
        } = poolFeesData;

        // get token prices from tokenPrice context object
        const lendPrice = tokenPrices[ethers.utils.getAddress(lendData.address[`${chainId}`])];
        const colPrice = tokenPrices[ethers.utils.getAddress(colData.address[`${chainId}`])];

        // calculate LTV from lend and collateral prices
        const mintRatio = ethers.utils.formatUnits(pool.mintRatio, 18);

        // calculate total borrowed
        let totalBorrowed = parseFloat(mintRatio) * parseFloat(ethers.utils.formatUnits(poolColBalanceResp, colData.tokenDecimals));
        const poolFee = (Number(feeRateResp) / 10000) / 100;
        totalBorrowed *= (1 - protocolFee - poolFee);

        const num = BigNumber.from(pool.mintRatio).div(ethers.utils.parseUnits("1", colData.tokenDecimals));
        const rawTotalBorrowed = num.mul(poolColBalanceResp);

        // calculate the annualize fee rate based on the rate type
        let annualizedFeeRate:any;
        if (rateType === 1) {
          // annualizedFeeRate = feeRateResp;
          annualizedFeeRate = (31536000 / (((Number(pool.expiry)) - new Date().getTime() / 1000))) * feeRateResp;
        } else {
          /*
                auctionStartDate     auctionEndDate
            ------------|----------------|----------
                A               B             C
            If now is in region A -> APR=auctionStartRate
            If now is in region B -> APR= (AED-now)/ (AER-ASR)
            If now is in region C -> APR=auctionEndRate
          */
          const now = Math.trunc(new Date().getTime() / 1000);
          // logic for determining APR for pools with type 2 (linear decay with auction)
          if (now < auctionStartDate)  {
            annualizedFeeRate = startRate;
          } else if (now >= auctionStartDate && now < auctionEndDate)  {
            annualizedFeeRate = endRate + (startRate - endRate) * (auctionEndDate - now) / (auctionEndDate - auctionStartDate);
          } else if (now >= auctionEndDate) {
            annualizedFeeRate = endRate;
          }
        }

        // undercollateralized and underwater logic
        // undercollateralized = undercollateralized.gt(BigNumber.from("0")) ? true : false;
        // undercollateralized = undercollateralized.toString() === "0" ? false : true;
        // let underMintRatio = colPrice < parseFloat(mintRatio);
        let isUnder = false;

        // get max borrow amount for pool given poolLendBalance, protocolFee, and poolFee
        const maxBorrowAmount = 
          Number(ethers.utils.formatUnits(poolLendBalanceResp, lendData?.tokenDecimals)) * 
          (1 - (protocolFee + poolFee));

        // collect all calculated data into an object 
        let fetchedData = {
          ...pool,
          ...userData,
          ...poolFeesData,
          externalDataLoaded: true,
          poolLendBalance: ethers.utils.formatUnits(poolLendBalanceResp, lendData?.tokenDecimals),
          poolColBalance: ethers.utils.formatUnits(poolColBalanceResp, colData?.tokenDecimals),
          lendBalance: ethers.utils.formatUnits(poolLendBalanceResp, lendData?.tokenDecimals),
          colBalance: ethers.utils.formatUnits(poolColBalanceResp, colData?.tokenDecimals),
          poolInterestOwed: Number(ethers.utils.formatUnits(rawPoolInterestOwed, lendData?.tokenDecimals)),
          isExpired: Number(pool.expiry) * 1000 < new Date().getTime(),
          colSymbol: colData?.symbol,
          lendSymbol: lendData?.symbol,
          colDecimals: colData?.tokenDecimals,
          lendDecimals: lendData?.tokenDecimals,
          currentFeeRate: feeRateResp.toString(),
          feeType: poolFeesData.rateType,
          type: poolSettings.poolType,
          pauseTime: poolSettings.pauseTime,
          rawPoolInterestOwed,
          interestOwed: "0",
          totalBorrowed,
          rawTotalBorrowed,
          annualizedFeeRate,
          colPrice,
          lendPrice,
          isUnder,
          rawProtocolFee,
          protocolFee,
          strategy,
          maxBorrowAmount,
          strategyRate
        };

        fetchedData.ltv = calculateLTV(fetchedData);

        // save the data into the non-state poolData object 
        nonStatePoolData[`${pool.id}`] = {
          ...fetchedData
        }

      } catch (e) {
        completed++;
        console.log(e);
      }

      if (completed++ >= pools.length - 1) {
        setFullPoolData({
          ...nonStatePoolData,
        });
      }
    });
  };

  // locally load the token price or fetch it if it hasn't been loaded yet
  const loadTokenPrices = async () => {
    let completed = 0;

    // extract prices from coingecko bulk response
    const extractPrices = (bulkPrices: any) => {
      // iterate through all tokens and grab the response if it exists
      Object.values(tokenData).forEach((data: any) => {
        const priceKey = `coingecko:${data.id}`;
        try {
          if (bulkPrices[priceKey] && !data.pairAddress) {
            // get price, tokenaddress, and key for object
            const price = bulkPrices[priceKey].price;
            const tokenAddress = data.address[`${chainId}`];
            const key = ethers.utils.getAddress(tokenAddress);
            // add price to localPrices object
            localPrices = {
              [`${key}`]: price,
              ...localPrices
            }
          }
        } catch (e) {
          console.log(e);
          console.log("failed to load price for ", data.id);
        }
      });
    }

    let ids:string[] = [];

    // iterate through all tokens and fetch prices
    Object.values(tokenData).forEach(async (data: any) => {
      // get token address for current chain and the address
      // for where the price can be fetched
      const tokenAddress = data.address[`${chainId}`];
      const priceNetwork = data.historicalPriceNetwork[0];
      const networkAddress = data.address[priceNetwork];
      // skip non-supported tokens for the chain
      const supported = tokenAddress !== undefined && tokenAddress !== "";
      if (!localPrices[networkAddress] && supported) {
        const key = ethers.utils.getAddress(tokenAddress);
        // fetch price
        if (data.id) {
          // if token has an ID add to array
          ids.push(data.id);
        } else {
          // else fetch price normally (oracle/coingecko single token/firebase)
          fetchTokenPrice(tokenAddress, chainId || defaultChain, provider).then(price => {
            // store in object
            localPrices[key] = price;
            setTokenPrices(localPrices);
          });
        }
      } 
      // update global state object when complete
      if (completed++ === Object.values(tokenData).length - 1) {
        const bulkPrices = await fetchBulkPrices(ids);
        extractPrices(bulkPrices);
        setTokenPrices(localPrices);
      }
    });

    // don't delete
    return;
  }

  return(
    <div className="dashboard-wrapper">
      <WalletDataContext.Provider value={{ provider, setProvider, chainId, setChainId, account, setAccount, multicallProvider, setMulticallProvider }}>
        <AppDataContext.Provider 
          value={{ 
            tokenPrices, 
            setTokenPrices, 
            pending, 
            setPending, 
            calculateLTV, 
            liquidityRange, 
            setLiquidityRange,
            fullPoolData,
            getPoolData,
            historicalPrices,
            setHistoricalPrices,
            aprRange,
            setAprRange,
            dateRange,
            setDateRange,
            showNetworkWarning,
            setShowNetworkWarning,
            darkMode,
            setDarkMode,
            recaptchaRef,
          }}
        >
          <FeaturedPoolsManagerContext.Provider value={{
            featuredPoolsList,
            setFeaturedPoolsList,
            featuredDuration,
            setFeaturedDuration,
            featuredPoolsPrice,
            setFeaturedPoolsPrice,
            fetchFeaturedPools,
            isPoolFeatured,
            getFeaturedPoolData,
          }}>
            <TopNav/>
            <ReCAPTCHA 
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              size="invisible"
            />
            <div className="dashboard-content">
              <div className="topnav-divider"></div>
              <div className="routes-container">
                <Routes>
                  <Route
                    path="/create-pool"
                    element={
                      <CreatePool/>
                    }
                  />
                  <Route
                    path="/my-pools/*"
                    element={
                      <MyPools/>
                    }
                  />
                  <Route
                    path="/borrow/*"
                    element={
                      <Explore
                        key={`Borrow Page (${chainId})`}
                      />
                    }
                  />
                  <Route
                    path="/*"
                    element={
                      <Explore
                        key={`Borrow Page (${chainId})`}
                      />
                    }
                  />
                  <Route 
                    path="/request-pool"
                    element={
                      <CreatePool/>
                    }
                  />
                </Routes>
              </div>
            </div>
          </FeaturedPoolsManagerContext.Provider>
        </AppDataContext.Provider>
      </WalletDataContext.Provider>
    </div>
  )
}

export default Dashboard;