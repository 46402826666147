import { useContext, useEffect, useState } from "react";
import ActionButton from "../../components/ActionButton/ActionButton";
import Slideshow from "../../components/Slideshow/Slideshow";
import { ethers, Contract, BigNumber } from "@usedapp/core/node_modules/ethers";
import { fetchLocalTokenData, getGasLimit, getTransactionUrl, handleMulticallAddress, isDarkMode, isPoolExpired, pollTime, readableABIs, supportedNetworks } from "../../utils/Utils";
import ERC20ABI from "../../abi/ERC20.js";
import TokenSelector from "../../components/TokenSelector/TokenSelector";
import LendingPoolABI from "../../abi/LendingPool.js";
import "./MyPools.css";
import { useSnackbar } from "notistack";
import LendingPool from "../../components/LendingPool/LendingPool";
import { useCookies } from "react-cookie";
import { WalletDataContext } from "../../context/WalletDataContext";
import { APP_DATA_CONTEXT, METHOD_TYPE, POOL_DATA_CONTEXT, WALLET_DATA_CONTEXT } from "../../utils/Interfaces";
import { AppDataContext } from "../../context/AppDataContext";
import { PoolDataContext } from "../../context/PoolDataContext";
import { Contract as MulticallContract } from "ethers-multicall";
import { useInterval } from "@usedapp/core";
import LineTabs from "../../components/LineTabs/LineTabs";

const BalanceManager = (props: {
  updatePoolData: () => void,
  mode: string
}) => {

  const [mode, setMode] = useState<"add" | "withdraw" | "repay" | "rollover">("add")
  const [inputValue, setInputValue] = useState<string | number>("");
  const [userLendBalance, setUserLendBalance] = useState<any>(BigNumber.from("0"));
  const [poolLendBalance, setPoolLendBalance] = useState<BigNumber>(BigNumber.from("0"));
  const [poolColBalance, setPoolColBalance] = useState<any>(BigNumber.from("0"));
  const [actionButtonText, setActionButtonText] = useState<string>("")
  const [actionButtonDisabled, setActionButtonDisabled] = useState<boolean>(false);
  const [actionButtonAction, setActionButtonAction] = useState<() => void>(() => {});
  const [lendAllowance, setLendAllowance] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [cookies] = useCookies(['infiniteApprovalDisabled']);
  const [initialBalanceLoaded, setInitialBalanceLoaded] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { provider, chainId, account, multicallProvider } = useContext(WalletDataContext) as WALLET_DATA_CONTEXT;
  const { pending, setPending, getPoolData, fullPoolData } = useContext(AppDataContext) as APP_DATA_CONTEXT;
  const { selectedPool } = useContext(PoolDataContext) as POOL_DATA_CONTEXT;

  useEffect(() => {
    setInputValue("");
    getBalanceData();
  // eslint-disable-next-line
  }, [selectedPool, mode]);

  useEffect(() => {
    manageActionButton();
  // eslint-disable-next-line
  }, [selectedPool, mode, lendAllowance, userLendBalance, poolLendBalance, pending, inputValue, loading]);

  useInterval(() => {
    getBalanceData(true);
  }, pollTime);

  const manageActionButton = () => {

    if (!selectedPool) return;

    // make sure the method has the vars it needs
    const hasVars = () => {
      if (!selectedPool || !account) return false;
      else return true;
    }

    // check approvals and allowances 
    const hasApproved = () => {
      if (mode === "withdraw") {
        // approve not needed for withdraw
        return true;
      } else if (props.mode === "borrowed") {
        setActionButtonText("Approve");
        setActionButtonDisabled(false);
        setActionButtonAction(() => approve);
      } else if (!lendAllowance || lendAllowance.lt(ethers.utils.parseUnits(inputValue.toString() || "0", selectedPool.lendDecimals))) {
        setActionButtonText("Approve");
        setActionButtonDisabled(false);
        setActionButtonAction(() => approve);
        return false;
      } else {
        return true;
      }
    }

    if (!hasApproved()) return;

    if (hasVars() && isPoolExpired(selectedPool)) {
      setActionButtonText("Collect");
      setActionButtonAction(() => collect);
      if (
        poolLendBalance && 
        poolColBalance && 
        poolLendBalance.eq(BigNumber.from("0")) && 
        poolColBalance.eq(BigNumber.from("0"))
      )
        setActionButtonDisabled(true);
      else
        setActionButtonDisabled(false);

    } else if (mode === "add")  {
      // setActionButtonAction(deposit);
      setActionButtonText("Add");
      if (!hasVars()) {
        setActionButtonDisabled(true);
        console.log(hasVars());
        return;
      };
      setActionButtonDisabled(
        ethers.utils.parseUnits(inputValue.toString() || "0", selectedPool.lendDecimals).gt(userLendBalance || BigNumber.from(0)) ||
        ethers.utils.parseUnits(inputValue.toString() || "0", selectedPool.lendDecimals).eq(BigNumber.from(0))
      );
      setActionButtonAction(() => deposit);
    } else {
      setActionButtonText("Withdraw");
      if (!hasVars()) {
        setActionButtonDisabled(true);
        return;
      };
      const { rawPoolInterestOwed } = selectedPool;
      const withdrawableAmount = poolLendBalance.add(rawPoolInterestOwed);
      setActionButtonDisabled(
        ethers.utils.parseUnits(inputValue.toString() || "0", selectedPool.lendDecimals).gt(withdrawableAmount || BigNumber.from(0)) || 
        ethers.utils.parseUnits(inputValue.toString() || "0", selectedPool.lendDecimals).eq(BigNumber.from(0))
      );
      setActionButtonAction(() => withdraw);
    }

  }

  // get user token balances and pool balances
  const getBalanceData = async (polling?: boolean) => {
    if (!selectedPool) return;
    if (!initialBalanceLoaded) setLoading(true);
    // setup multicall contracts
    const lendTokenContract = new MulticallContract(selectedPool.lendToken, readableABIs.erc20);
    const poolContract = new MulticallContract(selectedPool.id, LendingPoolABI);
    // setup the correct multicall address
    handleMulticallAddress(chainId, multicallProvider);
    let calls:any[] = [
      // userLendBal 
      lendTokenContract.balanceOf(account),
      // poolLendBal 
      poolContract.lendBalance(),
      // poolColBal 
      poolContract.colBalance(),
      // lendAllowance
      lendTokenContract.allowance(account, ethers.utils.getAddress(selectedPool.id))
    ];
    // execute contract calls 
    const response = await multicallProvider.all(calls);
    // fetch data for current pool
    if (polling)
      getPoolData([selectedPool], true);
    // extract data from calls
    const [
      userBal,
      poolLendBal,
      poolColBal,
      lendAllowance
    ] = response;
    // update state
    setLendAllowance(lendAllowance);
    setPoolLendBalance(poolLendBal);
    setPoolColBalance(poolColBal);
    setUserLendBalance(userBal);
    setLoading(false);
    setInitialBalanceLoaded(true);
  }

  const approve = async () => {
    if (!supportedNetworks.includes(chainId) || !selectedPool || !provider) return;
    try {
      setPending(true);
      const poolAddress = selectedPool.id;
      enqueueSnackbar("Please confirm the approval transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true
      });
      let tokenContract = new Contract(
        selectedPool.lendToken,
        ERC20ABI,
        provider.getSigner()
      );
      // infinite approval vs. specific approval
      const approvalAmount = cookies.infiniteApprovalDisabled === "true"
        ? ethers.utils.parseUnits(inputValue.toString(), selectedPool.lendDecimals)
        : ethers.constants.MaxInt256;
      let tx = await tokenContract.approve(
        poolAddress,
        approvalAmount,
        {
          ...getGasLimit(chainId, METHOD_TYPE.APPROVE)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Approval successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
    } catch (e) {
      enqueueSnackbar("Approval transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
    // props.updatePoolData();
    await getBalanceData();
  };

  const withdraw = async () => {
    if (!provider || !selectedPool) return;
    try {
      setPending(true);
      enqueueSnackbar("Please confirm the withdraw transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true
      });
      const poolAddress = selectedPool.id;
      const poolContract = new Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner()
      );
      // get withdraw amount
      let withdrawAmount = ethers.utils.parseUnits(inputValue.toString(), selectedPool.lendDecimals);
      // if withdraw amount is equal to pool balance, pass in MaxUint256 to withdraw all
      if (Number(inputValue) === Number(selectedPool.poolLendBalance)) {
        withdrawAmount = ethers.constants.MaxUint256;
      }
      const tx = await poolContract.withdraw(
        withdrawAmount,
        {
          // @ts-ignore
          ...getGasLimit(chainId, METHOD_TYPE.WITHDRAW)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Withdraw transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
      setInputValue(0);
    } catch (e) {
      enqueueSnackbar("Withdraw transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
    props.updatePoolData();
    await getBalanceData();
  }

  const deposit = async () => {
    if (!provider || !selectedPool) return;
    try {
      setPending(true);
      enqueueSnackbar("Please confirm the deposit transaction in your wallet", {
        persist: false,
        disableWindowBlurListener: true
      });
      const poolAddress = selectedPool.id;
      const poolContract = new Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner()
      );
      const tx = await poolContract.deposit(
        ethers.utils.parseUnits(inputValue.toString(), selectedPool.lendDecimals),
        {
          ...getGasLimit(chainId, METHOD_TYPE.DEPOSIT)
        }
      );
      await tx.wait();
      enqueueSnackbar(`Deposit transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
      setInputValue(0);
    } catch (e) {
      enqueueSnackbar("Deposit transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
    await getBalanceData();
  }

  const collect = async () => {
    if (!provider || !selectedPool) return;
    try {
      setPending(true);
      const poolAddress = selectedPool.id;
      const poolContract = new Contract(
        poolAddress,
        LendingPoolABI,
        provider.getSigner()
      );
      const tx = await poolContract.collect({
        ...getGasLimit(chainId, METHOD_TYPE.COLLECT)
      });
      await tx.wait();
      enqueueSnackbar(`Collect transaction successful ** ${getTransactionUrl(tx.hash, chainId)}`, {
        persist: false,
        disableWindowBlurListener: true
      });
    } catch (e) {
      enqueueSnackbar("Collect transaction failed", {
        persist: false,
        disableWindowBlurListener: true
      });
      setPending(false);
      console.log(e);
      return;
    }
    setPending(false);
    await getBalanceData();
  }

  const inputChanged = (value: string) => {
    // get number of digits after decimal
    const getDigits = (v: string) => {
      const s = v;
      const i = s.indexOf('.') + 1;
      return i && s.length - i;
    }
    // check if new input is a number and check if decimal places is less than token decimals
    if (!isNaN(Number(value)) && getDigits(value) <= selectedPool.lendDecimals)
      setInputValue(value);
  }

  const renderSlideshow = () => {
    const slideshowContent = [
      {
        title: "Select a Pool to Manage",
        body: "Here you can manage loans that you have given and loans you have created.",
        img: require(`../../img/slideshow/my-pools/1${isDarkMode() ? "-dark" : ""}.png`),
      },
      {
        title: "Repay or Rollover Loans",
        body: "Remember to repay or rollover your loans on time to avoid losing your collateral.",
        img: require(`../../img/slideshow/my-pools/2${isDarkMode() ? "-dark" : ""}.png`),
      },
      {
        title: "Update Your Pool Parameters",
        body: "You can update your pool parameters at any time. This includes, interest rate, pause status, and rollover pools.",
        img: require(`../../img/slideshow/my-pools/3${isDarkMode() ? "-dark" : ""}.png`),
      },
      {
        title: "Collect Funds After Due Date",
        body: "As a lender you can collect your lent funds or defaulted collateral after the loan due date has passed",
        img: require(`../../img/slideshow/my-pools/4${isDarkMode() ? "-dark" : ""}.png`),
      },
      {
        title: "Monitor Loan Status",
        body: "You can keep track of the value of borrowed assets against the price of the collateral.",
        img: require(`../../img/slideshow/my-pools/5${isDarkMode() ? "-dark" : ""}.png`),
      }
    ]
    return <Slideshow
      content={slideshowContent}
    />
  }
  
  // show collect or add/withdraw depending on selected pool expiration
  const renderInputs = () => {
    let colData, lendData;
    if (selectedPool) {
      colData = fetchLocalTokenData(selectedPool.colToken, chainId);
      lendData = fetchLocalTokenData(selectedPool.lendToken, chainId);
    }
    if (!selectedPool || (props.mode === "lent" && selectedPool.owner.toLowerCase() !== account?.toLowerCase())) {
      return (
        renderSlideshow()
      );
    } else if (props.mode === "borrowed") {
      if (selectedPool)
        return (
          <LendingPool
            poolAddress={selectedPool.id}
            poolData={selectedPool}
            updatePoolData={props.updatePoolData}
            key={`${selectedPool.id}`}
            defaultMode={"repay"}
          />
        )
      else return null;
    } else if (!selectedPool || isPoolExpired(selectedPool)) {
      return (
        <div className="my-pools-collect-wrapper fade-in modal">
          <div className="collect-inner-wrapper">
            <TokenSelector
              symbol={
                selectedPool ? colData.symbol : "WETH"
              }
              value={ethers.utils.formatUnits(
                poolColBalance,
                selectedPool.colDecimals
              )}
              loading={loading}
            />
            <TokenSelector
              symbol={
                selectedPool ? lendData.symbol : "WETH"
              }
              value={ethers.utils.formatUnits(
                poolLendBalance,
                selectedPool.lendDecimals
              )}
              loading={loading}
            />
          </div>
          <ActionButton
            disabled={actionButtonDisabled || pending}
            title={actionButtonText}
            action={actionButtonAction}
          />
        </div>
      );     
    } else if (!selectedPool.externalDataLoaded) {
      return (
        <div></div>
      );
    } else {
      // exit if external data hasn't been loaded yet
      if (!lendData) return <></>;
      let rawPoolLendBalance: any;
      let lendBalance: any;
      try {
        const { rawPoolInterestOwed, poolLendBalance } = fullPoolData[selectedPool.id];
        rawPoolLendBalance = ethers.utils.parseUnits(poolLendBalance, selectedPool.lendDecimals);
        lendBalance = rawPoolLendBalance.add(rawPoolInterestOwed);
      } catch {
        lendBalance = BigNumber.from("0");
      }
      return (
       <div className="balance-manager-wrapper fade-in">
          <LineTabs
            tabs={["Add", "Withdraw"]}
            activeTab={mode === "add" ? 0 : 1}
            setActiveTab={(index: number) => setMode(index === 0 ? "add" : "withdraw")}
          />
          <TokenSelector
            symbol={selectedPool ? lendData.symbol : "WETH"}
            value={inputValue}
            sync={inputChanged}
            balance={mode === "add" ? userLendBalance : lendBalance}
            text={mode === "add" ? "Balance" : "Pool Balance"}
            loading={loading}
          />
          <ActionButton
            disabled={actionButtonDisabled || pending}
            title={actionButtonText}
            action={actionButtonAction}
          />
       </div>
      )
    }
  }

  return(
    renderInputs()
  );
}

export default BalanceManager